import {Button, Col, DatePicker, Form, Input, InputNumber, message, Popconfirm, Row, Select} from "antd";
import Api from "../../../services/Api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import FormattedInputNumber from "../../../utils/FormattedInputNumber";

const OdemePlaniDetail = ({selected, modalClose}) => {
    const [form] = Form.useForm();
    const [odemeYeriTipleri, setOdemeYeriTipleri] = useState([]);
    const [odemeTuruTipleri, setOdemeTuruTipleri] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = () => {
        setLoading(true)
        Api.get("/api/MuhasebeOdemePlanis/"+selected).then(res => {
            res.data = {...res.data, odemeTarihi: dayjs(res.data.odemeTarihi), odenenTarih: res.data.odenenTarih && dayjs(res.data.odenenTarih)}
            form.setFieldsValue(res.data)
            setLoading(false)
        }).catch(err => {
            message.error("Veriler yüklenemedi")
            setLoading(false)
        })
    }

    const getOdemeYeriTipleri = () => {
        Api.post("api/MuhasebeOdemePlanis/OdemeYeriTipleri").then(res => {
            setOdemeYeriTipleri(res.data)
        }).catch(err => {
            message.error("Ödeme Türü Tipleri Alınamadı.")
        })
    }

    const getOdemeTuruTipleri = () => {
        Api.post("api/MuhasebeOdemePlanis/OdemeTuruTipleri").then(res => {
            setOdemeTuruTipleri(res.data)
        }).catch(err => {
            message.error("Ödeme Türü Tipleri Alınamadı.")
        })

    }

    useEffect(() => {
        getOdemeYeriTipleri()
        getOdemeTuruTipleri()
        if(selected > 0 ){
            getData()
        }

    }, []);

    const deleteData = () => {
        Api.delete("/api/MuhasebeOdemePlanis/"+selected).then(res => {
            message.success("Silme işlemi başarılı")
            modalClose()
        }).catch(err => {
            message.error("Silme işlemi başarısız")
        })
    }

    const onFinish = (values) => {

        values.odemeTarihi = values.odemeTarihi.format("YYYY-MM-DD")

        if(values.odenenTarih && values.odenenTarih.length > 0){
            values.odenenTarih = values.odenenTarih.format("YYYY-MM-DD")
        }

        if(values.durum === "ÖDENDİ")
        {
            if(values.odenenTarih === undefined || values.odenenTarih === null || values.odenenTarih === ""){
                message.error("Ödenen tarih olmak zorunda")
                return;
            }
        }

        if(values.id === undefined || values.id === null || values.id === ""){
            values.id = 0;

           Api.post("/api/MuhasebeOdemePlanis", values).then(res => {
               message.success("Kayıt başarılı")
               modalClose(values)
           }).catch(err => {
                message.error("Kayıt başarısız")
           })
        }

        else if(values.id > 0){
            Api.put("/api/MuhasebeOdemePlanis", values).then(res => {
                message.success("Güncelleme başarılı")
                modalClose(values)
            }).catch(err => {
                message.error("Güncelleme başarısız")
            })
        }

    }

    return(<>

        {loading && <div>Yükleniyor</div>}

        {!loading &&
            <Form
                layout={"horizontal"}
                form={form}
                colon={false}
                onFinish={onFinish}
                initialValues={{tutar:0}}
                labelCol={{
                    span: 10,
                }}
                labelAlign={"left"}>
                <Row gutter={[10,10]}>
                    <Col span={13}>
                        <Form.Item hidden={true} name={"id"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={13}>
                        <Form.Item label={"Ödeme Yeri"} name={"odemeYeri"} rules={[{ required: true },]}>
                            <Select>
                                {odemeYeriTipleri.map((item, index) => {
                                    return <Select.Option key={index} value={item.categoryName}>{item.categoryName}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={13}>
                        <Form.Item label={"Ödeme Türü"} name={"odemeTuru"} rules={[{ required: true },]}>
                            <Select>
                                {odemeTuruTipleri.map((item, index) => {
                                    return <Select.Option key={index} value={item.categoryName}>{item.categoryName}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Ödeme Adı"} name={"odemeAdi"} rules={[{ required: true },]}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Ödeme Tutarı"}  name={"tutar"} rules={[{ required: true },]}>
                            <FormattedInputNumber align={"left"} ></FormattedInputNumber>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Ödeme Tarihi"} name={"odemeTarihi"} rules={[{ required: true },]}>
                            <DatePicker  rootClassName={"w-100"} format={"DD.MM.YYYY"}></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Ödenen Tarih"} name={"odenenTarih"} rules={[{ required: false },]}>
                            <DatePicker  rootClassName={"w-100"} format={"DD.MM.YYYY"}></DatePicker>
                        </Form.Item>
                    </Col>


                    {/*<Col span={13}>*/}
                    {/*    <Form.Item label={"Donem"} name={"donem"} rules={[{ required: true },]}>*/}
                    {/*        <Input></Input>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}

                    <Col span={13}>
                        <Form.Item label={"Durum"} name={"durum"}>
                            <Select>
                                <Select.Option value={""}>ÖDENMEDİYSE</Select.Option>
                                <Select.Option value={"ÖDENDİ"}>ÖDENDİ</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Cari Adı"} name={"cariAdi"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Cari İBAN"} name={"cariIban"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={13}>
                        <Form.Item label={"Açıklama"} name={"aciklama"}>
                            <Input.TextArea row={3}></Input.TextArea>
                        </Form.Item>
                    </Col>

                    <Col span={13} className={"mt-1 text-align-right"}>
                        {selected > 0 &&
                            <Popconfirm
                                title="Silme İşlemi"
                                description="Silme işlemi yapmak istediğinize emin misiniz?"
                                onConfirm={deleteData}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button type={"dashed"} style={{marginRight:5}} >Sil</Button>
                            </Popconfirm>
                        }

                        <Button type={"primary"}  htmlType="submit" >Kaydet</Button>

                    </Col>

                </Row>

            </Form>
        }

    </>)
}

export default OdemePlaniDetail;