import Api from "../../services/Api";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Card, Col, Form, Input, message, Row, Space, Switch} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

const UserDetailPage = () => {
    const [loading,setLoading] = useState(true)
    const { state } = useLocation();
    const [form] = Form.useForm();
    const navigate = useNavigate()


    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        Api.get("/api/users/" + state.id).then(res => {
            form.setFieldsValue(res.data);
            setLoading(false)
        }).catch(err => message.error(err.response.detail)).finally(() => setLoading(false));
    }


    const onFinisFailed = (errorInfo) => {}
    const onFinish = (values) => {
        Api.put("/api/users", values).then(res => {
            message.success("İşlem başarılı")
            navigate("/users")
        }).catch(err => message.error("Bir hata gerçekleşti"))
    }





    return(
        <div className={"p-2"}>
            <Card title={"Kullanıcı Detayı"} loading={loading}  extra={<Button size={"small"} onClick={() => navigate("/users")}><ArrowLeftOutlined /></Button>}>
                <Form
                    form={form}
                    labelAlign={"left"}
                    initialValues={{optionType: "text"}}
                    onFinish={onFinish}
                    onFinishFailed={onFinisFailed}
                    layout={"horizontal"}
                    labelCol={{
                        span: 15,
                    }}
                    colon={false}
                >
                    <Row gutter={[10,0]}>
                       <Col span={12}>
                           <Col span={24}>
                               <Form.Item hidden={true} label={"Id"} name={"id"} rules={[{ required: true },]}>
                                   <Input></Input>
                               </Form.Item>
                           </Col>

                           <Col span={24}>
                               <Form.Item label={"Adı"} name={"firstName"} rules={[{ required: true },]}>
                                   <Input></Input>
                               </Form.Item>
                           </Col>

                           <Col span={24}>
                               <Form.Item label={"Soyadı"} name={"lastName"} rules={[{ required: true },]}>
                                   <Input></Input>
                               </Form.Item>
                           </Col>

                           <Col span={24}>
                               <Form.Item label={"Email Adresi (Kullanıcı Adı)"} name={"email"} rules={[{ required: true },]}>
                                   <Input></Input>
                               </Form.Item>
                           </Col>

                           <Col span={24}>
                               <Form.Item label={"Şifre"} name={"password"} rules={[{ required: true },]}>
                                   <Input.Password></Input.Password>
                               </Form.Item>
                           </Col>

                           <Col span={24}>
                               <Form.Item label={"Kullanıcı Durumu"} valuePropName={"checked"} name="status" required={true}>
                                   <Switch defaultChecked={true} checkedChildren="Kullanıcı Aktif" unCheckedChildren="Kullanıcı Pasif" />
                               </Form.Item>
                           </Col>

                           <Col span={24} className={"text-align-right"}>
                                 <Space>
                                      <Button type={"primary"} htmlType="submit">Kaydet</Button>
                                     <Button type={"default"} onClick={() => navigate("/users")}>Geri Dön</Button>
                                 </Space>
                           </Col>
                       </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    )
}

export default UserDetailPage