import {message, Table} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import flexCurrency from "../../../utils/FlexCurrency";
import dayjs from "dayjs";

const BankaDurumuComponent = ({startFilterDate, endFilterDate}) => {
    const [loading, setLoading] = useState(true)
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])

    const getData = () => {
        setLoading(true)

        if(startFilterDate === undefined || endFilterDate === undefined){
            setLoading(false)
            return
        }

        const requestDto = {
            "startDate": dayjs(startFilterDate).format("YYYY-MM-DD"),
            "endDate": dayjs(endFilterDate).format("YYYY-MM-DD"),
            "formulleHesaplaRequestDto": [
                {
                    "formul": "100",
                    "hesaplamaAdi": "KASA"
                },
                {
                    "formul": "102.01",
                    "hesaplamaAdi": "BANKA"
                }
            ]
        }

        Api.post("api/MuhasebeTahminiGelirGiders/GetListTahminiBankaDurumQuery", requestDto)
            .then(response => {
            const newData = [];
            response.data.forEach(item => {
                const ustGrup = { aciklama: item.aciklama, bakiyes: item.bakiyes };
                item.bakiyes.forEach(item => {
                    ustGrup[item.tarih] = flexCurrency(item.tutar); // bakiye.tutar değerini ekliyoruz
                });
                newData.push(ustGrup);
            });

            setData(newData)

            const columns = []
            columns.push({
                title: "",
                className:"font-weight-bold",
                dataIndex: "aciklama"
            })
            response.data[0].bakiyes.forEach(item => {
                columns.push({
                    title: dayjs(item.tarih).format("DD.MM.YYYY"),
                    dataIndex: item.tarih,
                    align:"right",
                    width:150
                })
            })
            setColumns(columns)
            setLoading(false)

            console.log(newData)

        })
            .catch(error => {message.error("Bir hata oluştu, banka durumu getirilemedi.")})
    }

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        getData()
    }, [startFilterDate, endFilterDate]);


    return(
       <div>
           <Table
               rowKey={"aciklama"}
               dataSource={data}
               loading={loading}
               bordered={true}
               style={{overflow: "auto"}}
               pagination={false}
               columns={columns}
               size={"small"}
               summary={() => (
                   <Table.Summary.Row>

                       <Table.Summary.Cell index={0}>
                           <div className={"font-weight-bold text-align-right"}>TOPLAM</div>
                       </Table.Summary.Cell>

                       {columns.slice(1).map((item, index) => {
                            return (
                                 <Table.Summary.Cell index={index + 1}>
                                      <div className={"font-weight-bold text-align-right"}>
                                          {flexCurrency(data.reduce((acc, val) => acc + parseFloat(val[item.dataIndex].replaceAll(".","").replace(",",".")), 0))}
                                      </div>
                                 </Table.Summary.Cell>
                            )
                       })}

                   </Table.Summary.Row>
               )}
           >
           </Table>
       </div>
    )
}

export default BankaDurumuComponent