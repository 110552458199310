import {Button, Card, Checkbox, Col, DatePicker, Form, message, Modal, Row, Space, Table,} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardCheck, faFileExport, faPenToSquare, faPlus} from "@fortawesome/free-solid-svg-icons";
import Api_Maden from "../../../../../services/Api_Maden";
import MadenOdemePlaniDetail from "./partials/MadenOdemePlaniDetail";
import MadenOdemePlaniOlusturucuOzetComponent from "./partials/MadenOdemePlaniOlusturucuOzetComponent";
import MadenOdemePlaniAyllikDagilimComponent from "./partials/MadenOdemePlaniAyllikDagilimComponent";
import flexCurrency from "../../../../../utils/FlexCurrency";

const MadenOdemePlaniPage = () => {
    const [data, setData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { RangePicker } = DatePicker;
    const [startFilterDate, setStartFilterDate] = useState(dayjs().startOf('month'));
    const [endFilterDate, setEndFilterDate] = useState(dayjs().endOf('month'));
    const [durumFilter, setDurumFilter] = useState([]);
    const [odemeTuruTipleri, setOdemeTuruTipleri] = useState([]);
    const [odemeYeriTipleri, setOdemeYeriTipleri] = useState([]);
    const [odemeTuruFilter, setOdemeTuruFilter] = useState([]);
    const [odemeYeriFilter, setOdemeYeriFilter] = useState([]);
    const [odemePlaniModal, setOdemePlaniModal] = useState({isOpen:false, selected:0});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [odemePlaniOlusturucuOzetModal, setOdemePlaniOlusturucuOzetModal] = useState({isOpen:false, selectedRows:[]});

    useEffect(() => {
        getOdemeTuruTipleri()
        getOdemeYeriTipleri()
        getData(startFilterDate, endFilterDate)
    }, []);

    useEffect(() => {
        let filterData = fullData

        if(durumFilter.length > 0) {
            filterData = filterData.filter(item => durumFilter.includes(item.durum))
        }

        if(odemeTuruFilter.length > 0) {
            filterData = filterData.filter(item => odemeTuruFilter.includes(item.odemeTuru))
        }

        if(odemeYeriFilter.length > 0) {
            filterData = filterData.filter(item => odemeYeriFilter.includes(item.odemeYeri))
        }

        setData(filterData)

    },[durumFilter, odemeTuruFilter, odemeYeriFilter])

    const getData = (requestStartDateFilter, requestEndDateFilter) => {

        form.resetFields()


        setLoading(true)
        setDurumFilter([])
        setOdemeTuruFilter([])
        const requestFilterData = {
            "sort": [
                {
                    "field": "OdemeTarihi",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "OdemeTarihi.Date",
                "operator": "gte",
                "value": requestStartDateFilter,
                "logic": "and",
                "filters":[{
                    "field":"OdemeTarihi.Date",
                    "operator":"lte",
                    "value":requestEndDateFilter,
                    "logic":"and"
                }]
            }
        }

        Api_Maden.post("api/MuhasebeOdemePlanis/dynamic?PageIndex=0&PageSize=1000000", requestFilterData).then(res => {
            const data = res.data.items.map(item => {
                if(item.durum === "ÖDENDİ") {
                    item.durum = "ÖDENDİ"
                }
                else if(item.durum === "") {
                    //burada eğer mevcut tarih geçmişse gecikti olarak işaretlenecek
                    if(dayjs(item.odemeTarihi).isBefore(dayjs())) {
                        item.durum = "GECİKTİ"
                    }
                    else {
                        item.durum = "BEKLİYOR"
                    }
                }
                return item
            })

            setData(data)
            setFullData(data)

            setLoading(false)
        }).catch(err => {
            message.error("Ödeme Planı Listesi Alınamadı.")
        })
    }

    const getOdemeTuruTipleri = () => {
        Api_Maden.post("api/MuhasebeOdemePlanis/OdemeTuruTipleri").then(res => {
            setOdemeTuruTipleri(res.data)
        }).catch(err => {
            message.error("Ödeme Türü Tipleri Alınamadı.")
        })
    }

    const getOdemeYeriTipleri = () => {
        Api_Maden.post("api/MuhasebeOdemePlanis/OdemeYeriTipleri").then(res => {
            setOdemeYeriTipleri(res.data)
        }).catch(err => {
            message.error("Ödeme Türü Tipleri Alınamadı.")
        })
    }

    const handleDurumFilter = (name, value) => {
        if(value) {
            setDurumFilter([...durumFilter, name])
        }
        else {
            setDurumFilter(durumFilter.filter(item => item !== name))
        }
    }

    const handleOdemeTuruFilter = (name, value) => {
        if(value) {
            setOdemeTuruFilter([...odemeTuruFilter, name])
        }
        else {
            setOdemeTuruFilter(odemeTuruFilter.filter(item => item !== name))
        }
    }

    const handleOdemeYeriFilter = (name, value) => {
        if(value) {
            setOdemeYeriFilter([...odemeYeriFilter, name])
        }
        else {
            setOdemeYeriFilter(odemeYeriFilter.filter(item => item !== name))
        }
    }

    const GroupTotalComponent = (data) => {
        //odemeTuru ' ne göre grupla

        const odemeTuruGrup = data.data.reduce((acc, item) => {
            if(!acc[item.odemeTuru]) {
                acc[item.odemeTuru] = 0
            }
            acc[item.odemeTuru] += item.tutar
            return acc
        }, {})

        const odemeTuruGrupArray = Object.keys(odemeTuruGrup).map(key => {
            return {
                odemeTuru: key,
                tutar: odemeTuruGrup[key]
            }
        })

        // toplam tutar
        const totalTutar = data.data.reduce((acc, item) => {
            acc += item.tutar
            return acc
        }, 0)

        odemeTuruGrupArray.push({
            odemeTuru: "TOPLAM",
            tutar: totalTutar
        })




        return(
            <Table
                bordered={false}
                showHeader={true}
                rowKey={"odemeTuru"}
                className={"row-padding"}
                dataSource={odemeTuruGrupArray}
                rowClassName={(record, index) => {
                    return index === odemeTuruGrupArray.length - 1 ? 'font-weight-bold font-color-red' : '';
                }}
                size={"small"}
                pagination={false}
            >
                <Column title={"Ödeme Türü"} dataIndex={"odemeTuru"}></Column>
                <Column title={"Tutar"} align={"right"} className={"font-weight-bold"} render={(text,record,index) => {
                    return flexCurrency(record.tutar)
                }} dataIndex={"tutar"}></Column>
            </Table>
        )




    }

    const odemePlaniModalClose = (changeItem) => {
        setOdemePlaniModal({isOpen: false, selected:0})

        if(changeItem) {
            if(changeItem.id === 0) {
                setFullData([...fullData, changeItem])
                setData([...data, changeItem])
            }

            else{
                const newData = data.map(item => {
                    if(item.id === changeItem.id) {
                        return changeItem
                    }
                    return item
                })
                setFullData(newData)
                setData(newData)
            }
        }
        else{
            getData(startFilterDate, endFilterDate)
        }
    }

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        //setlerken seçilen datalarıda selectedRowKeys e at

        const selectedRows = data.filter(item => newSelectedRowKeys.includes(item.id));
        console.log('Selected Rows Data: ', selectedRows);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const rowSelectionToplamTutar = () => {
        const selectedRow = data.filter(item => selectedRowKeys.includes(item.id));
        return selectedRow.reduce((acc, item) => {
            acc += item.tutar
            return acc
        }, 0)
    }

    const odemePlaniOlusturucuOzetModalOpen = () => {
        const selectedRow = data.filter(item => selectedRowKeys.includes(item.id));
        setOdemePlaniOlusturucuOzetModal({isOpen:true, selectedRows:selectedRow})
    }

    return(
        <div className={"p-1"}>
            <Modal
                open={odemePlaniModal.isOpen}
                destroyOnClose={true}
                width={1400}
                onCancel={() => setOdemePlaniModal({isOpen:false, selected:0})}
                footer={[]}
                title={"Ödeme Planı"}>
                <MadenOdemePlaniDetail selected={odemePlaniModal.selected} modalClose={(e) => {odemePlaniModalClose(e)}}></MadenOdemePlaniDetail>
            </Modal>

            <Modal
                open={odemePlaniOlusturucuOzetModal.isOpen}
                destroyOnClose={true}
                width={1400}
                onCancel={() => setOdemePlaniOlusturucuOzetModal({isOpen:false, selectedRows:[]})}
                footer={[]}
            >
                <MadenOdemePlaniOlusturucuOzetComponent selectedRows={odemePlaniOlusturucuOzetModal.selectedRows}></MadenOdemePlaniOlusturucuOzetComponent>
            </Modal>

            <Row gutter={[10,10]}>
                <Col span={12}>
                    <Card title={"FİLTRE VE PARAMETRELER"} style={{height:"100%"}} size={"small"}>
                        <Form
                            layout={"horizontal"}
                            form={form}
                            colon={false}
                            labelCol={{
                                span: 8,
                            }}
                            labelAlign={"left"}>
                            <Row gutter={[10,10]}>
                                <Col span={24}>
                                    <Col span={24}>
                                        <Form.Item label={"Raporlama Tarihi Aralığı"}  style={{fontWeight:"bold"}} rules={[{ required: true },]}>
                                            <RangePicker
                                                name={"daterange"}
                                                format={"DD.MM.YYYY"}
                                                defaultValue={[startFilterDate, endFilterDate]}
                                                onChange={(dates) => {
                                                    if (dates) {
                                                        const startDate = dayjs(dates[0]).format("YYYY-MM-DD");
                                                        setStartFilterDate(startDate);

                                                        const endDate = dayjs(dates[1]).format("YYYY-MM-DD");
                                                        setEndFilterDate(endDate);

                                                        getData(startDate, endDate)
                                                    }
                                                }}
                                                picker="date"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className={"mt-1"}>
                                        <Form.Item label={"Ödeme Durumu"} name={"odeme-durumlari"}>
                                            <Checkbox.Group name={"odeme-durumlari"}>
                                                <Checkbox className={"font-color-green"} defaultChecked={true} value={"ÖDENDİ"}  onChange={(e) => handleDurumFilter("ÖDENDİ", e.target.checked)}>ÖDENDİ</Checkbox>
                                                <Checkbox className={"font-color-orange"} defaultChecked={true} value="BEKLİYOR" onChange={(e) => handleDurumFilter("BEKLİYOR", e.target.checked)}>BEKLİYOR</Checkbox>
                                                <Checkbox className={"font-color-red"} defaultChecked={true} value="GECİKTİ" onChange={(e) => handleDurumFilter("GECİKTİ", e.target.checked)}>GECİKTİ</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} className={"mt-1"}>
                                        <Form.Item label={"Ödeme Türü"} name={"odeme-turleri"}>
                                            <Checkbox.Group>
                                                {odemeTuruTipleri.map(item => {
                                                    return <Checkbox name={item.categoryName}  key={item.categoryName} onChange={(e) => handleOdemeTuruFilter(item.categoryName, e.target.checked)} value={item.categoryName}>{item.categoryName}</Checkbox>
                                                })}
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className={"mt-1"}>
                                        <Form.Item label={"Ödeme Yeri"} name={"odeme-yeri"}>
                                            <Checkbox.Group>
                                                {odemeYeriTipleri.map(item => {
                                                    return <Checkbox name={item.categoryName}  key={item.categoryName} onChange={(e) => handleOdemeYeriFilter(item.categoryName, e.target.checked)} value={item.categoryName}>{item.categoryName}</Checkbox>
                                                })}
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>


                                </Col>

                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={"SEÇİLİ OLAN ÖDEME PLANI TOPLAMLARI"}  style={{height:"100%"}} size={"small"}>
                        <GroupTotalComponent data={data}></GroupTotalComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title={"AYLIK DAĞILIM"} size={"small"}>
                        <MadenOdemePlaniAyllikDagilimComponent data={data}></MadenOdemePlaniAyllikDagilimComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title={"ÖDEME PLANI LİSTESİ"} size={"small"} extra={<Button onClick={() => setOdemePlaniModal({isOpen: true, selected:0})} size={"small"}><FontAwesomeIcon icon={faPlus} /></Button>}>

                        <Row>
                            <Col span={24} className={"d-flex"} style={{justifyContent:"space-between"}}>
                                <div>
                                    <Space
                                        style={{
                                            marginBottom: 16,
                                        }}
                                    >
                                        <Button onClick={() => odemePlaniOlusturucuOzetModalOpen()} icon={<FontAwesomeIcon icon={faFileExport} />} type={"default"}>Ödeme Planı Oluştur</Button>
                                    </Space>

                                </div>

                                <div>

                                    
                                    <Space style={{marginRight:10}}>
                                        <div className={"text-align-right font-weight-bold"}> Seçilen Ödeme Planı
                                            <p className={"font-weight-bold font-color-red"}>{selectedRowKeys.length} Adet Ödeme</p>
                                        </div>
                                    </Space>

                                    <Space>
                                        <div className={"text-align-right font-weight-bold"}> Seçilen Toplam Tutar
                                            <p className={"font-weight-bold font-color-red"}>{flexCurrency(rowSelectionToplamTutar())}</p>
                                        </div>
                                    </Space>

                                </div>

                            </Col>
                            <Col span={24}>
                                <Table
                                    loading={loading}
                                    bordered={true}
                                    showHeader={true}
                                    rowKey={"id"}
                                    className={"row-padding"}
                                    dataSource={data}
                                    size={"small"}
                                    rowSelection={rowSelection}
                                    pagination={false}
                                >
                                    <Column title={""} width={"10px"} dataIndex={""} render={(_, record) => {
                                        return(
                                            <Space>
                                                <Button size={"small"} onClick={() => setOdemePlaniModal({isOpen: true, selected: record.id})}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                            </Space>
                                        )
                                    }}></Column>
                                    <Column
                                        title={"Fatura Tarihi"}
                                        align={"center"}
                                        width={125}
                                        sorter={(a, b) => dayjs(a.odemeTarihi).unix() - dayjs(b.odemeTarihi).unix()}
                                        render={(text, record, index) => {
                                            return dayjs(record.odemeTarihi).format("DD.MM.YYYY")
                                        }} dataIndex={"odemeTarihi"}></Column>

                                    <Column
                                        title={"Ödenen Tarih"}
                                        align={"center"}
                                        width={125}
                                        sorter={(a, b) => dayjs(a.odemeTarihi).unix() - dayjs(b.odemeTarihi).unix()}
                                        render={(text, record, index) => {
                                            if(record.odenenTarih === null) {
                                                return ""
                                            }

                                            return dayjs(record.odenenTarih).format("DD.MM.YYYY")
                                        }} dataIndex={"odenenTarih"}></Column>

                                    <Column title={"Durum"} align={"center"} width={150}
                                            sorter={(a, b) => a.durum.localeCompare(b.durum)}
                                            render={(text,record,index) => {
                                                if(record.durum === "ÖDENDİ") {
                                                    return <span style={{color:"green"}}>ÖDENDİ</span>
                                                }
                                                else if(record.durum === "GECİKTİ") {
                                                    return <span style={{color:"red"}}>GECİKTİ</span>
                                                }
                                                else if(record.durum === "BEKLİYOR") {
                                                    return <span style={{color:"orange"}}>BEKLİYOR</span>
                                                }

                                                else {
                                                    return <span style={{color:"black"}}>{record.durum}</span>
                                                }
                                            }} dataIndex={"durum"}></Column>

                                    <Column
                                        title={"Ödeme Yeri"}
                                        align={"center"}
                                        sorter={(a, b) => a.odemeYeri.localeCompare(b.odemeYeri)}
                                        width={150}
                                        dataIndex={"odemeYeri"}></Column>


                                    <Column
                                        title={"Ödeme Türü"}
                                        sorter={(a, b) => a.odemeTuru.localeCompare(b.odemeTuru)}
                                        dataIndex={"odemeTuru"}></Column>


                                    <Column
                                        title={"Ödeme Adı"}
                                        sorter={(a, b) => a.odemeAdi.localeCompare(b.odemeAdi)}
                                        dataIndex={"odemeAdi"}></Column>


                                    <Column
                                        title={"Tutar"}
                                        align={"right"}
                                        className={"font-weight-bold"}
                                        width={150}
                                        sorter={(a, b) => a.tutar - b.tutar}
                                        render={(text,record,index) => {
                                            return flexCurrency(record.tutar)
                                        }}
                                        dataIndex={"tutar"}></Column>
                                </Table>
                            </Col>
                        </Row>



                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default MadenOdemePlaniPage