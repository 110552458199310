import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import {message, Table} from "antd";
import dayjs from "dayjs";
import flexCurrency from "../../../utils/FlexCurrency";

const GeotFisGunDetayKodComponent = ({selected}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        const requestDto = {
            "referansTarih": selected.selectedFisGunDetayKod.tarih,
            "formulleHesaplaRequestDto": {
                "formul": selected.selectedFisGunDetayKod.formul,
                "hesaplamaAdi": selected.selectedFisGunDetayKod.aciklama
            }
        }

        Api.post("api/MuhasebeFisis/GetListFormulleHesaplaWidthGunluk", requestDto).then(res => {
            setData(res.data[0].muhasebeFisleri)
            setLoading(false)
        }).catch(err => {
            message.error("Detay fişleri getirilirken hata oluştu.")
        })
    }

    return(
       <div style={{overflow:"auto", maxHeight:500}}>
           <Table
                dataSource={data}
                loading={loading}
                pagination={false}
                rowKey={"index"}
                size={"small"}

           >
                <Table.Column
                    title={"Tarih"} width={100} dataIndex={"tarih"} key={"tarih"} render={(text, record, index) => {
                        return <div>{dayjs(record.tarih).format("DD.MM.YYYY")}</div>
                }}>
                </Table.Column>
               <Table.Column width={100} title={"Fiş No"} dataIndex={"fisNo"} key={"turuUzun"}></Table.Column>
               <Table.Column width={100} title={"Türü"} dataIndex={"turuUzun"} key={"turuUzun"}></Table.Column>
               <Table.Column width={130} title={"Hesap Kodu"} dataIndex={"hesapKodu"} key={"hesapKodu"}></Table.Column>
               <Table.Column title={"Fiş Açıklama"} dataIndex={"fisAciklamasi"} key={"fisAciklamasi"}></Table.Column>
               <Table.Column title={"Hesap Adı"} dataIndex={"hesapAciklama"} key={"hesapAciklama"}></Table.Column>
               <Table.Column title={"Açıklama"} dataIndex={"aciklama"} key={"aciklama"}></Table.Column>

               <Table.Column title={"Borç"} align={"right"} dataIndex={"borc"} key={"borc"} render={(text, record, index) => {
                   return flexCurrency(record.borc)

               }}></Table.Column>
                <Table.Column title={"Alacak"} align={"right"} dataIndex={"alacak"} key={"alacak"} render={(text,record, index) => {
                    return flexCurrency(record.alacak)
                }}></Table.Column>
           </Table>
       </div>

    )
}

export default GeotFisGunDetayKodComponent

