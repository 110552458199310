import {Button, Card, message, Modal, Table, Tooltip} from "antd";
import {useEffect, useState} from "react";
import KrediLimitPageDetail from "./partials/KrediLimitPageDetail";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Api from "../../services/Api";
import FlexCurrency from "../../utils/FlexCurrency";

const KrediLimitPage = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [krediLimitModal, setKrediLimitModal] = useState({isOpen:false, selectedData:null})
    const [parametreData, setParametreData] = useState()


    const getData = () => {
        Api.get("/api/Parametres/GetByCode/MUH_KREDI_LIMIT").then(res => {
            let resData = JSON.parse(res.data.parametreDegeri)
            setData(resData)
            setParametreData(res.data)
            setLoading(false)

        }).catch(err => {
            message.error("Kredi limit bilgileri getirilemedi")
        })
    }

    const addData = (values) => {
        let newData = [...data]
        newData.push(values)
        setData(newData)
        setKrediLimitModal({isOpen: false, selectedData: null})

        Api.put("api/Parametres", {...parametreData, parametreDegeri: JSON.stringify(newData)}).then(res => {
            message.success("Kredi limit bilgileri güncellendi")
            getData()
        }).catch(err => {
            message.error("Kredi limit bilgileri güncellenemedi")
        })
    }

    const deleteData = (values) => {
        let newData = data.filter(x => x !== values)
        setData(newData)
        setKrediLimitModal({isOpen: false, selectedData: null})

        Api.put("api/Parametres", {...parametreData, parametreDegeri: JSON.stringify(newData)}).then(res => {
            message.success("Kredi limit bilgileri güncellendi")
            getData()
        }).catch(err => {
            message.error("Kredi limit bilgileri güncellenemedi")
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return(<div className={"p-1"}>

        <Modal
            open={krediLimitModal.isOpen}
            title={"Kredi Limit Detay"}
            width={1400}
            onCancel={() => setKrediLimitModal({isOpen:false, selectedData:null})}
            footer={null}
            destroyOnClose={true}
        >
            <KrediLimitPageDetail addData={(e) => addData(e)} updateData={(e) => updateData(e)} selectedData={krediLimitModal.selectedData} modalClose={() => setKrediLimitModal({isOpen: false, selectedData: null})}></KrediLimitPageDetail>
        </Modal>

        <Card title={"KREDİ LİMİT TABLOSU"} size={"small"} extra={<Button onClick={() => setKrediLimitModal({isOpen: true, selectedData: null})} size={"small"}><FontAwesomeIcon icon={faPlus} /></Button>}>
            <Table
                loading={loading}
                bordered={true}
                showHeader={true}
                rowKey={"index"}
                style={{overflow:"auto"}}
                dataSource={data}
                size={"small"}
                pagination={false}>

                <Table.Column  render={(text, record, index) => {
                        return(
                            <Tooltip title="Sil">
                                <Button onClick={() => deleteData(record)} icon={<FontAwesomeIcon icon={faTrash} />}  size={"small"}></Button>
                            </Tooltip>
                        )
                    }} width={20} dataIndex={"duzenle"} key={"duzenle"}>
                </Table.Column>

                <Table.Column width={290} title={"Banka"} dataIndex={"banka"} key={"banka"}></Table.Column>

                <Table.Column width={345} title={"Limit"} align={"right"} dataIndex={"limit"} key={"limit"} render={(text,record, index) => {
                    return FlexCurrency(record.limit)
                }}></Table.Column>

                <Table.Column width={340} title={"Kullanılan"} align={"right"} dataIndex={"kullanilan"} key={"kullanilan"} render={(text,record, index) => {
                    return FlexCurrency(record.kullanilan)
                }}></Table.Column>

                <Table.Column width={460} align={"right"} title={"Teminat Mektubu Limiti"} dataIndex={"teminatMektubuLimiti"} key={"teminatMektubuLimit"} render={(text,record,index) => {
                    return FlexCurrency(record.teminatMektubuLimiti)
                }}></Table.Column>

                <Table.Column align={"right"} title={"Teminat Kullanılan"} dataIndex={"teminatKullanilan"} key={"teminatKullanilan"} render={(text,record,index) => {
                    return FlexCurrency(record.teminatKullanilan)
                }}></Table.Column>

            </Table>
        </Card>


    </div>)
}

export default KrediLimitPage;