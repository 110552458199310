import {Button, Card, Col, Form, Input, message, Modal, Row, Space, Table} from "antd";
import Column from "antd/es/table/Column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faDatabase, faEye, faLayerGroup, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import IslemetGelirGiderAltGrupParametreComponent from "./IslemetGelirGiderAltGrupParametreComponent";
import Api from "../../../services/Api";

const IsletGelirGiderParametreComponent = () => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [gridData, setGridData] = useState([])
    const [paramatreDuzenleyicisiForm] = Form.useForm()
    const [altGrupModal, setAltGrupModal] = useState({isOpen:false, record:null})
    const [resParametre, setResParametre] = useState()

    const gridAddRow = (values) => {
        setLoading(true)
        const checkGroup = gridData.filter((item) => item.aciklama === values.aciklama)
        if(checkGroup.length > 0){
            message.info("Bu grup daha önce eklenmiş")
            setLoading(false)
        }
        else{
            const newRow = {
                aciklama: values.aciklama,
                props: [],
            }
            setGridData([...gridData, newRow])
            setLoading(false)
            paramatreDuzenleyicisiForm.resetFields()
        }

    }

    const gridUpdateRowData = (record, index, hesaplamaAdi, formul) => {
        const newData = [...gridData]
        const item = newData[index]
        newData.splice(index, 1, { ...item, aciklama: hesaplamaAdi, formul:formul })
        setGridData(newData)
    }

    const gridDeleteRowData = (record, index) => {
        const newData = [...gridData]
        newData.splice(index, 1)
        setGridData(newData)
    }

    const gridUpRowData = (record, index) => {
        if(index === 0){
            return
        }
        const newData = [...gridData]
        const item = newData[index]
        newData.splice(index, 1)
        newData.splice(index-1, 0, item)
        setGridData(newData)
    }

    const gridDownRowData = (record, index) => {
        if(index === gridData.length-1){
            return
        }
        const newData = [...gridData]
        const item = newData[index]
        newData.splice(index, 1)
        newData.splice(index+1, 0, item)
        setGridData(newData)
    }

    const getParametre = () => {
        Api.get(`api/Parametres/GetByCode/MUH_ISLETME_GELIR_GIDER_DURUMU`).then(res => {
            form.setFieldsValue(res.data)
            setLoading(false)
            setGridData(JSON.parse(res.data.parametreDegeri))
            setResParametre(res.data)


        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
            console.log(err)
        })
    }

    useEffect(() => {
        getParametre()
    },[])

    const saveParametre = () => {
        setLoading(true)
        const data = {
            ...resParametre,
            parametreDegeri: JSON.stringify(gridData)
        }

        Api.put("api/Parametres/", data).then(res => {
            message.success("Parametre bilgileri güncellendi")
            setLoading(false)
        }).catch(err => {
            message.error("Parametre bilgileri güncellenirken hata oluştu")
            setLoading(false)
        })
    }

    return(
        <Row gutter={[10,10]}>
            <Modal
                title={altGrupModal?.record?.aciklama+" _ Alt Grup Parametre"}
                onCancel={() => setAltGrupModal({isOpen:false, record:null})}
                open={altGrupModal.isOpen}
                destroyOnClose={true}
                footer={[]}
                focusTriggerAfterClose={false}
                centered={true}
                maskClosable={false}
                width={1500}
            >
                <IslemetGelirGiderAltGrupParametreComponent setGridData={setGridData} gridData={gridData}  record={altGrupModal.record}></IslemetGelirGiderAltGrupParametreComponent>
            </Modal>
            <Col span={24}>
                <Card size={"small"} title={"Yeni Parametre Girişi"}>
                    <Form
                        labelCol={{span:5}}
                        layout={"vertical"}
                        onFinish={gridAddRow}
                        form={paramatreDuzenleyicisiForm}
                    >
                        <Row gutter={[10,10]}>
                            <Col span={24}>
                                <Form.Item  label={"Üst Grup Adı"} name={"aciklama"} rules={[{ required: true },]}>
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Col span={24} className={"text-align-right"}>
                                <Button icon={<FontAwesomeIcon icon={faLayerGroup}></FontAwesomeIcon>} type={"default"} htmlType={"submit"}>Yeni Üst Grup Ekle</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
            <Col span={24}>
                <Card size={"small"} title={"Paramatre Düzenleyicisi"}>
                    <Row gutter={[10,10]}>

                        <Col span={24}>
                            <Button onClick={() => saveParametre()} icon={<FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>}>Paremetreleri Kaydet</Button>
                        </Col>

                        <Col span={24}>
                            <Table
                                bordered={true}
                                showHeader={true}
                                loading={loading}
                                rowKey={"aciklama"}
                                dataSource={!loading ? gridData : []}
                                style={{overflow:"auto"}}
                                size={"small"}
                                pagination={false}>

                                <Column width={50} render={
                                    (text, record, index) => {
                                        return(
                                            <Space>
                                                <Button onClick={() => gridDeleteRowData(record, index)} size={"small"} type={"danger"}><FontAwesomeIcon icon={faTrash} /></Button>
                                            </Space>
                                        )
                                    }
                                }></Column>
                                <Column width={50} render={(text,record,index) => {
                                    return(
                                        <Button icon={<FontAwesomeIcon icon={faEye}></FontAwesomeIcon>} onClick={() => setAltGrupModal({isOpen:true, record:record})} size={"small"}></Button>
                                    )
                                }}>
                                </Column>
                                <Column editable={true} width={75} title={"Sıra"}  dataIndex={"rowNumber"}
                                        render={(text, record, index) => {
                                            return(
                                                <div>
                                                    <Space>
                                                        <Button onClick={() => gridUpRowData(record, index)} size={"small"}><FontAwesomeIcon icon={faArrowUp} /></Button>
                                                        <Button onClick={() => gridDownRowData(record, index)} size={"small"}><FontAwesomeIcon icon={faArrowDown} /></Button>
                                                    </Space>
                                                </div>
                                            )
                                        }}

                                ></Column>
                                <Column title={"Açıklama"} className={"w-100"} dataIndex={"aciklama"}
                                        render={(text, record, index) => {
                                            return(
                                                <Input
                                                    defaultValue={text}
                                                    style={{width:200}}
                                                    onBlur={(e) => gridUpdateRowData(record, index, e.target.value, record.formul)}
                                                />
                                            )
                                        }}
                                ></Column>
                            </Table>

                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={24}>
                <Input.TextArea value={JSON.stringify(gridData)}></Input.TextArea>
            </Col>

        </Row>
    )
}


export default IsletGelirGiderParametreComponent