import {Button, Card, Col, DatePicker, Form, Input, message, Modal, Row, Table} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GeTahsilatComponent from "./partials/GeTahsilatComponent";
import ParametreComponent from "./partials/ParametreComponent";
import GeOdemeComponent from "./partials/GeOdemeComponent";
import GeDurumComponent from "./partials/GeDurumComponent";

const GeOdemeTahsilatPage = () => {
    const [currentMonth, setCurrentMonth] = useState(dayjs(new Date).format("YYYY-MM-01"))
    const [form] = Form.useForm();
    const [geDipTahsilat, setGeDipTahsilat] = useState(null)
    const [geDipOdeme, setGeDipOdeme] = useState(null)
    const [parametreModal, setParametreModal] = useState({isOpen:false, selectedParametreKodu:null})


    const parametreModalOpen = (parametreKodu) => {
        setParametreModal({isOpen:true, selectedParametreKodu:parametreKodu})
    }

    return(
        <div className={"p-1"} style={{overflow:"auto"}}>
            <Modal
                open={parametreModal.isOpen}
                title={"Parametre"}
                destroyOnClose={true}
                width={1800}
                onCancel={() => setParametreModal({isOpen:false, selectedParametreKodu: null})}
                footer={null}

            >
                <ParametreComponent selectedParametreKodu={parametreModal.selectedParametreKodu}></ParametreComponent>
            </Modal>

            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card size={"small"} title={"FİLTRE VE PARAMETRELER"}>
                        <Form
                            layout={"horizontal"}
                            form={form}
                            colon={false}
                            labelCol={{
                                span: 15,
                            }}
                            labelAlign={"left"}>
                            <Row gutter={[10,10]}>
                                <Col >
                                    <Form.Item label={"Raporlama İstenilen Yılın Ayı"} style={{fontWeight:"bold", minWidth:400}} rules={[{ required: true },]}>
                                        <DatePicker  rootClassName={"w-100"} defaultValue={dayjs(new Date)} onChange={(e) => setCurrentMonth(dayjs(e).format("YYYY-MM-01"))} picker="month" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"}
                          title={"GERÇEKLEŞEN TAHSİLATLAR"}
                          extra={<Button onClick={() => parametreModalOpen("MUH_RPR_GERCEKLESEN_TAHSILAT_AYLIK")}><FontAwesomeIcon icon={faDatabase} /></Button>}>
                        <GeTahsilatComponent setGeDipTahsilat={setGeDipTahsilat} filterMonthDate={currentMonth}></GeTahsilatComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"}
                          title={"GERÇEKLEŞEN ÖDEMELER"}
                          extra={<Button onClick={() => parametreModalOpen("MUH_RPR_GERCEKLESEN_ODEMELER_AYLIK")}><FontAwesomeIcon icon={faDatabase} /></Button>}>
                        <GeOdemeComponent setGeDipOdeme={setGeDipOdeme} filterMonthDate={currentMonth}></GeOdemeComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"} title={"MEVCUT DURUM"}>
                        <GeDurumComponent filterMonthDate={currentMonth} geDipOdeme={geDipOdeme} geDipTahsilat={geDipTahsilat}></GeDurumComponent>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default GeOdemeTahsilatPage