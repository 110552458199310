import {message, Table} from "antd";
import Api from "../../../services/Api";
import {useEffect, useState} from "react";
import flexCurrency from "../../../utils/FlexCurrency";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";

const NakitGirisDurumuComponent  = ({startFilterDate, endFilterDate }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [fullData, setFullData] = useState([])

    const getParametre = () => {
        setLoading(true)
        Api.get("/api/Parametres/GetByCode/TAHMINI_GELIR_GIDER_AYLIK_GIRIS").then(res => {
            getData(res.data.parametreDegeri)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
            setLoading(false)
        })
    }

    const getData = (parametreDegeri) => {
        if(startFilterDate === undefined || endFilterDate === undefined){
            setLoading(false)
            return
        }

        let queryParams = JSON.parse(parametreDegeri)

        const requestDto = {
            "startDate": dayjs(startFilterDate).format("YYYY-MM-DD"),
            "endDate": dayjs(endFilterDate).format("YYYY-MM-DD"),
            "formulleHesaplaRequestDto": queryParams,
            "slidingExpiration": {
                "ticks": 99
            },
        }


        Api.post("api/MuhasebeTahminiGelirGiders/GetTahminiGelirGiderFormulToplamWidthDayQuery", requestDto).then(res => {
            const newData = [];
            res.data.forEach(item => {
                const ustGrup = { ustGrupAdi: item.ustGrupAdi, altGrup: [] };

                item.altGrup.forEach(altGrup => {
                    const newAltGrup = { aciklama: altGrup.aciklama, ustGrupAdi: item.ustGrupAdi, formul: altGrup.formul };
                    altGrup.bakiyes.forEach(bakiye => {
                        newAltGrup[bakiye.tarih] = bakiye.tutar; // bakiye.tutar değerini ekliyoruz
                    });
                    ustGrup.altGrup.push(newAltGrup);
                });
                newData.push(ustGrup);
            });



            setData(newData)
            setLoading(false)
            setFullData(res.data)

        }).catch(err => {
            message.error("Bir hata oluştu");
        })
    }

    const altGrupColumnsGenerator = (data) => {
        const columns = []

        columns.push(
            {title: "", dataIndex: "aciklama",
                key: "aciklama",
                fixed: "left",
                className:"font-weight-bold",
                render: (_,record) => {
                    return <div>{record.aciklama}</div>
                }
            }
        )


        data[0].altGrup[0].bakiyes.forEach(item => {
            columns.push(
                {title: dayjs(item.tarih).format("DD.MM.YYYY"),
                    align:"right",
                    dataIndex: item.tarih,
                    key: item.tarih,

                    width:132,
                    render: (_,record) => {
                        return flexCurrency(record[item.tarih])
                    }})
        })

        return columns
    }

    useEffect(() => {
        getParametre()
    }, []);

    useEffect(() => {
        getParametre()
    }, [startFilterDate, endFilterDate]);

    return(
        <>
            {fullData &&
                <Table
                    size={"small"}
                    showHeader={true}
                    loading={loading}
                    dataSource={data}
                    style={{overflow:"auto"}}
                    pagination={false}
                    bordered={true}
                    rowKey={"ustGrupAdi"}
                    defaultExpandAllRows={false}
                    summary={() => (
                        <Table.Summary.Row>

                            <Table.Summary.Cell index={0}>
                                <div className={"font-weight-bold text-align-right"}></div>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={1}>
                                <div className={"font-weight-bold text-align-right"}>TOPLAM</div>
                            </Table.Summary.Cell>


                            {fullData[0]?.altGrup[0]?.bakiyes.map(item => {
                                return (
                                    <Table.Summary.Cell index={item.tarih} key={item.tarih}>
                                        <div className={"font-weight-bold text-align-right"}>

                                            {
                                                flexCurrency(
                                                    data.reduce((acc, val) => {
                                                        return acc + val.altGrup.reduce((acc2, altItem) => {
                                                            const value = altItem[item.tarih];
                                                            return acc2 + (typeof value === 'number' ? value : 0);
                                                        }, 0)
                                                    }, 0)
                                                )
                                            }
                                        </div>
                                    </Table.Summary.Cell>
                                )
                            })}
                        </Table.Summary.Row>
                    )}
                    expandable={{
                        expandedRowRender: record => {
                            return (
                                <Table
                                    loading={loading}
                                    showHeader={true}
                                    bordered={true}
                                    columns={altGrupColumnsGenerator(fullData)}
                                    dataSource={record.altGrup}
                                    rowKey={"aciklama"}
                                    size={"small"}
                                    pagination={false}
                                    summary={() => (
                                        <Table.Summary.Row>

                                            <Table.Summary.Cell index={0}>
                                                <div className={"font-weight-bold text-align-right"}>TOPLAM</div>
                                            </Table.Summary.Cell>
                                            {fullData[0].altGrup[0].bakiyes.map(item => {
                                                return (
                                                    <Table.Summary.Cell index={item.tarih} key={item.tarih}>
                                                        <div className={"font-weight-bold text-align-right"}>

                                                            {
                                                                flexCurrency(
                                                                    record.altGrup.reduce((acc, altItem) => {
                                                                        const value = altItem[item.tarih];
                                                                        return acc + (typeof value === 'number' ? value : 0);
                                                                    }, 0)
                                                                )
                                                            }
                                                        </div>
                                                    </Table.Summary.Cell>
                                                )
                                            })}
                                        </Table.Summary.Row>
                                    )}
                                />
                            )
                        }
                    }}
                >
                    <Column title={""} className={"font-weight-bold"}  dataIndex={"ustGrupAdi"} key={"ustGrupAdi"} />
                    {fullData[0]?.altGrup[0]?.bakiyes.map(item => {
                        return (
                            <Column width={150} title={dayjs(item.tarih).format("DD.MM.YYYY")} dataIndex={item.tarih} key={item.tarih} align={"right"} render={(_,record) => {
                                //burada alttaki bakiyeleri toplayıp göstereceğim!!

                                return flexCurrency(
                                    record.altGrup.reduce((acc, altItem) => {
                                        const value = altItem[item.tarih];
                                        return acc + (typeof value === 'number' ? value : 0);
                                    }, 0)
                                )
                            }}/>
                        )
                    })}


                </Table>

            }

        </>
    )
}

export default NakitGirisDurumuComponent