import {useEffect, useState} from "react";
import {Card, Table} from "antd";
import Api from "../../../../services/Api";
import Column from "antd/es/table/Column";
import flexCurrency from "../../../../utils/FlexCurrency";


const BankaVadeliComponent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)


    const getData = () => {
        const hesapKodlari = [
            "102.01.02.001",
            "102.01.02.011",
            "102.01.02.021",
            "102.01.02.031",
            "102.01.02.041",
            "102.01.02.051",
            "102.02.02.001"

        ];
        Api.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data.items.sort((a,b) => b.bakiye - a.bakiye))
            setLoading(false)
        }).catch(err => console.log(err))
    }


    useEffect(() => {
        getData()
    }, []);

    return(
        <Card loading={loading} title={"VADELİ MEVDUAT HESAPLARI"} size={"small"}>
            <Table bordered={true}
                   showHeader={false}
                   rowKey={"hesapKodu"}
                   style={{overflow:"auto", maxHeight:250}}
                   dataSource={data}
                   size={"small"} pagination={false}>
                <Column title={"Hesap Adı"}  dataIndex={"hesapAdi"}></Column>
                <Column title={"Bakiye"} className={"font-color-green"} align={"right"} dataIndex={"tarifeTutar"}
                        render={(_,record) => {
                            return  flexCurrency(record.bakiye)
                        }}>
                </Column>
            </Table>
        </Card>
    )
}

export default BankaVadeliComponent