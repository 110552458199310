import {Alert, Button, Card, Col, Row} from "antd";
import GeTahsilatYillikComponent from "./partials/GeTahsilatYillikComponent";
import {useState} from "react";
import GeOdemeYillikComponent from "./partials/GeOdemeYillikComponent";
import GeDurumYillikComponent from "./partials/GeDurumYillikComponent";

const GeOdemeTahsilatYillikPage  = () => {
    const [geDipTahsilat, setGeDipTahsilat] = useState(null)
    const [geDipOdeme, setGeDipOdeme] = useState(null)

    return (

        <div className={"p-1"} style={{overflow:"auto"}}>
            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Alert
                        message="Bilgi Mesajı"
                        description={`Bu raporun görüntülenme süresi uzun olabilir. Raporu görüntülemek için beklemek zorunda değilsiniz. Rapor sunucu tarafında hazırlandıktan sonra hızlı bir şekilde görüntülenecektir. Raporun hazırlanma süresi 10 dakikaya kadar sürebilir.`}
                        type="warning"
                    />
                </Col>
                <Col span={24}>
                    <Card size={"small"}
                          title={"GERÇEKLEŞEN TAHSİLATLAR"}>
                        <GeTahsilatYillikComponent filterMonthDate={2024} setGeDipTahsilat={setGeDipTahsilat} ></GeTahsilatYillikComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"}
                          title={"GERÇEKLEŞEN ÖDEMELER"}>
                        <GeOdemeYillikComponent filterMonthDate={2024} setGeDipOdeme={setGeDipOdeme}></GeOdemeYillikComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"} title={"MEVCUT DURUM"}>
                        <GeDurumYillikComponent filterMonthDate={2024} geDipOdeme={geDipOdeme} geDipTahsilat={geDipTahsilat}></GeDurumYillikComponent>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default GeOdemeTahsilatYillikPage