import {Modal} from "antd";
import HareketDetailComponent from "./HareketDetailComponent";

const HareketModalComponent = ({isOpen, title, close, hesapKodu, width}) => {

    return (
        <Modal open={isOpen}
               title={title}
               onCancel={close}
               width={width}
               destroyOnClose={true }
               footer={null}
               centered={true}
        >
            <HareketDetailComponent hesapKodu={hesapKodu}></HareketDetailComponent>
        </Modal>
    )
}

export default HareketModalComponent;