import {Button, Card, Col, Form, Input, message, Row, Space, Switch, Tooltip} from "antd";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../../services/Api";
import {ArrowLeftOutlined, PlusOutlined} from "@ant-design/icons";

const UserAddPage = () => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const onFinisFailed = () => {
        message.error("Başarısız oldu")
    }

    const onFinish = (values) => {
        setLoading(true)
        Api.post("/api/Users", values).then(res => {
            setLoading(false)
            navigate("/users")
        }).catch(err => {
            message.error(err.response.data.Detail)
            setLoading(false)
        })
    }


    return (<div className={"p-2"}>
        <Card title={"Kullanıcı Ekle"} loading={loading}  extra={<Button size={"small"} onClick={() => navigate("/users")}><ArrowLeftOutlined /></Button>}>

            <Form
                form={form}
                labelAlign={"left"}
                onFinish={onFinish}
                onFinishFailed={onFinisFailed}
                layout={"horizontal"}
                labelCol={{
                    span: 15,
                }}
                colon={true}
            >
                <Row gutter={[10, 0]}>
                    <Col span={12}>
                        <Col span={24}>
                            <Form.Item label={"Adı"} name={"firstName"} rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Soyadı"} name={"lastName"} rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Email Adresi (Kullanıcı Adı)"} name={"email"}
                                       rules={[{required: true},]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Şifre"} name={"password"} rules={[{required: true},]}>
                                <Input.Password></Input.Password>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Kullanıcı Durumu"} valuePropName={"checked"} name="status"
                                       required={true}>
                                <Switch defaultChecked={true} checkedChildren="Kullanıcı Aktif"
                                        unCheckedChildren="Kullanıcı Pasif"/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className={"text-align-right"}>
                            <Space>
                                <Button type={"primary"} htmlType="submit">Kaydet</Button>
                                <Button type={"default"} onClick={() => navigate("/users")}>Geri Dön</Button>
                            </Space>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </Card>
    </div>)
}

export default UserAddPage