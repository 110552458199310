import {Button, Card, message, Modal, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import Api_Maden from "../../../../../services/Api_Maden";
import MadenCekSenetDetailPage from "./partials/MadenCekSenetDetailPage";
import flexCurrency from "../../../../../utils/FlexCurrency";

const MadenCekSenetPageList = () =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cekSenetModel, setCekSenetModel] = useState({isOpen:false, selectedId:0});

    const getData = () => {
        setLoading(true)
        Api_Maden.get("/api/CekSenets?PageIndex=0&PageSize=1000").then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            message.error("Veriler getirilemedi")
        })
    }

    useEffect(() => {
        getData()
    }, []);


    return(
        <div className={"p-1"}>
            <Modal
                title={"Çek-Senet"}
                open={cekSenetModel.isOpen}
                onCancel={() => setCekSenetModel({isOpen:false, selectedId: 0})}
                width={1200}
                destroyOnClose={true}
                footer={[]}

            >
                <MadenCekSenetDetailPage refreshData={() => getData()} close={() => setCekSenetModel({isOpen: false, selectedId: 0})} selectedId={cekSenetModel.selectedId}></MadenCekSenetDetailPage>
            </Modal>

            <Card title={"ÇEK SENET LİSTESİ"} size={"small"} extra={<Button onClick={() => setCekSenetModel({isOpen: true, selectedId: 0})} size={"small"} icon={<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>}></Button>}>
                <Table
                    size={"small"}
                    dataSource={data}
                    loading={loading}
                    pagination={false}
                    rowKey={"id"}
                >
                    <Column width={50} render={(_,record) => {
                        return <Button size={"small"} onClick={() => setCekSenetModel({isOpen: true, selectedId: record.id})} icon={<FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>}></Button>
                    }}></Column>
                    <Column title={"Vade Tarihi"} width={100} align={"center"} render={(_,record) => {
                        return <div>{dayjs(record.vadeTarihi).format("DD.MM.YYYY")}</div>
                    }} dataIndex={"vadeTarihi"} key={"vadeTarihi"}></Column>

                    <Column title={"Firma Adı"} className={"font-weight-bold"} dataIndex={"firmaAdi"} key={"firmaAdi"}></Column>
                    <Column title={"Banka Adı"} width={300} dataIndex={"bankaAdi"} key={"cekSenetNo"}></Column>

                    <Column width={200} title={"Tutar"} dataIndex={"tutar"} key={"tutar"} align={"right"} render={(_,record) => {
                        return <div className={"font-weight-bold"}>{flexCurrency(record.tutar)}</div>
                    }}></Column>
                    <Column title={"Açıklama"} dataIndex={"aciklama"} key={"aciklama"}></Column>
                </Table>
            </Card>
        </div>
    )
}

export default MadenCekSenetPageList