import {Button, Col, Form, Input, message, Row} from "antd";
import Api from "../../services/Api";
import {useEffect, useState} from "react";

const ParametreDetailPage = ({onClose, selectedId}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        if(selectedId){
            updateData(values)
        }else{
            addData(values)
        }
    }

    const getData = () => {
        Api.get(`/api/Parametres/${selectedId}`).then(res => {
            form.setFieldsValue(res.data)
            setLoading(false)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
        })
    }

    const addData = (values) => {
        setLoading(true)
        Api.post("/api/Parametres",values).then(res => {
            message.success("Kayıt işlemi başarılı")
            setLoading(false)
            onClose()
        }).catch(err => {
            message.error("Kayıt işlemi sırasında bir hata oluştu")
        })
    }

    const updateData = (values) => {
        Api.put("/api/Parametres",values).then(res => {
            message.success("Güncelleme işlemi başarılı")
            setLoading(false)
            onClose()
        }).catch(err => {
            message.error("Güncelleme işlemi sırasında bir hata oluştu")
        })
    }

    useEffect(() => {
        if(selectedId){
            getData()
        }
    }, [])

    return(
        <div className={"mt-1"}>
            <Form
                form={form}
                layout={"horizontal"}
                name={"parametreForm"}
                labelCol={{span:5}}
                autoComplete={"on"}
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={null}
            >
                <Row gutter={[10,5]}>
                    <Col span={24}>
                        <Form.Item
                            label={"Id"}
                            name={"id"}
                            hidden={true}
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={"Parametre Kodu"}
                            name={"parametreKodu"}
                            rules={[{required:true, message:"Parametre Kodu zorunlu"}]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={"Parametre Adı"}
                            name={"parametreAdi"}
                            rules={[{required:true, message:"Parametre Adı zorunlu"}]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={"Parametre Tipi"}
                            name={"parametreTipi"}
                            rules={[{required:true, message:"Parametre Tipi zorunlu"}]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={"Parametre Değeri"}
                            name={"parametreDegeri"}
                            rules={[{required:true, message:"Parametre Değeri zorunlu"}]}
                        >
                            <Input.TextArea rows={10}></Input.TextArea>
                        </Form.Item>
                    </Col>
                    <Col span={24} className={"mt-1"}>
                        <Button loading={loading} type={"primary"}  htmlType="submit"  className={"w-100"}>Kaydet</Button>
                    </Col>

                </Row>
            </Form>

        </div>
    )
}

export default ParametreDetailPage