import {Button, Col, Form, Input, InputNumber, Row} from "antd";

const KrediLimitPageDetail = ({selectedData, addData, updateData}) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
       if(selectedData){
           updateData(values)
       }
       else
           addData(values)
    }

    return(<>
        <Form
            layout={"horizontal"}
            form={form}
            colon={false}
            onFinish={onFinish}
            labelCol={{
                span: 10,
            }}
            labelAlign={"left"}
            initialValues={selectedData}
        >
            <Row gutter={[10,10]}>
                <Col span={13}>
                    <Form.Item label={"Banka"} name={"banka"} rules={[{ required: true },]}>
                        <Input></Input>
                    </Form.Item>
                </Col>

                <Col span={13}>
                    <Form.Item label={"Limit"} name={"limit"} rules={[{ required: true },]}>
                        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col span={13}>
                    <Form.Item label={"Kullanılan"} name={"kullanilan"} rules={[{ required: true },]}>
                        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col span={13}>
                    <Form.Item label={"Teminat Mektubu Limiti"} name={"teminatMektubuLimiti"} rules={[{ required: true },]}>
                        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col span={13}>
                    <Form.Item label={"Teminat Kullanılan"} name={"teminatKullanilan"} rules={[{ required: true },]}>
                        <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col span={13} className={"mt-1 text-align-right"}>
                    {selectedData &&
                        <Button type={"dashed"} onClick={() => deleteData()} style={{marginRight:5}} >Sil</Button>
                    }

                    <Button type={"primary"}  htmlType="submit" >Kaydet</Button>

                </Col>

            </Row>

        </Form>

    </>)
}

export default KrediLimitPageDetail;