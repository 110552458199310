import {Card, message, Table} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import Column from "antd/es/table/Column";
import FlexCurrency from "../../../../utils/FlexCurrency";
import dayjs from "dayjs";

const BankaVadeliHareketlerComponent = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState();

    const getData = () => {
        const requestFilterData = {
            "sort": [
                {
                    "field": "_Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "_Date.Date",
                "operator": "eq",
                "value": `${dayjs(new Date).format("YYYY-MM-DD")}`,
                "logic": "and",
                "filters":[
                    {
                        "field":"HesapKodu",
                        "operator":"startswith",
                        "value":"102.01.02",
                        "logic":"and"
                    }
                ]
            }
        }

        Api.post("api/MuhasebeFisis/GetListDynamic?PageIndex=0&PageSize=100&BypassCache=false", requestFilterData).then(res=> {
            setData(res.data.items)
            setLoading(false)
        }).catch(err =>{
            message.error("Hata oluştu")
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <Card title={"VADELİ BANKA HAREKETLERİ"} loading={loading} size={"small"}>
            <Table
                bordered={true}
                showHeader={false}
                rowKey={"id"}
                dataSource={data}
                style={{overflow:"auto", maxHeight:250}}
                size={"small"}
                pagination={false}>
                <Column title={"Hesap Adı"}  dataIndex={"hesapAciklama"}></Column>
                {/*<Column title={"Açıklama"}  dataIndex={"kalemAciklamasi"}></Column>*/}
                <Column title={"Ekleyen"}  dataIndex={"ekleyenKullanici"}></Column>

                <Column title={"Çıkan"} className={"font-color-red small-font"} align={"right"} dataIndex={"alacak"}
                        render={(_,record) => {
                            return  FlexCurrency(record.alacak) === "0,00" ? "" : "-"+FlexCurrency(record.alacak)
                        }}>
                </Column>

                <Column title={"Giren"} align={"right"} className={"font-color-green"} dataIndex={"borc"}
                        render={(_,record) => {
                            return FlexCurrency(record.borc) === "0,00" ? "" : "+"+FlexCurrency(record.borc)
                        }}>
                </Column>
            </Table>
        </Card>
    )
}

export default BankaVadeliHareketlerComponent