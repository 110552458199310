import {Card, Col, Drawer, Modal, Row} from "antd";
import KasaBankaToplamComponent from "../kasa/partials/Kasa/KasaBankaToplamComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClipboardCheck,
    faClockRotateLeft, faHandshake, faLayerGroup, faMagnifyingGlassChart,
    faMoneyBillTransfer,
    faMoneyBillTrendUp, faPlusMinus, faSchool
} from "@fortawesome/free-solid-svg-icons";
import KasaPage from "../kasa/KasaPage";
import {useState} from "react";
import GeOdemeTahsilatPage from "../geodemetahsilat/GeOdemeTahsilatPage";
import OdemePlaniPage from "../odemeplani/OdemePlaniPage";
import KrediLimitPage from "../kredilimit/KrediLimitPage";
import KrediTaksitleriPage from "../kreditaksitleri/KrediTaksitleriPage";
import IsletmeGelirGiderPage from "../isletmegg/IsletmeGelirGiderPage";
import GeOdemeTahsilatYillikPage from "../geodemetahsilatyillik/GeOdemeTahsilatYillikPage";
import TahminiGelirGiderDurumu from "../tahminigelirgider/TahminiGelirGiderDurumu";
import MadenGeOdemeTahsilatPage from "../companies/maden/pages/geodemetahsilat/MadenGeOdemeTahsilatPage";
import MadenKasaPage from "../companies/maden/pages/kasa/MadenKasaPage";
import MadenOdemePlaniPage from "../companies/maden/pages/odemeplani/MadenOdemePlaniPage";
import CekSenetPageList from "../ceksenet/CekSenetPageList";
import MadenCekSenetComponent from "../companies/maden/pages/kasa/partials/MadenCekSenetComponent";
import MadenCekSenetPageList from "../companies/maden/pages/ceksenet/MadenCekSenetPageList";
import AlacakListPage from "../alacak/AlacakListPage";

const DashboardPage = () => {
    const [drawer, setDrawer] = useState({isOpen:false, type:"", title:""});

    const getDrawerComponent = () => {
        switch (drawer.type) {
            case "kasa":
                return <KasaPage></KasaPage>
            case "geodemetahsilat":
                return <GeOdemeTahsilatPage></GeOdemeTahsilatPage>
            case "geodemetahsilatyillik":
                return <GeOdemeTahsilatYillikPage></GeOdemeTahsilatYillikPage>
            case "odemeplani":
                return <OdemePlaniPage></OdemePlaniPage>
            case "kredilimit":
                return <KrediLimitPage></KrediLimitPage>
            case "kredi_taksitleri":
                return <KrediTaksitleriPage></KrediTaksitleriPage>
            case "isletme_gelir_gider":
                return <IsletmeGelirGiderPage></IsletmeGelirGiderPage>
            case "tahmini_tahsilat":
                return <TahminiGelirGiderDurumu></TahminiGelirGiderDurumu>
            case "cek_senet":
                return <CekSenetPageList></CekSenetPageList>
            case "alacak_tablosu":
                return <AlacakListPage></AlacakListPage>
            case "maden_geodemetahsilat":
                return <MadenGeOdemeTahsilatPage></MadenGeOdemeTahsilatPage>
            case "maden_kasa":
                return <MadenKasaPage></MadenKasaPage>
            case "maden_odemeplani":
                return <MadenOdemePlaniPage></MadenOdemePlaniPage>
            case "maden_cek_senet":
                return <MadenCekSenetPageList></MadenCekSenetPageList>
            default:
                return <KasaPage></KasaPage>
        }
    }

    return(<div className={"p-1"}>
        <Row gutter={[10,10]}>
            <Drawer
                open={drawer.isOpen}
                placement={"top"}
                destroyOnClose={true}
                className={"drawer-panding-none drawer-custom-background"}
                title={drawer.title}
                onClose={()=>{setDrawer({isOpen:false, type:"", title:""})}}
                height={"100%"}
            >
                {getDrawerComponent()}
            </Drawer>


            <Col span={24}>
                <KasaBankaToplamComponent></KasaBankaToplamComponent>
            </Col>

            <Col span={24}>
                <Card size={"small"}
                      title={"ORBEL A.Ş"}
                      extra={"Finans Raporları"}
                >

                    <Row gutter={[10,10]}>
                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"kasa", title:"ORBEL A.Ş - KASA-BANKA DURUMU"})}
                                style={{backgroundColor:'whitesmoke',
                                borderRadius:10,
                                height:"100%",
                                justifyContent:"center",
                                alignContent:"center",
                                textAlign:"center",
                                padding:10,
                                userSelect:"none",
                                cursor:"pointer",
                                alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    KASA-BANKA DURUMU
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"geodemetahsilat", title:"ORBEL A.Ş - GERÇEKLEŞEN ÖDEME VE TAHSİLATLAR"})}
                                style={{backgroundColor:'whitesmoke',
                                borderRadius:10,
                                height:"100%",
                                justifyContent:"center",
                                alignContent:"center",
                                textAlign:"center",
                                padding:10,
                                userSelect:"none",
                                cursor:"pointer",
                                alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faClipboardCheck} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    GERÇEKLEŞEN ÖDEME VE TAHSİLATLAR
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"geodemetahsilatyillik", title:"ORBEL A.Ş - GERÇEKLEŞEN ÖDEME VE TAHSİLATLAR"})}
                                style={{backgroundColor:'whitesmoke',
                                    borderRadius:10,
                                    height:"100%",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    textAlign:"center",
                                    padding:10,
                                    userSelect:"none",
                                    cursor:"pointer",
                                    alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faLayerGroup} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    GERÇEKLEŞEN ÖDEME VE TAHSİLATLAR YILLIK
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"odemeplani", title:"ORBEL A.Ş - ÖDEME PLANI"})}
                                style={{backgroundColor:'whitesmoke',
                                borderRadius:10,
                                height:"100%",
                                justifyContent:"center",
                                alignContent:"center",
                                textAlign:"center",
                                padding:10,
                                userSelect:"none",
                                cursor:"pointer",
                                alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faClockRotateLeft} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    ÖDEME PLANI
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"kredilimit", title:"ORBEL A.Ş - KREDİ LİMİT TABLOSU"})}
                                style={{backgroundColor:'whitesmoke',
                                borderRadius:10,
                                height:"100%",
                                justifyContent:"center",
                                alignContent:"center",
                                textAlign:"center",
                                padding:10,
                                userSelect:"none",
                                cursor:"pointer",
                                alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    KREDİ LİMİT TABLOSU
                                </div>
                            </div>
                        </Col>



                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"isletme_gelir_gider", title:"ORBEL A.Ş - İŞLETME GELİR GİDER DURUMU"})}
                                style={{backgroundColor:'whitesmoke',
                                    borderRadius:10,
                                    height:"100%",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    textAlign:"center",
                                    padding:10,
                                    userSelect:"none",
                                    cursor:"pointer",
                                    alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faPlusMinus} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    İŞLETME GELİR GİDER DURUMU
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({
                                    isOpen: true,
                                    type: "tahmini_tahsilat",
                                    title: "ORBEL A.Ş - TAHMİNİ GELİR GİDER DURUMU"
                                })}
                                style={{
                                    backgroundColor: 'whitesmoke',
                                    borderRadius: 10,
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    textAlign: "center",
                                    padding: 10,
                                    userSelect: "none",
                                    cursor: "pointer",
                                    alignItems: "center"
                                }}>
                                <div style={{fontSize: "3rem"}}>
                                    <FontAwesomeIcon icon={faMagnifyingGlassChart}/>
                                </div>
                                <div style={{fontSize: "1rem", fontWeight: "bold"}}>
                                    TAHMİNİ GELİR GİDER DURUMU (YAPILIYOR)
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"cek_senet", title:"ORBEL A.Ş - ÇEK SENET"})}
                                style={{backgroundColor:'whitesmoke',
                                    borderRadius:10,
                                    height:"100%",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    textAlign:"center",
                                    padding:10,
                                    userSelect:"none",
                                    cursor:"pointer",
                                    alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faHandshake} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    ÇEK SENET
                                </div>
                            </div>
                        </Col>

                        <Col span={4}>
                            <div
                                onClick={() => setDrawer({isOpen:true, type:"alacak_tablosu", title:"ORBEL A.Ş - ALACAK TABLOSU"})}
                                style={{backgroundColor:'whitesmoke',
                                    borderRadius:10,
                                    height:"100%",
                                    justifyContent:"center",
                                    alignContent:"center",
                                    textAlign:"center",
                                    padding:10,
                                    userSelect:"none",
                                    cursor:"pointer",
                                    alignItems:"center"}}>
                                <div style={{fontSize:"3rem"}}>
                                    <FontAwesomeIcon icon={faSchool} />
                                </div>
                                <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                    ALACAK TABLOSU
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={24}>
                <Card size={"small"} title={"ORBEL MADENCİLİK A.Ş"} extra={"Finans Raporları"}>
                   <Row gutter={[10,10]}>
                       <Col span={4}>
                           <div
                               onClick={() => setDrawer({isOpen:true, type:"maden_kasa", title:"ORBEL MADENCİLİK A.Ş - KASA-BANKA DURUMU"})}
                               style={{backgroundColor:'whitesmoke',
                                   borderRadius:10,
                                   height:"100%",
                                   justifyContent:"center",
                                   alignContent:"center",
                                   textAlign:"center",
                                   padding:10,
                                   userSelect:"none",
                                   cursor:"pointer",
                                   alignItems:"center"}}>
                               <div style={{fontSize:"3rem"}}>
                                   <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                               </div>
                               <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                   KASA-BANKA DURUMU
                               </div>
                           </div>
                       </Col>

                       <Col span={4}>
                           <div
                               onClick={() => setDrawer({isOpen:true, type:"maden_geodemetahsilat", title:"ORBEL MADENCİLİK A.Ş - GERÇEKLEŞEN ÖDEME VE TAHSİLATLAR"})}
                               style={{backgroundColor:'whitesmoke',
                                   borderRadius:10,
                                   height:"100%",
                                   justifyContent:"center",
                                   alignContent:"center",
                                   textAlign:"center",
                                   padding:10,
                                   userSelect:"none",
                                   cursor:"pointer",
                                   alignItems:"center"}}>
                               <div style={{fontSize:"3rem"}}>
                                   <FontAwesomeIcon icon={faClipboardCheck} />
                               </div>
                               <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                   GERÇEKLEŞEN ÖDEME VE TAHSİLATLAR
                               </div>
                           </div>
                       </Col>
                       <Col span={4}>
                           <div
                               onClick={() => setDrawer({isOpen:true, type:"maden_odemeplani", title:"ORBEL MADENCİLİK A.Ş - ÖDEME PLANI"})}
                               style={{backgroundColor:'whitesmoke',
                                   borderRadius:10,
                                   height:"100%",
                                   justifyContent:"center",
                                   alignContent:"center",
                                   textAlign:"center",
                                   padding:10,
                                   userSelect:"none",
                                   cursor:"pointer",
                                   alignItems:"center"}}>
                               <div style={{fontSize:"3rem"}}>
                                   <FontAwesomeIcon icon={faClockRotateLeft} />
                               </div>
                               <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                   ÖDEME PLANI
                               </div>
                           </div>
                       </Col>

                       <Col span={4}>
                           <div
                               onClick={() => setDrawer({isOpen:true, type:"maden_cek_senet", title:"ORBEL MADENCİLİK A.Ş - ÇEK SENET"})}
                               style={{backgroundColor:'whitesmoke',
                                   borderRadius:10,
                                   height:"100%",
                                   justifyContent:"center",
                                   alignContent:"center",
                                   textAlign:"center",
                                   padding:10,
                                   userSelect:"none",
                                   cursor:"pointer",
                                   alignItems:"center"}}>
                               <div style={{fontSize:"3rem"}}>
                                   <FontAwesomeIcon icon={faHandshake} />
                               </div>
                               <div style={{fontSize:"1rem", fontWeight:"bold"}}>
                                   ÇEK SENET
                               </div>
                           </div>
                       </Col>
                   </Row>
                </Card>
            </Col>
        </Row>
        <div style={{
            position: "absolute",
            bottom: 10,
            width: "100%",
            color: "white",
            textAlign: "center",
            fontSize: "10px"
        }}>
            Those who contributed to this software are Sezgin Arslan and A. Adnan Bolaman.
        </div>
    </div>)

}

export default DashboardPage