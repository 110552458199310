import KasaBankaToplamComponent from "./partials/Kasa/KasaBankaToplamComponent";
import KasalarComponent from "./partials/Kasa/KasalarComponent";
import {Card, Col, Row} from "antd";
import KasaHareketleriComponent from "./partials/Kasa/KasaHareketleriComponent";
import BankaVadeliComponent from "./partials/Banka/BankaVadeliComponent";
import BankaVadesizHareketlerComponent from "./partials/Banka/BankaVadesizHareketlerComponent";
import BankaVadeliHareketlerComponent from "./partials/Banka/BankaVadeliHareketlerComponent";
import BankaDurumComponent from "./partials/Banka/BankaDurumComponent";
import BankaVadesizComponent from "./partials/Banka/BankaVadesizComponent";
import dayjs from "dayjs";
import BankaKrediKartiTutarlariPage from "./partials/Banka/BankaKrediKartiTutarlariPage";
import KasaCekSenetComponent from "./partials/CekSenet/KasaCekSenetComponent";


const KasaPage = () => {
    return(
        <div className={"p-1"}>
            <div className={"mt-0"}>
                <Card size={"small"} >
                    <div style={{display:"flex", width:"100%",justifyContent: "space-between"}}>
                        <h2>Şirket Finans Durumu </h2>
                        <h3>{dayjs(new Date()).format("D MMMM YYYY dddd")}</h3>
                    </div>
                </Card>
            </div>
            <div className={"mt-1"}>
                <KasaBankaToplamComponent></KasaBankaToplamComponent>
            </div>

            <div className={"mt-1"}>
                <Row gutter={[10,10]}>

                    <Col span={8}>
                        <div>
                            <KasaHareketleriComponent></KasaHareketleriComponent>
                        </div>

                        <div className={"mt-1"}>
                            <KasalarComponent></KasalarComponent>
                        </div>
                    </Col>

                    <Col span={16}>
                        <Row gutter={[10,10]}>
                            <Col span={24}>
                                <BankaDurumComponent></BankaDurumComponent>
                            </Col>

                            <Col span={8}>
                                <BankaVadesizComponent></BankaVadesizComponent>
                            </Col>

                            <Col span={8}>
                                <BankaVadeliComponent></BankaVadeliComponent>
                            </Col>

                            <Col span={8}>
                               <BankaKrediKartiTutarlariPage></BankaKrediKartiTutarlariPage>
                            </Col>

                            <Col span={8}>
                                <BankaVadesizHareketlerComponent></BankaVadesizHareketlerComponent>
                            </Col>
                            <Col span={8}>
                                <BankaVadeliHareketlerComponent></BankaVadeliHareketlerComponent>
                            </Col>

                            <Col span={8}>
                                <KasaCekSenetComponent></KasaCekSenetComponent>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>
        </div>
    )
}

export default KasaPage