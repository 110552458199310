import {useEffect, useState} from "react";
import dayjs from "dayjs";
import Api from "../../../services/Api";
import flexCurrency from "../../../utils/FlexCurrency";
import {message, Table} from "antd";

const GeDurumYillikComponent = ({geDipTahsilat, geDipOdeme, filterMonthDate}) => {
    const [loading, setLoading] = useState(true)
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [devirData, setDevirData] = useState([])

    const getDevirData = () => {
        setLoading(true)
        const requestDto = {
            "yil": filterMonthDate,
            "formulleHesaplaRequestDto": {
                "formul": "100+102.01",
                "hesaplamaAdi": "2024"
            }
        }

        Api.post("/api/MuhasebeFisis/GetListDevirBakiyeTarihAralikli", requestDto).then(res => {
            let resData = {"aciklama":"DEVİR BAKİYESİ"}
            res.data.devirBakiyeler.forEach(item => {
                resData[item.gosterilecekTarih] = flexCurrency(item.bakiye)
            })
            setDevirData(resData)


            if(geDipTahsilat && geDipOdeme){
                getData()
            }

        }).catch(err => {
            message.error("Devir bakiyesi çekilemedi")
        })
    }


    useEffect(() => {
        getDevirData()
    }, []);


    useEffect(() => {
        getDevirData()
    },[geDipTahsilat, geDipOdeme, filterMonthDate])

    const getData = () => {
        const columns = []
        const data = []

        //ge dip tahsilat 'da columnsları bul
        geDipTahsilat && Object.keys(geDipTahsilat).forEach(item => {
            columns.push(
                {title: item === "aciklama" ? "" : dayjs(item).format("MMMM YYYY"),
                    align:"right",
                    dataIndex: item,
                    key: item,
                    render: (_,record) => {
                        if(item === "aciklama")
                            return <div style={{minWidth:200, fontWeight:"bold", textAlign:"left"}}>{record[item]}</div>

                        //0.row sa renkli olsun


                        return record[item]
                    }})
        })

        //devir bakiyesi dataya ekleyelim
        data.push(devirData)

        //şimdide de tahsilattakileri data ya ekleyelim
        geDipTahsilat  = {...geDipTahsilat, aciklama: "TAHSİLATLAR TOPLAMI"}
        data.push(geDipTahsilat)
        //sonra da odemeleri data ya ekleyelim
        geDipOdeme = {...geDipOdeme, aciklama: "ÖDEMELER TOPLAMI"}
        data.push(geDipOdeme)

        //şimdide de tahsilattan ödemeleri çıkaralım
        const sonuc = {}
        Object.keys(geDipTahsilat).forEach(item => {
            if(item !== "aciklama"){
                let tahsilat = geDipTahsilat[item]?.replaceAll(".","").replace(",",".")
                let odenen = geDipOdeme[item]?.replaceAll(".","").replace(",",".")
                let devir = devirData[item]?.replaceAll(".","").replace(",",".")
                sonuc[item] =  flexCurrency(parseFloat(devir) + parseFloat(tahsilat) - parseFloat(odenen))
            }
        })
        sonuc["aciklama"] = "BAKİYE"
        data.push(sonuc)

        setColumns(columns)
        setData(data)
        setLoading(false)
    }

    return(
        <>
            <Table
                style={{overflow:"auto"}}
                pagination={false}
                columns={columns}
                rootClassName={"custom-small-row"}
                rowClassName={(record, index) => {
                    if(index === 0){
                        return "font-weight-bold font-color-blue"
                    }
                    if(index === 1){
                        return "font-weight-bold font-color-green"
                    }
                    if(index === 2){
                        return "font-weight-bold font-color-red"
                    }

                    if(index === 3){
                        return "font-weight-bold font-color-orange"
                    }

                    if(index === 2 && record.aciklama === "BAKİYE"){
                        return "font-weight-bold"
                    }
                }}
                dataSource={data}
                loading={loading}
                bordered={true}
                size={"small"}
                showHeader={true}
            >

            </Table>
        </>
    )
}

export default GeDurumYillikComponent