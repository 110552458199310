import {Card, Col, Row, Table} from "antd";
import Api from "../../../../services/Api";
import {useEffect, useState} from "react";
import Column from "antd/es/table/Column";
import FlexCurrency from "../../../../utils/FlexCurrency";

const KasalarComponent = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true)

    const hesapKodlari = [
        "100.01.01.001",
        "100.01.01.002",
        "100.01.01.003",
        "100.01.01.004",
        "100.01.01.005",
        "100.01.01.006",
        "100.01.01.007",
        "100.01.01.008",
        "100.01.01.009",
        "100.01.01.010",
        "100.01.01.011",
        "100.01.01.012",
        "100.01.01.013",
        // "100.01.01.016",
        "100.01.01.017",
        "100.01.05"
    ];

    const getData = () => {
        Api.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data)
            setLoading(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [])


    return(<div>
        <Card loading={loading} size={"small"} title={"KASA DURUMU"}>
            <Table
                bordered={true}
                showHeader={false}
                rowKey={"hesapKodu"}
                dataSource={data?.items}
                size={"small"}
                style={{overflow:"auto", maxHeight:385}}
                pagination={false}>
                <Column title={"Hesap Adı"}  dataIndex={"hesapAdi"}></Column>
                <Column title={"Bakiye"} align={"right"} dataIndex={"tarifeTutar"}
                        render={(_,record) => {
                            return  FlexCurrency(record.bakiye)
                        }}>
                </Column>
            </Table>
        </Card>
    </div>)
}

export default KasalarComponent