import {useEffect, useState} from "react";
import Api_Maden from "../../../../../../services/Api_Maden";
import {Card, Table} from "antd";
import Column from "antd/es/table/Column";
import flexCurrency from "../../../../../../utils/FlexCurrency";

const MadenBankaVadeliDovizComponent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const getData = () => {
        const hesapKodlari = [
            "102.04.01",


        ];
        Api_Maden.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data.items.sort((a,b) => b.bakiye - a.bakiye))
            setLoading(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <Card loading={loading} title={"VADELİ MEVDUAT HESAPLARI (DÖVİZ)"} size={"small"} style={{height:"100%"}}>
            <Table bordered={true}
                   showHeader={false}
                   rowKey={"hesapKodu"}
                   dataSource={data} size={"small"}
                   style={{overflow:"auto", maxHeight:250}}
                   pagination={false}>
                <Column title={"Hesap Adı"}  dataIndex={"hesapAdi"}></Column>
                <Column className={"font-color-green"} title={"Bakiye"} align={"right"} dataIndex={"tarifeTutar"}
                        render={(_,record) => {
                            return  flexCurrency(record.bakiye)
                        }}>
                </Column>
            </Table>
        </Card>
    )
}

export default MadenBankaVadeliDovizComponent