import {Card, Col, Row, Table} from "antd";
import dayjs from "dayjs";
import flexCurrency from "../../../utils/FlexCurrency";
import {useEffect} from "react";

const IsletmeGelirGiderModalComponent = ({data}) => {

    useEffect(() => {
        console.log(data)
    }, []);
    return(
        <Row gutter={[10,10]}>
            {data?.muhasebeFisleri?.length > 0 &&
                <Col span={24}>
                    <Card size={"small"} title={"Muhasebe Fişleri"} extra={`${data?.propName} - ${flexCurrency(data?.propValue)}`}>
                        <Table
                            dataSource={data?.muhasebeFisleri}
                            pagination={false}
                            rowKey={"index"}
                            size={"small"}
                            scroll={{ y: 500 }}

                        >
                            <Table.Column
                                title={"Tarih"} width={100} dataIndex={"tarih"} key={"tarih"} render={(text, record, index) => {
                                return <div>{dayjs(record.tarih).format("DD.MM.YYYY")}</div>
                            }}>
                            </Table.Column>
                            <Table.Column width={100} title={"Fiş No"} dataIndex={"fisNo"} key={"turuUzun"}></Table.Column>
                            <Table.Column width={100} title={"Türü"} dataIndex={"turuUzun"} key={"turuUzun"}></Table.Column>
                            <Table.Column width={130} title={"Hesap Kodu"} dataIndex={"hesapKodu"} key={"hesapKodu"}></Table.Column>
                            <Table.Column title={"Fiş Açıklama"} dataIndex={"fisAciklamasi"} key={"fisAciklamasi"}></Table.Column>
                            <Table.Column title={"Hesap Adı"} dataIndex={"hesapAciklama"} key={"hesapAciklama"}></Table.Column>
                            <Table.Column title={"Açıklama"} dataIndex={"aciklama"} key={"aciklama"}></Table.Column>

                            <Table.Column title={"Borç"} align={"right"} dataIndex={"borc"} key={"borc"} render={(text, record, index) => {
                                return flexCurrency(record.borc)

                            }}></Table.Column>
                            <Table.Column title={"Alacak"} align={"right"} dataIndex={"alacak"} key={"alacak"} render={(text,record, index) => {
                                return flexCurrency(record.alacak)
                            }}></Table.Column>
                        </Table>
                    </Card>
                </Col>
            }

            {data?.muhasebeMasrafMerkeziHareketleri?.length > 0 &&
                <Col span={24}>
                <Card size={"small"} title={"Masraf Merkezi Hareketleri"} extra={`${data?.propName} - ${flexCurrency(data?.propValue)}`}>
                    <Table
                        dataSource={data?.muhasebeMasrafMerkeziHareketleri}
                        pagination={false}
                        rowKey={"index"}
                        size={"small"}
                        style={{overflow:"auto"}}
                        scroll={{ y: 500 }}

                    >
                        <Table.Column
                            title={"Tarih"} width={100} dataIndex={"tarih"} key={"tarih"} render={(text, record, index) => {
                            return <div>{dayjs(record.tarih).format("DD.MM.YYYY")}</div>
                        }}>
                        </Table.Column>
                        <Table.Column width={100} title={"Fiş No"} dataIndex={"fisNo"} key={"turuUzun"}></Table.Column>
                        <Table.Column width={100} title={"Türü"} dataIndex={"turuUzun"} key={"turuUzun"}></Table.Column>
                        <Table.Column width={130} title={"Hesap Kodu"} dataIndex={"hesapKodu"} key={"hesapKodu"}></Table.Column>
                        <Table.Column title={"Fiş Açıklama"} dataIndex={"fisAciklamasi"} key={"fisAciklamasi"}></Table.Column>
                        <Table.Column title={"Hesap Adı"} dataIndex={"hesapAciklama"} key={"hesapAciklama"}></Table.Column>
                        <Table.Column title={"Açıklama"} dataIndex={"aciklama"} key={"aciklama"}></Table.Column>

                        <Table.Column title={"Borç"} align={"right"} dataIndex={"borc"} key={"borc"} render={(text, record, index) => {
                            return flexCurrency(record.borc)

                        }}></Table.Column>
                        <Table.Column title={"Alacak"} align={"right"} dataIndex={"alacak"} key={"alacak"} render={(text,record, index) => {
                            return flexCurrency(record.alacak)
                        }}></Table.Column>
                    </Table>
                </Card>
            </Col>
            }
        </Row>
    )
}

export default IsletmeGelirGiderModalComponent