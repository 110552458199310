import {Card, Col, Row} from "antd";
import dayjs from "dayjs";
import MadenKasaBankaToplaComponent from "./partials/MadenKasaBankaToplaComponent";
import MadenBankaVadesizComponent from "./partials/MadenBankaVadesizComponent";
import MadenBankaDurumComponent from "./partials/MadenBankaDurumComponent";
import MadenBankaVadesizDovizComponent from "./partials/MadenBankaVadesizDovizComponent";
import MadenBankaVadeliComponent from "./partials/MadenBankaVadeliComponent";
import MadenBankaVadeliDovizComponent from "./partials/MadenBankaVadeliDovizComponent";
import MadenCekSenetComponent from "./partials/MadenCekSenetComponent";

const MadenKasaPage = () => {
    return (
        <div className={"p-1"}>
            <div className={"mt-0"}>
                <Card size={"small"}>
                    <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                        <h2>Şirket Finans Durumu </h2>
                        <h3>{dayjs(new Date()).format("D MMMM YYYY dddd")}</h3>
                    </div>
                </Card>
            </div>
            <div className={"mt-1"}>
                <MadenKasaBankaToplaComponent></MadenKasaBankaToplaComponent>
            </div>

            <div className={"mt-1"}>
                <Row gutter={[10, 10]}>

                    <Col span={24}>
                        <MadenBankaDurumComponent></MadenBankaDurumComponent>
                    </Col>

                    <Col span={24}>
                       <Row gutter={[10,10]}>
                           <Col span={6}>
                               <MadenBankaVadesizComponent></MadenBankaVadesizComponent>
                           </Col>

                           <Col span={6}>
                               <MadenBankaVadesizDovizComponent></MadenBankaVadesizDovizComponent>
                           </Col>

                           <Col span={6}>
                               <MadenBankaVadeliComponent></MadenBankaVadeliComponent>
                           </Col>

                           <Col span={6}>
                               <MadenBankaVadeliDovizComponent></MadenBankaVadeliDovizComponent>
                           </Col>

                           <Col span={12}>
                               <MadenCekSenetComponent></MadenCekSenetComponent>
                           </Col>
                       </Row>
                    </Col>

                    {/*<Col span={16}>*/}
                    {/*    <Row gutter={[10, 10]}>*/}
                    {/*        <Col span={24}>*/}
                    {/*            <BankaDurumComponent></BankaDurumComponent>*/}
                    {/*        </Col>*/}

                    {/*        <Col span={8}>*/}
                    {/*            <BankaVadesizComponent></BankaVadesizComponent>*/}
                    {/*        </Col>*/}

                    {/*        <Col span={8}>*/}
                    {/*            <BankaVadeliComponent></BankaVadeliComponent>*/}
                    {/*        </Col>*/}

                    {/*        <Col span={8}>*/}
                    {/*            <BankaKrediKartiTutarlariPage></BankaKrediKartiTutarlariPage>*/}
                    {/*        </Col>*/}

                    {/*        <Col span={12}>*/}
                    {/*            <BankaVadesizHareketlerComponent></BankaVadesizHareketlerComponent>*/}
                    {/*        </Col>*/}
                    {/*        <Col span={12}>*/}
                    {/*            <BankaVadeliHareketlerComponent></BankaVadeliHareketlerComponent>*/}
                    {/*        </Col>*/}


                    {/*    </Row>*/}
                    {/*</Col>*/}

                </Row>
            </div>
        </div>
    )
}

export default MadenKasaPage