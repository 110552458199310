import {useEffect, useState} from "react";
import {Table} from "antd";
import dayjs from "dayjs";
import flexCurrency from "../../../utils/FlexCurrency";

const OdemePlaniAyllikDagilimComponent = ({data}) => {
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        getColumns()
    }, [data]);

    const getColumns = () => {
        //gelen data'da tarihlerin aylarını bulup columns oluşturacak dinamik bir şekilde

        //data'dan ayları bulalım ve bir array'e atalım ama 2024-07 gibi olacak yanında yılda olacak

        let aylar = [];
        data.forEach(item => {
            let tarih = item.odemeTarihi.split("-");
            let ay = tarih[1];
            let yil = tarih[0];

            if(aylar.indexOf(yil+"-"+ay) === -1){
                aylar.push(yil+"-"+ay);
            }
        });

        //aylar array'ini sıralayalım
        aylar.sort();

        //columns oluşturalım
        let columns = [
            {
                title: "Ödeme Türü",
                dataIndex: "odemeTuru",
                key: "odemeTuru"
            }
        ];

        //aylar array'indeki her bir eleman için bir column oluşturalım
        // title türkçe ismini getir TEMMUZ 2024 gibi olacak

        aylar.forEach(item => {
            columns.push({
                title: dayjs(item).format("MMMM YYYY"),
                dataIndex: item,
                align: "right",
                width: 130,
                render: (text, record) => {
                    return <div className={"text-align-right"}>{flexCurrency(text)}</div>
                },
                key: item
            })
        });

        //satır toplamını gösteren bir column ekleyelim
        columns.push({
            title: "TOPLAM",
            dataIndex: "toplam",
            align: "right",
            width: 130,
            render: (text, record) => {
                let toplam = 0;
                aylar.forEach(item => {
                    toplam += record[item];
                });

                return <div className={"text-align-right font-weight-bold"}>{flexCurrency(toplam)}</div>
            },
            key: "toplam"
        });

        //columns'u state'e atayalım
        setColumns(columns);


        getDataSource()

    }

    const getDataSource = () => {
        //datasource oluşturalım
        //her bir item için bir satır olacak
        //her bir item'daki ay ve yıla göre ilgili column'a ekleme yapılacak
        // ilk önce data'yı OdemeTuru'na göre gruplayalım

        let gruplar = {};
        data.forEach(item => {
            if(gruplar[item.odemeTuru] === undefined){
                gruplar[item.odemeTuru] = [];
            }

            gruplar[item.odemeTuru].push(item);
        });


        let aylar = [];
        data.forEach(item => {
            let tarih = item.odemeTarihi.split("-");
            let ay = tarih[1];
            let yil = tarih[0];

            if(aylar.indexOf(yil+"-"+ay) === -1){
                aylar.push(yil+"-"+ay);
            }
        });


         //burada gruplanmış datayı aylara göre gruplayıp ilgili column'a ekleme yapacağız
        let yeniData = [];
        for (const [key, value] of Object.entries(gruplar)) {
            let item = {
                odemeTuru: key
            };

            aylar.forEach(ay => {
                let toplam = 0;
                value.forEach(val => {
                    let tarih = val.odemeTarihi.split("-");
                    let ayYil = tarih[0]+"-"+tarih[1];

                    if(ayYil === ay){
                        toplam += val.tutar;
                    }
                });

                item[ay] = toplam;
            });

            yeniData.push(item);
        }

        //toplam satırı ekleyelim
        let toplamItem = {
            odemeTuru: ""
        };

        aylar.forEach(ay => {
            let toplam = 0;
            yeniData.forEach(item => {
                toplam += item[ay];
            });

            toplamItem[ay] = toplam;
        });

        yeniData.push(toplamItem);
        setDataSource(yeniData);
    }

    return(<div>
        <Table size={"small"}
               pagination={false}
               className={"custom-small-row"}
               rowClassName={(record, index) => {
                   return index === dataSource.length - 1 ? 'font-weight-bold font-color-red' : '';
               }}
               dataSource={dataSource}
               columns={columns} />

    </div>)
}

export default OdemePlaniAyllikDagilimComponent