import {Card, Col, Image, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import Api from "../../services/Api";
import flexCurrency from "../../utils/FlexCurrency";
import ObbCariHesapDetayPage from "../obbcarihesap/ObbCariHesapDetayPage";

const AlacakListPage = () => {
    const [data,setData] = useState([]);

    const getData = () => {
        const requestDto = {
            "hesapKods": [
                "120.01.02","120.03.02","120.03.01","120.01.06"
            ]
        }


        Api.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", requestDto).then((res) => {
            setData(res.data.items);
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getData();
    }, []);
    return(
        <div className={"p-1"}>
            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card title={"ALACAK LİSTESİ"} size={"small"}>
                        <Table
                            dataSource={data} size={"small"} pagination={false}>
                            <Table.Column title={"Hesap Kodu"} dataIndex={"hesapKodu"} key={"hesapKodu"}></Table.Column>
                            <Table.Column title={"Hesap Adı"} dataIndex={"hesapAdi"} key={"hesapAdi"}></Table.Column>
                            <Table.Column title={"Borç"} dataIndex={"borc"} align={"right"} render={(_,record) => {
                                return flexCurrency(record.bakiye)
                            }} key={"borc"}></Table.Column>
                        </Table>
                    </Card>
                </Col>

                <Col span={24}>
                   <ObbCariHesapDetayPage></ObbCariHesapDetayPage>
                </Col>

            </Row>
        </div>
    )
}

export default AlacakListPage