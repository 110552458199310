import React, {useState} from "react";
import {Card, Col, Row, Image, Modal, Button} from "antd";
import ObbHizmetAlacakToArrayComponent from "./partials/ObbHizmetAlacakToArrayComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";


const ObbCariHesapDetayPage = () => {
    const [obbHizmetAlacakToArrayModal, setObbHizmetAlacakToArrayModal] = useState(false);
    return(
        <div>
            <Modal title={"Yükleme İşlemleri"}
                   open={obbHizmetAlacakToArrayModal}
                   footer={[]}
                   destroyOnClose={true}
                   width={1800}
                   onCancel={() => setObbHizmetAlacakToArrayModal(false)}
            >
                <ObbHizmetAlacakToArrayComponent></ObbHizmetAlacakToArrayComponent>
            </Modal>
            <Card title={"OBB HİZMET ALACAĞI"} size={"small"} extra={<Button onClick={() => setObbHizmetAlacakToArrayModal(true)} icon={<FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>}></Button>}>
                <div className={"text-align-center"}>
                    <img src={process.env.PUBLIC_URL + '/Resim1.png'} alt={""}></img>
                </div>
            </Card>
        </div>
    )
}

export default ObbCariHesapDetayPage