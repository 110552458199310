import {Button, Card, Col, Form, Input, message, Row, Space, Table} from "antd";
import Column from "antd/es/table/Column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import Api from "../../../../../services/Api";

const NakitCikisParametreComponent = ({selectedParametreKodu}) => {
    const [loading, setLoading] = useState(true)
    const [gridData, setGridData] = useState([])
    const [form] = Form.useForm()
    const [paramatreDuzenleyicisiForm] = Form.useForm()

    const gridUpRowData = (record, index) => {
        if(index === 0) return

        gridData[index] = gridData[index - 1]
        gridData[index - 1] = record
        setGridData([...gridData])

    }

    const gridDownRowData = (record, index) => {
        if(index === gridData.length - 1) return
        gridData[index] = gridData[index + 1]
        gridData[index + 1] = record
        setGridData([...gridData])
    }

    const gridDeleteRowData = (record, index) => {
        gridData.splice(index, 1)
        setGridData([...gridData])
    }

    const gridAddRowData = (aciklama, formulStr) => {
        gridData.push({rowNumber: gridData.length + 1, aciklama:aciklama, formulStr:formulStr})
        setGridData([...gridData])
    }

    const gridUpdateRowData = (record, index, aciklama, formulStr) => {
        gridData[index] = {rowNumber: gridData.length + 1, aciklama:aciklama, formulStr:formulStr}
        setGridData([...gridData])
    }

    const gridAddRow = (values) => {
        gridAddRowData(values.formulStr, values.aciklama)
        paramatreDuzenleyicisiForm.resetFields()
    }

    const getParametre = () => {
        Api.get(`api/Parametres/GetByCode/${selectedParametreKodu}`).then(res => {
            form.setFieldsValue(res.data)
            setLoading(false)
            setGridData(JSON.parse(res.data.parametreDegeri))
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
        })
    }

    const onFinish = (values) => {
        Api.put("api/Parametres/", values).then(res => {
            message.success("Parametre bilgileri güncellendi")
        }).catch(err => {
            message.error("Parametre bilgileri güncellenirken hata oluştu")
        })
    }

    useEffect(() => {
        if(selectedParametreKodu){
            getParametre()
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            form.setFieldsValue({parametreDegeri: JSON.stringify(gridData)})
            setLoading(false)
        }, 100)
    }, [gridData]);

    return(
        <Row gutter={[10,10]}>
            <Col span={6}>
                <Card title={"Parametre Detay"} size={"small"}>
                    <Form
                        form={form}
                        layout={"vertical"}
                        name={"parametreForm"}
                        colon={false}
                        // labelCol={{span:30}}
                        autoComplete={"on"}
                        initialValues={{}}
                        onFinish={onFinish}
                    >
                        <Row gutter={[10,10]}>
                            <Col span={24}>
                                <Form.Item hidden={true} label={"Parametre Id"} name={"id"} rules={[{ required: true },]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={"Parametre Kodu"} name={"parametreKodu"} rules={[{ required: true },]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={"Parametre Tipi"} name={"parametreTipi"} rules={[{ required: true },]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={"Parametre Adı"} name={"parametreAdi"} rules={[{ required: true },]}>
                                    <Input disabled={true}/>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label={"Parametre Kodu"} name={"parametreDegeri"} rules={[{ required: true },]}>
                                    <Input.TextArea disabled={true} rows={10} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Button htmlType={"submit"} className={"w-100"}>Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>

                </Card>

            </Col>

            <Col span={18}>
                <Card size={"small"} title={"Paramatre Düzenleyicisi"}>
                    <Row gutter={[10,10]}>
                        <Col span={24}>
                            <Form
                                labelCol={{span:4}}
                                layout={"horizontal"}
                                onFinish={gridAddRow}
                                form={paramatreDuzenleyicisiForm}
                            >
                                <Row gutter={[10,10]}>
                                    <Col span={24}>
                                        <Form.Item vertical={true} label={"Formül"} name={"formulStr"} rules={[{ required: true },]}>
                                            <Input   />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={"Açıklama"} name={"aciklama"} rules={[{ required: true },]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} className={"text-align-right"}>
                                        <Button type={"primary"} htmlType={"submit"}>Formülü Ekle</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>

                        <Col span={24}>
                            <Table
                                bordered={true}
                                showHeader={true}
                                loading={loading}
                                rowKey={"id"}
                                virtual={true}
                                dataSource={!loading ? gridData : []}
                                style={{overflow:"auto", maxHeight:500}}
                                size={"small"}
                                pagination={false}>

                                <Column width={50} render={
                                    (text, record, index) => {
                                        return(
                                            <Space>
                                                <Button onClick={() => gridDeleteRowData(record, index)} size={"small"} type={"danger"}><FontAwesomeIcon icon={faTrash} /></Button>
                                            </Space>
                                        )
                                    }
                                }></Column>
                                <Column editable={true} width={75} title={"Sıra"}  dataIndex={"rowNumber"}
                                        render={(text, record, index) => {
                                            return(
                                                <div>
                                                    <Space>
                                                        <Button onClick={() => gridUpRowData(record, index)} size={"small"}><FontAwesomeIcon icon={faArrowUp} /></Button>
                                                        <Button onClick={() => gridDownRowData(record, index)} size={"small"}><FontAwesomeIcon icon={faArrowDown} /></Button>
                                                    </Space>
                                                </div>
                                            )
                                        }}

                                ></Column>
                                <Column title={"Açıklama"} width={200} dataIndex={"aciklama"}
                                        render={(text, record, index) => {
                                            return(
                                                <Input
                                                    defaultValue={text}
                                                    style={{width:200}}
                                                    onBlur={(e) => gridUpdateRowData(record, index, e.target.value, record.formul)}
                                                />
                                            )
                                        }}
                                ></Column>
                                <Column title={"Formül"}  dataIndex={"formulStr"}
                                        render={(text, record, index) => {
                                            return(
                                                <Input.TextArea
                                                    defaultValue={text}
                                                    onBlur={(e) => gridUpdateRowData(record, index, record.aciklama, e.target.value)}
                                                />
                                            )
                                        }}
                                ></Column>
                            </Table>

                        </Col>
                    </Row>
                </Card>

            </Col>
        </Row>
    )
}

export default NakitCikisParametreComponent