import {Button, Card, Col, Input, List, message, Modal, Row, Space, Table,} from "antd";
import Api from "../../../../../services/Api";
import {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faFloppyDisk, faTrash} from "@fortawesome/free-solid-svg-icons";

const TahminiGelirGiderParametreComponentModal = ({isActive, isClose}) => {
    return(<Modal
        title={"Tahmini Gelir Gider Parametre Düzenleyici"}
        open={isActive}
        onCancel={isClose}
        onOk={isClose}
        width={1200}
        destroyOnClose={true}
        footer={null}
        centered={false}

    >
        <TahminirGelirGiderParametreComponent selectedParametreKodu={"TAHMINI_GELIR_GIDER_AYLIK_GIRIS"}></TahminirGelirGiderParametreComponent>
    </Modal>)
}

const TahminirGelirGiderParametreComponent = ({selectedParametreKodu}) => {
    const [loading, setLoading] = useState(true)
    const [gridData, setGridData] = useState([])
    const ustGrupAdiRef = useRef(null);
    const [selectedParametreData, setSelectedParametreData] = useState(null)

    const getParametre = () => {
        Api.get(`api/Parametres/GetByCode/${selectedParametreKodu}`).then(res => {
            setLoading(false)
            setGridData(JSON.parse(res.data.parametreDegeri))
            setSelectedParametreData(res.data)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
        })
    }

    const addUstGrup = () => {
        const check = gridData.find(x => x.ustGrupAdi === ustGrupAdiRef.current.input.value)
        if(check){
            message.error("Bu üst grup daha önce eklenmiş")
            return;
        }

        const ustGrupAdi = ustGrupAdiRef.current.input.value;
        if (ustGrupAdi) {
            setGridData([...gridData, { ustGrupAdi: ustGrupAdi }]);
            ustGrupAdiRef.current.input.value = "";
        }
    }

    const deleteUstGrup = (ustGrupAdi) => {
        setGridData(gridData.filter(x => x.ustGrupAdi !== ustGrupAdi))
    }

    const upUstGrup = (ustGrupAdi, index) => {

        if(index === 0){
            return;
        }
        const temp = gridData[index];
        gridData[index] = gridData[index-1];
        gridData[index-1] = temp;
        setGridData([...gridData])
    }

    const downUstGrup = (ustGrupAdi, index) => {
        if(index === gridData.length - 1){
            return;
        }
        const temp = gridData[index];
        gridData[index] = gridData[index+1];
        gridData[index+1] = temp;
        setGridData([...gridData])
    }

    const onFinish = (values) => {
        Api.put("api/Parametres/", values).then(res => {
            message.success("Parametre bilgileri güncellendi")
        }).catch(err => {
            message.error("Parametre bilgileri güncellenirken hata oluştu")
        })
    }

    const saveData = () => {

        const data = {...selectedParametreData, parametreDegeri: JSON.stringify(gridData)}

        Api.put("api/Parametres/", data).then(res => {
            message.success("Parametre bilgileri güncellendi")
        }).catch(err => {
            message.error("Parametre bilgileri güncellenirken hata oluştu")
        })
    }

    useEffect(() => {
        if(selectedParametreKodu){
            getParametre()
        }
    }, [])


    return(
        <div>
            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card size={"small"} title={"Yeni Parametre Üst Grup"}>

                        <Row className={"justify-content-between d-flex"}>
                            <Col>
                                <Space>
                                    <Input addonBefore={"Üst Grup Adı"} ref={ustGrupAdiRef} name={"ustGrupAdi"} className={"w-100"} ></Input>
                                    <Button onClick={() => addUstGrup()}>Ekle</Button>
                                </Space>
                            </Col>

                            <Col>
                                <Space>
                                    <Button onClick={saveData} type={"primary"} icon={<FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>}>Değişiklikleri Kaydet</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={24}>
                    <Table
                        dataSource={gridData}
                        size={"small"}
                        showHeader={true}
                        loading={loading}
                        style={{overflow:"auto", height: "calc(100vh - 335px)"}}
                        pagination={false}
                        bordered={true}
                        rowKey={"ustGrupAdi"}
                        defaultExpandAllRows={false}

                        expandable={{
                            expandedRowRender: record => {
                                return <AltGrupComponent setGridData={setGridData} gridData={gridData} record={record}></AltGrupComponent>
                            }}}
                        >
                            <Table.Column width={50} render={(e,record, index) => {
                                return(
                                    <Space>
                                        <Button size={"small"} icon={<FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>} onClick={() => upUstGrup(record.ustGrupAdi, index)}></Button>
                                        <Button size={"small"} icon={<FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>} onClick={() => downUstGrup(record.ustGrupAdi, index)}></Button>
                                        <Button icon={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>} onClick={() => deleteUstGrup(record.ustGrupAdi)} type={"default"} size={"small"}></Button>
                                    </Space>
                                )
                            }}></Table.Column>
                            <Table.Column title={"Üst Grup"} dataIndex={"ustGrupAdi"}></Table.Column>
                    </Table>
                </Col>

            </Row>
        </div>
    )
}


const AltGrupComponent = ({record, gridData, setGridData}) => {
    const [altGrupAdi, setAltGrupAdi] = useState("")
    const [altGrupAciklama, setAltGrupAciklama] = useState("")

    const addAltGrup = (ustGrupAdi) => {
        const check = gridData.find(x => x.ustGrupAdi === ustGrupAdi)

        if(check.altGrup){
            const checkAlt = check.altGrup.find(x => x.formul === altGrupAdi && x.aciklama === altGrupAciklama)
            if(checkAlt){
                message.error("Bu alt grup daha önce eklenmiş")
                return;
            }
        }


        if (altGrupAdi) {
            const newGridData = gridData.map(x => {
                if(x.ustGrupAdi === ustGrupAdi){
                    if(x.altGrup){
                        x.altGrup.push({formul: altGrupAdi, aciklama: altGrupAciklama})
                    }else{
                        x.altGrup = [{formul: altGrupAdi, aciklama: altGrupAciklama}]
                    }
                }
                return x;
            })
            setGridData(newGridData)
            setAltGrupAciklama("")
            setAltGrupAdi("")
        }

    }

    const deleteAltGrup = (ustGrupAdi, formul) => {
        const newGridData = gridData.map(x => {
            if(x.ustGrupAdi === ustGrupAdi){
                x.altGrup = x.altGrup.filter(y => y.formul !== formul)
            }
            return x;
        })
        setGridData(newGridData)
    }

    const upAltGrup = (ustGrupAdi, formul, index) => {
        if(index === 0){
            return;
        }
        const newGridData = gridData.map(x => {
            if(x.ustGrupAdi === ustGrupAdi){
                const temp = x.altGrup[index];
                x.altGrup[index] = x.altGrup[index-1];
                x.altGrup[index-1] = temp;
            }
            return x;
        })
        setGridData(newGridData)
    }

    const downAltGrup = (ustGrupAdi, formul, index) => {
        if(index === gridData.find(x => x.ustGrupAdi === ustGrupAdi).altGrup.length - 1){
            return;
        }
        const newGridData = gridData.map(x => {
            if(x.ustGrupAdi === ustGrupAdi){
                const temp = x.altGrup[index];
                x.altGrup[index] = x.altGrup[index+1];
                x.altGrup[index+1] = temp;
            }
            return x;
        })
        setGridData(newGridData)
    }

    return(
        <Card size={"small"} id={record.id} key={record.ustGrupApi}>
            <Row gutter={[0,5]}>
                <Col span={24}>
                    <Space>
                        <Input onChange={(e) => setAltGrupAdi(e.target.value)} addonBefore={"Özel Kodu"} name={"formul"} ></Input>
                        <Input onChange={(e) => setAltGrupAciklama(e.target.value)} addonBefore={"Açıklama"} name={"aciklama"}></Input>
                        <Button onClick={() => addAltGrup(record.ustGrupAdi)}>Ekle</Button>
                    </Space>
                </Col>

                <Col span={24} style={{padding:0}} >
                    <List
                        size="small"
                        bordered
                        dataSource={record.altGrup}
                        renderItem={(item, index) => <List.Item key={index}> <Space>
                            <Button size={"small"} onClick={() => upAltGrup(record.ustGrupAdi, item.formul, index)} icon={<FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>}></Button>
                            <Button size={"small"} onClick={() => downAltGrup(record.ustGrupAdi, item.formul, index)} icon={<FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>}></Button>
                            <Button size={"small"} onClick={() => deleteAltGrup(record.ustGrupAdi, item.formul)} icon={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> }></Button>
                            {item.formul}
                            {item.aciklama}
                        </Space></List.Item>}
                    />
                </Col>
            </Row>
        </Card>
    )
}


export default TahminiGelirGiderParametreComponentModal;