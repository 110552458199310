import {useEffect, useState} from "react";
import Api from "../../../../../../services/Api";
import {Card, Table} from "antd";
import flexCurrency from "../../../../../../utils/FlexCurrency";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import Api_Maden from "../../../../../../services/Api_Maden";

const MadenCekSenetComponent = () => {

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const getData = () => {
        Api_Maden.get("/api/CekSenets?PageIndex=0&PageSize=1000").then(res => {
            //vade tarihi ne göre sırala ve vade tarihi geçmiş olanları kırmızı yap
            res.data.items.sort((a,b) => {
                if(a.vadeTarihi < b.vadeTarihi) return -1;
                if(a.vadeTarihi > b.vadeTarihi) return 1;
                return 0;
            })

            setData(res.data.items)
            setTotal(res.data.items.reduce((a,b) => a + b.tutar,0))
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <Card title={"ÇEK ALACAKLARI"} size={"small"} style={{height:"100%"}} extra={<div className={"font-weight-bold font-color-green"}>{flexCurrency(total)}</div>}>
            <Table dataSource={data} size={"small"} pagination={false} showHeader={false}>
                <Column title={"Vade Tarihi"} dataIndex={"vadeTarihi"} key={"vadeTarihi"} render={(_,record) => {
                    return dayjs(record?.vadeTarihi).format("DD.MM.YYYY")
                }}></Column>
                <Column title={"Vade Tarihi"} dataIndex={"firmaAdi"} key={"firmaAdi"}></Column>
                <Column title={"Vade Tarihi"}  className={"font-weight-bold"} align={"right"} dataIndex={"tutar"} key={"tutar"} render={(_,record) => {
                    return flexCurrency(record?.tutar)
                }}></Column>
            </Table>
        </Card>
    )
}

export default MadenCekSenetComponent