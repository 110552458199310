import {Button, Col, Descriptions, Form, Input, InputNumber, message, Row, Table} from "antd";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import {Html} from "@react-email/html";
import {render} from "@react-email/components";
import { useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import Api_Maden from "../../../../../../services/Api_Maden";
import flexCurrency from "../../../../../../utils/FlexCurrency";
import FormattedInputNumber from "../../../../../../utils/FormattedInputNumber";

const MadenOdemePlaniOlusturucuOzetComponent = ({selectedRows}) => {
    const [data, setData] = useState(selectedRows)
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem("user"));

    const getData = () => {
        selectedRows.map((item) => {
            item.odenecekTutar = item.tutar;
        })
        setData(selectedRows);
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, []);

    const refreshDataTable = () => {
        setLoading(true)
        const data_old = data;
        // setData([]);
        setTimeout(() => {
            setData(data_old);
            setLoading(false)
        }, 100);
    }

    const Email = () => {
        return (
            <Html lang="tr" dir="ltr" >

               <Row gutter={[10,10]}>
                    <Col span={24} style={{textAlign:"center", backgroundColor:'whitesmoke', padding:"0.5rem"}}>
                        <h3>ORBEL MADENCİLİK A.Ş ÖDEME PLANI LİSTESİ</h3>
                    </Col>

                   <Col span={24}>
                       <Table
                           pagination={false}
                           bordered={true}
                           size={"small"}
                           // style={{overflow:"auto"}}
                           // className={"custom-small-row"}
                           loading={loading}
                           showHeader={true}
                           key={"id"}
                           rowKey={"id"}
                           summary={() => (
                               <Table.Summary.Row>
                                   <Table.Summary.Cell index={0}>
                                       {(() => {
                                           return <div className={"font-weight-bold text-align-right"}></div>
                                       })()}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={1}>
                                       {(() => {
                                           return <div className={"font-weight-bold text-align-right font-color-green"}></div>
                                       })()}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={2}>
                                       {(() => {
                                           return <div className={"font-weight-bold text-align-right font-color-green"}></div>
                                       })()}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={3}>
                                       {(() => {
                                           return <div className={"font-weight-bold text-align-right font-color-green"}></div>
                                       })()}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={4}>
                                       {(() => {
                                           return <div className={"font-weight-bold text-align-right"}>TOPLAM:</div>
                                       })()}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={5}>
                                       {(() => {
                                           let toplam = 0;
                                           data.map((item) => {
                                               toplam += item.tutar;
                                           });

                                           return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(toplam)}</div>
                                       })()}
                                   </Table.Summary.Cell>
                                   <Table.Summary.Cell index={6}>
                                       {(() => {
                                           let toplam = 0;
                                           data.map((item) => {
                                               toplam += item.odenecekTutar;
                                           });

                                           return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(toplam)}</div>
                                       })()}
                                   </Table.Summary.Cell>
                               </Table.Summary.Row>
                           )}
                           dataSource={data}>
                           <Column
                               title={"Ödeme Tarihi"}
                               align={"center"}
                               width={125}
                               sorter={(a, b) => dayjs(a.odemeTarihi).unix() - dayjs(b.odemeTarihi).unix()}
                               render={(text, record, index) => {
                                   return dayjs(record.odemeTarihi).format("DD.MM.YYYY")
                               }} dataIndex={"odemeTarihi"}></Column>

                           <Column title={"Durum"} align={"center"} width={150}
                                   sorter={(a, b) => a.durum.localeCompare(b.durum)}
                                   render={(text,record,index) => {
                                       if(record.durum === "ÖDENDİ") {
                                           return <div style={{color:"green"}}>ÖDENDİ</div>
                                       }
                                       else if(record.durum === "GECİKTİ") {
                                           return <div style={{color:"red"}}>GECİKTİ</div>
                                       }
                                       else if(record.durum === "BEKLİYOR") {
                                           return <div style={{color:"orange"}}>BEKLİYOR</div>
                                       }

                                       else {
                                           return <div style={{color:"black"}}>{record.durum}</div>
                                       }
                                   }} dataIndex={"durum"}></Column>

                           <Column
                               title={"Ödeme Yeri"}
                               align={"center"}
                               sorter={(a, b) => a.odemeYeri.localeCompare(b.odemeYeri)}
                               width={150}
                               dataIndex={"odemeYeri"}></Column>


                           <Column
                               title={"Ödeme Türü"}
                               sorter={(a, b) => a.odemeTuru.localeCompare(b.odemeTuru)}
                               dataIndex={"odemeTuru"}></Column>


                           <Column
                               title={"Ödeme Adı"}
                               sorter={(a, b) => a.odemeAdi.localeCompare(b.odemeAdi)}
                               dataIndex={"odemeAdi"}></Column>

                           <Column
                               title={"Tutar"}
                               align={"right"}
                               className={"font-weight-bold"}
                               width={150}
                               sorter={(a, b) => a.tutar - b.tutar}
                               render={(text,record,index) => {
                                   return flexCurrency(record.tutar)
                               }}
                               dataIndex={"tutar"}></Column>


                           <Column
                               align={"right"}
                               className={"font-weight-bold"}
                               width={200}
                               sorter={(a, b) => a.tutar - b.tutar}
                               title={"Ödenecek Tutarı"}
                                 render={(text,record,index) => {
                                     return <FormattedInputNumber
                                         onChange={(value) => {
                                                // ödeme tutarı tutardan büyük olamaz o zaman bişey yapmayacağız
                                                if(value > record.tutar) {
                                                    message.error("Ödenecek tutar, fatura tutarından büyük olamaz");
                                                    return;
                                                }

                                                record.odenecekTutar = value; // Kaydın değerini güncelliyoruz
                                         }}
                                         onBlur={() => refreshDataTable()}
                                         defaultValue={record.odenecekTutar}>
                                     </FormattedInputNumber>

                                 }}
                           >

                           </Column>

                       </Table>
                   </Col>


                   <Col span={24} className={"mt-0-5"}>
                       <Descriptions bordered={true}>
                           <Descriptions.Item span={12} label="Oluşturulma Zamanı">{dayjs(new Date).format("DD.MM.YYYY HH:mm:ss")}</Descriptions.Item>
                           <Descriptions.Item span={12} label="Oluşturan">{user?.detail?.email}</Descriptions.Item>
                       </Descriptions>
                   </Col>

               </Row>

            </Html>
        );
    };

    const sendEmail = () => {
        const htmlRender = render(<Email></Email>);

        if(user?.detail?.email === null || user?.detail?.email === undefined) {
            message.error("Kullanıcıya ait bir mail adresi bulunamadı");
            return;
        }

        setLoading(true)

        const requestDto = {
            "subject": "ORBEL MADENCİLİK A.Ş - "+dayjs(new Date).format("DD.MM.YYYY HH:mm:ss")+" tarihinde oluşturulan ödeme planı listesi ekte bulunmaktadır.",
            "textBody": "ORBEL MADENCİLİK A.Ş - "+dayjs(new Date).format("DD.MM.YYYY HH:mm:ss")+" tarihinde oluşturulan ödeme planı listesi ekte bulunmaktadır.",
            "htmlBody": htmlRender,
            "toList": [
                user?.detail?.email
            ],
            "muhasebeOdemePlaniList": data
        }

        Api_Maden.post("/api/MuhasebeOdemePlanis/OdemePlaniSendMailToBankFormat", requestDto).then((res) => {
            message.success("Mail başarıyla gönderildi");
            setLoading(false)
        }).catch(err => {
            message.error("Mail gönderilirken bir hata oluştu");
            setLoading(false)
        });
    }

    return(
        <>
            <div className={"p-1"}>
                <Email></Email>

                <Col span={24} className={"mt-2"}>
                    <Button type={"primary"} onClick={sendEmail} icon={<FontAwesomeIcon icon={faPaperPlane} />} loading={loading} className={"w-100"}>Ödeme Planı Listesini Sistemde Kayıtlı Olan ({user.detail.email}) Adresime Gönder</Button>
                </Col>
            </div>
        </>

    )
}

export default MadenOdemePlaniOlusturucuOzetComponent;