import {useEffect, useState} from "react";
import Api_Maden from "../../../../../../services/Api_Maden";
import {Badge, Card, Col, Divider, Row} from "antd";
import FlexCurrency from "../../../../../../utils/FlexCurrency";

const MadenKasaBankaToplaComponent = () => {
    const [data, setData] = useState()
    const [loading,setLoading] = useState(true)

    const getData = () => {
        const hesapKodlari = ["100", "102"];
        Api_Maden.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data)
            setLoading(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [])

    return(
        <Row gutter={[10,10]}>
            <Col span={8}>
                <Badge.Ribbon text="ORBEL MADENCİLİK A.Ş" color={"orange"}>
                    <Card loading={loading} size={"small"} title={"KASA - 100"}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                            <div>Alacak</div>
                                            <div className={"font-weight-bold"}>{FlexCurrency(data?.items[0]?.alacak)}</div>
                                        </div>
                                    </Col>
                                    <Divider style={{margin:5}} />
                                    <Col span={24}>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <div>Borç</div>
                                            <div className={"font-weight-bold"}>{FlexCurrency(data?.items[0]?.borc)}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <div className={"text-align-right"}>
                                    <div style={{fontSize:20, fontWeight:600}}>Bakiye</div>
                                    <div className={"font-color-green"} style={{fontSize: 30}}>{FlexCurrency(data?.items[0]?.bakiye)}</div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Badge.Ribbon>
            </Col>

            <Col span={8}>
                <Badge.Ribbon text="ORBEL MADENCİLİK A.Ş" color={"orange"}>
                    <Card loading={loading} size={"small"} title={"BANKA - 102"}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                            <div>Alacak</div>
                                            <div className={"font-weight-bold"}>{FlexCurrency(data?.items[1]?.alacak)}</div>
                                        </div>
                                    </Col>
                                    <Divider style={{margin:5}} />
                                    <Col span={24}>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <div>Borç</div>
                                            <div className={"font-weight-bold"}>{FlexCurrency(data?.items[1]?.borc)}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <div className={"text-align-right"}>
                                    <div style={{fontSize:20, fontWeight:600}}>Bakiye</div>
                                    <div className={"font-color-green"} style={{fontSize: 30}}>{FlexCurrency(data?.items[1]?.bakiye)}</div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Badge.Ribbon>
            </Col>

            <Col span={8}>
                <Badge.Ribbon text="ORBEL MADENCİLİK A.Ş" color={"orange"}>
                    <Card loading={loading} size={"small"} title={"TOPLAM"}>
                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                            <div>Toplam Alacak</div>
                                            <div className={"font-weight-bold"}>{FlexCurrency(data?.toplamAlacak)}</div>
                                        </div>
                                    </Col>
                                    <Divider style={{margin:5}} />
                                    <Col span={24}>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <div>Toplam Borç</div>
                                            <div className={"font-weight-bold"}>{FlexCurrency(data?.toplamBorc)}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <div className={"text-align-right"}>
                                    <div style={{fontSize:20, fontWeight:600}}>Bakiye</div>
                                    <div className={"font-color-green"} style={{fontSize: 30}}>{FlexCurrency(data?.toplamBakiye)}</div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Badge.Ribbon>
            </Col>
        </Row>
    )
}

export default MadenKasaBankaToplaComponent