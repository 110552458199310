import {Card, Table} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import Column from "antd/es/table/Column";
import FlexCurrency from "../../../../utils/FlexCurrency";
import flexCurrency from "../../../../utils/FlexCurrency";

const BankaVadesizComponent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const getData = () => {
        const hesapKodlari = [
            "102.01.01.001",
            "102.01.01.002",
            "102.01.01.003",
            // "102.01.01.004",
            "102.01.01.005",
            // "102.01.01.006",
            "102.01.01.007",
            // "102.01.01.011",
            // "102.01.01.012",
            // "102.01.01.021",
            "102.01.01.022",
            "102.01.01.023",
            // "102.01.01.024",
            // "102.01.01.025",
            "102.01.01.027",
            // "102.01.01.028",
            // "102.01.01.029",
            // "102.01.01.030",
            "102.01.01.031",
            "102.01.01.041",
            "102.01.01.051",
            "102.01.01.052",
            // "102.01.01.061",
            "102.01.01.071",
            "102.01.01.072",
            "102.01.01.073",
            "102.01.01.074",
            "102.01.01.075",
            "102.01.01.076",
            "102.01.01.077"
        ];
        Api.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data.items.sort((a,b) => b.bakiye - a.bakiye))
            setLoading(false)
            // console.log(res.data.items)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <Card loading={loading} title={"VADESİZ MEVDUAT HESAPLARI"} size={"small"} >
            <Table bordered={true}
                   showHeader={false}
                   rowKey={"hesapKodu"}
                   dataSource={data} size={"small"}
                   style={{overflow:"auto", maxHeight:250}}
                   pagination={false}>
                <Column title={"Hesap Adı"}  dataIndex={"hesapAdi"}></Column>
                <Column className={"font-color-green"} title={"Bakiye"} align={"right"} dataIndex={"tarifeTutar"}
                        render={(_,record) => {
                            return  flexCurrency(record.bakiye)
                        }}>
                </Column>
            </Table>
        </Card>
    )
}

export default BankaVadesizComponent