import {useEffect, useState} from "react";
import dayjs from "dayjs";
import Api from "../../../../services/Api";
import {message, Table} from "antd";
import Column from "antd/es/table/Column";
import FlexCurrency from "../../../../utils/FlexCurrency";

const HareketDetailComponent = ({hesapKodu}) => {
    const [data, setData] = useState( []);
    const [loading, setLoading] = useState(true);

    const getData = () => {
        const requestFilterData = {
            "sort": [
                {
                    "field": "_Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "_Date.Date",
                "operator": "eq",
                "value": `${dayjs(new Date).format("YYYY-MM-DD")}`,
                "logic": "and",
                "filters":[
                    {
                        "field":"HesapKodu",
                        "operator":"startswith",
                        "value":`${hesapKodu}`,
                        "logic":"and"
                    }
                ]
            }
        }

        Api.post("api/MuhasebeFisis/GetListDynamic?PageIndex=0&PageSize=10000&BypassCache=true", requestFilterData).then(res=> {
            setData(res.data.items)
            setLoading(false)
        }).catch(err =>{
            message.error("Hata oluştu")
        })
    }

    useEffect(() => {
        getData()
    }, []);


    return (
        <Table
            loading={loading}
            bordered={true}
            showHeader={true}
            rowKey={"id"}
            className={"row-padding"}
            dataSource={data}
            size={"small"}
            pagination={true}>
            <Column title={"Tarih"} dataIndex={"_Date"} render={(_,record) => {
                return dayjs(record._Date).format("DD.MM.YYYY")
            }}></Column>
            <Column title={"Hesap Adı"}   dataIndex={"hesapAciklama"}></Column>
            <Column title={"Açıklama"} dataIndex={"kalemAciklamasi"}></Column>
            <Column title={"Ekleyen"} align={"center"}  width={20} dataIndex={"ekleyenKullanici"}></Column>
            <Column title={"Alacak"} className={"font-color-red small-font"} align={"right"} dataIndex={"alacak"}
                    render={(_,record) => {
                        return  FlexCurrency(record.alacak) === "0,00" ? "" : "-"+FlexCurrency(record.alacak)
                    }}>
            </Column>

            <Column title={"Borç"} align={"right"} className={"font-color-green"} dataIndex={"borc"}
                    render={(_,record) => {
                        return FlexCurrency(record.borc) === "0,00" ? "" : "+"+FlexCurrency(record.borc)
                    }}>
            </Column>
        </Table>
    );
}

export default HareketDetailComponent;