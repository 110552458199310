import {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Row, Select, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faArrowDown, faArrowUp, faDatabase, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Font} from "@react-email/components";

const IslemetGelirGiderAltGrupParametreComponent = ({record, setGridData, gridData}) => {
    const [altGrupData, setAltGrupData] = useState([])
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        const newRow = {
            rowNumber: altGrupData.length + 1,
            masrafMerkeziHesapKoduFormul: values.masrafMerkeziHesapKoduFormul,
            masrafMerkeziKoduFormul: values.masrafMerkeziKoduFormul,
            hesaplamaTipi: values.hesaplamaTipi,
            propName: values.propName,
            propType: values.propType,
            propDesc: values.propDesc
        }

        setAltGrupData([...altGrupData, newRow])
    }

    const onInputChange = (e, record, index, dataType) => {
        const newData = altGrupData.map((item, i) => {
            if(i === index){
                return {
                    ...item,
                    [dataType]: e.target.value
                }
            }
            return item
        })
        setAltGrupData(newData)
    }

    const getAltGrupData = () => {
        setLoading(true)
        const data = gridData.filter((item) => item.aciklama === record.aciklama)
        if(data.length > 0){
            setAltGrupData(data[0].props)
        }
        setLoading(false)
    }

    useEffect(() => {
        getAltGrupData()
    }, []);

    const rowDelete = (record) => {
        const newData = altGrupData.filter((item) => item.rowNumber !== record.rowNumber)
        setAltGrupData(newData)
        setGridData(gridData.map((item) => {
            if (item.aciklama === record.aciklama) {
                return {
                    ...item,
                    props: newData
                }
            }
            return item
        }))
    }

    const save = () => {
        setLoading(true)
        const newData = gridData.map((item) => {
            if(item.aciklama === record.aciklama){
                return {
                    ...item,
                    props: altGrupData
                }
            }
            return item
        })

        setGridData(newData)
        setLoading(false)
        message.success("Değişiklikler kaydedildi")
    }

    const upAltGrupData = (record, index) => {
        if(index === 0){
            return
        }
        const data = [...altGrupData]
        const temp = data[index]
        data[index] = data[index-1]
        data[index-1] = temp
        setAltGrupData(data)
    }

    const downAltGrupData = (record, index) => {
        if(index === altGrupData.length - 1){
            return
        }
        const data = [...altGrupData]
        const temp = data[index]
        data[index] = data[index+1]
        data[index+1] = temp
        setAltGrupData(data)
    }

    return (
        <>
            <Row gutter={[10,0]}>
                <Col span={24}>
                    <Form
                        labelCol={{span:50}}
                        layout={"vertical"}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Row gutter={[10,10]}>
                            <Col span={6}>
                                <Form.Item  label={"Tipi"} name={"propName"} rules={[{ required: true },]}>
                                    <Select>
                                        <Select.Option value="toplamGelir">Toplam Gelir</Select.Option>
                                        <Select.Option value="digerGiderler">Diğer Giderler</Select.Option>
                                        <Select.Option value="personelGiderleri">Personel Giderleri</Select.Option>
                                        <Select.Option value="isYeriKiraGiderleri">İş Yeri Kira Giderleri</Select.Option>
                                        <Select.Option value="sabitKiymetAlimlari">Sabit Kıymet Alımları</Select.Option>
                                        <Select.Option value="stokAlimlari">Stok Alımları</Select.Option>
                                        <Select.Option value="uretimdeKullanilanMalzemeGiderleri">Üretimde Kullanılan Malzeme Giderleri</Select.Option>
                                        <Select.Option value="amortismanGiderleri">Amortisman Giderleri</Select.Option>
                                        <Select.Option value="satisIadeleri">Satış İadeleri</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>



                            <Col span={6}>
                                <Form.Item  label={"Bakiye Tipi"} name={"propType"} rules={[{ required: true },]}>
                                    <Select>
                                        <Select.Option value="borc">Borç</Select.Option>
                                        <Select.Option value="alacak">Alacak</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item  label={"Hesaplama Tipi"} name={"hesaplamaTipi"} rules={[{ required: true },]}>
                                    <Select>
                                        <Select.Option value="MMK">Muhasebe Masraf Merkezi Kodu(MMK)</Select.Option>
                                        <Select.Option value="HK">Hesap Kodu(HK)</Select.Option>
                                        <Select.Option value="MUHHK">Muhasebe Hesap Kodu(MUHHK)</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item  label={"Paremetre Açıklama"} name={"propDesc"} rules={[{ required: true },]}>
                                    <Select>
                                        <Select.Option value="string">string</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={9}>
                                <Form.Item  label={"Masraf Merkezi Hesap Kodu"} name={"masrafMerkeziHesapKoduFormul"} rules={[{ required: true },]}>
                                    <Input.TextArea   />
                                </Form.Item>
                            </Col>

                            <Col span={9}>
                                <Form.Item  label={"Masraf Merkezi Kodu Formül"} name={"masrafMerkeziKoduFormul"} rules={[{ required: true },]}>
                                    <Input.TextArea   />
                                </Form.Item>
                            </Col>



                            <Col span={2} className={"text-align-right mt-2"}>
                                <Button icon={<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>} type={"dashed"} htmlType={"submit"}>Formülü Ekle</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col span={24} className={"mt-1"}>
                    <Table
                        loading={loading}
                        showHeader={true}
                        bordered={true}
                        rowKey={"rowNumber"}
                        size={"small"}
                        dataSource={altGrupData}
                        columns={[
                            {
                                title: '',
                                dataIndex: 'rowNumber',
                                width: 140,
                                key: 'rowNumber',
                                render: (text, record, index) => {
                                    return (
                                        <Space>
                                            <Button icon={<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>} onClick={() => rowDelete(record)} ></Button>
                                            <Button icon={<FontAwesomeIcon icon={faArrowUp} onClick={() => upAltGrupData(record, index)}></FontAwesomeIcon>}></Button>
                                            <Button icon={<FontAwesomeIcon icon={faArrowDown} onClick={() => downAltGrupData(record,index)}></FontAwesomeIcon>}></Button>
                                        </Space>
                                    )
                                }
                            },
                            {
                                title: 'Masraf Merkezi Hesap Kodu',
                                dataIndex: 'masrafMerkeziHesapKoduFormul',
                                key: 'masrafMerkeziHesapKoduFormul',
                                render: (text, _record, index) => {
                                    return (
                                        <Input.TextArea onChange={(e) => onInputChange(e, record, index, "masrafMerkeziHesapKoduFormul")} defaultValue={text}></Input.TextArea>
                                    )
                                }
                            },
                            {
                                title: 'Masraf Merkezi Kodu',
                                dataIndex: 'masrafMerkeziKoduFormul',
                                key: 'masrafMerkeziKoduFormul',
                                render: (text, _record, index) => {
                                    return (
                                        <Input.TextArea onChange={(e) => onInputChange(e, record, index, "masrafMerkeziKoduFormul")} defaultValue={text}></Input.TextArea>
                                    )
                                }
                            },
                            {
                                title: 'Hesaplama Tipi',
                                dataIndex: 'hesaplamaTipi',
                                key: 'hesaplamaTipi',
                                render: (text, _record, index) => {
                                    return (
                                        <Input onChange={(e) => onInputChange(e, record, index, "hesaplamaTipi")} defaultValue={text}></Input>
                                    )
                                }
                            },
                            {
                                title: 'Parametre Adı',
                                dataIndex: 'propName',
                                key: 'propName',
                                render: (text, _record, index) => {
                                    return (
                                        <Input onChange={(e) => onInputChange(e, record, index, "propName")} defaultValue={text}></Input>
                                    )
                                }
                            },
                            {
                                title: 'Parametre Tipi',
                                dataIndex: 'propType',
                                key: 'propType',
                                render: (text, _record, index) => {
                                    return (
                                        <Input onChange={(e) => onInputChange(e, record, index, "propType")} defaultValue={text}></Input>
                                    )
                                }
                            },
                            {
                                title: 'Parametre Açıklama',
                                dataIndex: 'propDesc',
                                key: 'propDesc',
                                render: (text, _record, index) => {
                                    return (
                                        <Input onChange={(e) => onInputChange(e, record, index, "propDesc")} defaultValue={text}></Input>
                                    )
                                }
                            },


                        ]}
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                </Col>

                <Col span={24} className={"text-align-right mt-1"}>
                    <Button icon={<FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>} type={"dashed"} onClick={() => save()}>Değişiklikleri Aktar</Button>
                </Col>
            </Row>
        </>
    )
}

export default IslemetGelirGiderAltGrupParametreComponent