import {Card, Table} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import Column from "antd/es/table/Column";
import flexCurrency from "../../../../utils/FlexCurrency";

const BankaKrediKartiTutarlariPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const getData = () => {
        const hesapKodlari = [
            "108.01.01",
            "108.01.02",
            "108.01.03",
        ]

        Api.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data.items.sort((a,b) => b.bakiye - a.bakiye))
            setLoading(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, []);

    const extraTotal = () => {
        let total = 0;
        data.map((item) => {
            total += item.bakiye
        })
        return flexCurrency(total)
    }

    return(
        <Card title={"KREDİ KARTI BAKİYE TUTARLARI"} size={"small"} className={"h-100"} extra={<div className={"font-weight-bold font-color-green"}>{extraTotal()}</div> } >
            <Table bordered={true}
                   showHeader={false}
                   rowKey={"hesapKodu"}
                   style={{overflow:"auto", maxHeight:250}}
                   dataSource={data}
                   size={"small"} pagination={false}>
                <Column title={"Hesap Adı"}  dataIndex={"hesapAdi"}></Column>
                <Column title={"Bakiye"} className={"font-color-green"} align={"right"} dataIndex={"tarifeTutar"}
                        render={(_,record) => {
                            return  flexCurrency(record.bakiye)
                        }}>
                </Column>
            </Table>
        </Card>
    )
}
export default BankaKrediKartiTutarlariPage