import {createBrowserRouter, createRoutesFromElements, defer, Route} from "react-router-dom";
import AuthLayout from "./layout/AuthLayout";
import ProtectedLayout from "./layout/ProtectedLayout";
import LoginPage from "./pages/auth/LoginPage";
import KasaPage from "./pages/kasa/KasaPage";
import UsersListPage from "./pages/users/UsersListPage";
import UserDetailPage from "./pages/users/UserDetailPage";
import UserAddPage from "./pages/users/UserAddPage";
import "../src/styles/Style.sass"
import DashboardPage from "./pages/home/DashboardPage";
import ParametreListPage from "./pages/parametre/ParametreListPage";
import OdemePlaniPage from "./pages/odemeplani/OdemePlaniPage";
import GeOdemeTahsilatPage from "./pages/geodemetahsilat/GeOdemeTahsilatPage";
import IsletmeGelirGiderPage from "./pages/isletmegg/IsletmeGelirGiderPage";
import GeOdemeTahsilatYillikPage from "./pages/geodemetahsilatyillik/GeOdemeTahsilatYillikPage";
import SyncListPage from "./pages/sync/SyncListPage";
import TahminiGelirGiderDurumu from "./pages/tahminigelirgider/TahminiGelirGiderDurumu";
import IsletGelirGiderParametreComponent from "./pages/isletmegg/partials/IsletGelirGiderParametreComponent";
import CekSenetPageList from "./pages/ceksenet/CekSenetPageList";
import ObbCariHesapDetayPage from "./pages/obbcarihesap/ObbCariHesapDetayPage";
import AlacakListPage from "./pages/alacak/AlacakListPage";


const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem("user");
            resolve(user);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >

            <Route>
                <Route path={"/"} element={<LoginPage></LoginPage>}></Route>
                <Route path={"/login"} element={<LoginPage></LoginPage>}></Route>
            </Route>

            <Route path="/dashboard" element={<ProtectedLayout />}>
                <Route path="/dashboard" element={<DashboardPage />} />
            </Route>

            <Route path="/kasa" element={<ProtectedLayout />}>
                <Route path="/kasa" element={<KasaPage />} />
            </Route>

            <Route path="/parametre" element={<ProtectedLayout />}>
                <Route path="/parametre" element={<ParametreListPage />} />
            </Route>

            <Route path="/sync" element={<ProtectedLayout />}>
                <Route path="/sync" element={<SyncListPage />} />
            </Route>

            <Route path={"/odemeplani"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/odemeplani" element={<OdemePlaniPage></OdemePlaniPage>}></Route>
            </Route>


            <Route path={"/gerceklesen"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/gerceklesen" element={<GeOdemeTahsilatPage></GeOdemeTahsilatPage>}></Route>
            </Route>

            <Route path={"/kasa"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/kasa" element={<KasaPage></KasaPage>}></Route>
            </Route>

            <Route path={"/igg"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/igg" element={<IsletmeGelirGiderPage></IsletmeGelirGiderPage>}></Route>
            </Route>

            <Route path={"/yillik"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/yillik" element={<GeOdemeTahsilatYillikPage></GeOdemeTahsilatYillikPage>}></Route>
            </Route>

            <Route path={"/tahmini"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/tahmini" element={<TahminiGelirGiderDurumu></TahminiGelirGiderDurumu>}></Route>
            </Route>

            <Route path={"/iiparametre"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/iiparametre" element={<IsletGelirGiderParametreComponent></IsletGelirGiderParametreComponent>}></Route>
            </Route>

            <Route path="/users" element={<ProtectedLayout />}>
                <Route path="/users" element={<UsersListPage />} />
                <Route path={"/users/add"} element={<UserAddPage />} />
                <Route path={"/users/detail"} element={<UserDetailPage />} />
            </Route>

            <Route path={"/ceksenet"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/ceksenet" element={<CekSenetPageList></CekSenetPageList>}></Route>
            </Route>

            <Route path={"/obbcari"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/obbcari" element={<ObbCariHesapDetayPage></ObbCariHesapDetayPage>}></Route>
            </Route>

            <Route path={"/alacak"} element={<ProtectedLayout></ProtectedLayout>}>
                <Route path="/alacak" element={<AlacakListPage></AlacakListPage>}></Route>
            </Route>

        </Route>
    )
)


