import {Card, Col, Row} from "antd";
import Api from "../../../../services/Api";
import {useEffect, useState} from "react";
import flexCurrency from "../../../../utils/FlexCurrency";

const BankaDurumComponent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const getData = () => {
        const hesapKodlari = ["102.01.01", "102.01.02", "102.02", "102.02.01", "102.02.02"];
        Api.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, []);


    return(
        <Row gutter={[10,10]}>
            <Col span={8}>
                <Card loading={loading} size={"small"} title={"VADESİZ MEVDUAT HESAPLARI"} extra={"102.01.01"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[0]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Alacak</div>
                        <div className={""}>{flexCurrency(data[0]?.alacak)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Borç</div>
                        <div className={""}>{flexCurrency(data[0]?.borc)}</div>
                    </div>
                </Card>
            </Col>

            <Col span={8}>
                <Card loading={loading} size={"small"} title={"VADELİ MEVDUAT HESAPLAR"} extra={"102.01.02"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[1]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Alacak</div>
                        <div className={""}>{flexCurrency(data[1]?.alacak)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Borç</div>
                        <div className={""}>{flexCurrency(data[2]?.borc)}</div>
                    </div>
                </Card>
            </Col>

            <Col span={8}>
                <Card loading={loading} size={"small"} title={"EÜTS HESAPLAR"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[2]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Eüts Vadesiz M. Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[3]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Eüts Vadeli M. Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[4]?.bakiye)}</div>
                    </div>
                </Card>
            </Col>
        </Row>

    )
}

export default BankaDurumComponent