import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import dayjs from "dayjs";
import flexCurrency from "../../../utils/FlexCurrency";
import {message, Modal, Table} from "antd";
import GeotFisGunDetayKodComponent from "./GeotFisGunDetayKodComponent";

const GeOdemeComponent = ({filterMonthDate, setGeDipOdeme}) => {
    const [geOdeme, setGeOdeme] = useState([]);
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(true)
    const [geotFisGunDetayKodModal, setGeotFisGunDetayKodModal] = useState({isOpen:false, selectedFisGunDetayKod:{formul:"", aciklama:"", tarih:""}})

    useEffect(() => {
        getParametre()
    }, [filterMonthDate]);


    useEffect(() => {
        getParametre()
    }, []);

    const getGeOdeme = (parametreDegeri) => {
        setLoading(true)
        let queryParams = JSON.parse(parametreDegeri)

        const requestDto = {
            "referansTarih": `${filterMonthDate}`,
            "formulleHesaplaRequestDto": queryParams,
        }

        Api.post("api/MuhasebeFisis/GetListFormulleHesaplaWidthAylik", requestDto).then(res => {
            const columns = []
            columns.push(
                {title: "", dataIndex: "aciklama",
                    key: "aciklama",
                    fixed: "left",
                    className:"font-weight-bold w-100",
                    render: (_,record) => {
                        return <div style={{minWidth:200}}>{record.aciklama}</div>
                    }
                }
            )

            res.data[0].gunlukBakiyes.forEach(item => {
                columns.push(
                    {title: dayjs(item.tarih).format("DD.MM.YYYY"),
                        align:"right",
                        dataIndex: item.tarih,
                        key: item.tarih,
                        render: (_,record) => {
                            return <div style={{cursor:"pointer"}} onDoubleClick={() =>  geotFisGunDetayKodModalOpen(record.formul, record.aciklama, item.tarih)}>{record[item.tarih]}</div>
                        }})
            })

            columns.push(
                {
                    title: "AYLIK TOPLAM",
                    dataIndex: "rowToplam", key: "rowToplam",
                    fixed: "right",
                    render: (_, record, index) => {
                        // Eğer son satırdaysa, özelleştirilmiş değeri döndür
                        if (index === res.data.length) {
                            //burada toplamAlacakların Heğsini Topla hesaplanacak
                            let toplam = 0
                            res.data.forEach(item => {
                                toplam += item.toplamBorc
                            })
                            return <div style={{minWidth:100, fontWeight:"bold", backgroundColor:'red', color:"white", padding:"5px", textAlign:"right"}}>{flexCurrency(toplam)}</div>
                        }
                        // Diğer durumlarda, orijinal değeri döndür
                        return <div style={{minWidth:100, fontWeight:"bold", color:"red", textAlign:"right"}}>{flexCurrency(record.toplamBorc)}</div>
                    }
                }
            )

            setColumns(columns)

            const data = []
            res.data.forEach(item => {
                const dataItem = {formul:item.formul, aciklama: item.aciklama, toplamBakiye: item.toplamBakiye, toplamAlacak: item.toplamAlacak, toplamBorc: item.toplamBorc}
                item.gunlukBakiyes.forEach(gunlukBakiye => {
                    dataItem[gunlukBakiye.tarih] = flexCurrency(gunlukBakiye.borc)
                })
                data.push(dataItem)
            })

            const dataItem = {aciklama: ""}
            res.data[0].gunlukBakiyes.forEach(gunlukBakiye => {
                let toplam = 0
                res.data.forEach(item => {
                    toplam += item.gunlukBakiyes.find(x => x.tarih === gunlukBakiye.tarih).borc
                })
                dataItem[gunlukBakiye.tarih] = flexCurrency(toplam)
            })

            data.push(dataItem)
            setGeOdeme(data)
            setGeDipOdeme(dataItem)
            setLoading(false)

        }).catch(err => {
            message.error("GeTahsilat getirilirken hata oluştu")
        })
    }

    const geotFisGunDetayKodModalOpen = (formul, aciklama, tarih, ) => {
        setGeotFisGunDetayKodModal({isOpen:true, selectedFisGunDetayKod:{formul, aciklama, tarih}})
    }

    const getParametre = () => {
        Api.get("/api/Parametres/GetByCode/MUH_RPR_GERCEKLESEN_ODEMELER_AYLIK").then(res => {
            getGeOdeme(res.data.parametreDegeri)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
            console.log(err)
        })
    }

    return(
        <>
            <Modal
                open={geotFisGunDetayKodModal.isOpen}
                title={geotFisGunDetayKodModal.selectedFisGunDetayKod.aciklama+" - "+ dayjs(geotFisGunDetayKodModal.selectedFisGunDetayKod.tarih).format("DD.MM.YYYY")+" Tarihli Detay"}
                destroyOnClose={true}
                width={1800}
                onCancel={() => setGeotFisGunDetayKodModal({isOpen:false, selectedFisGunDetayKod: {formul:"", aciklama:"", tarih:""}})}
                footer={[]}

            >
                <GeotFisGunDetayKodComponent selected={geotFisGunDetayKodModal}></GeotFisGunDetayKodComponent>
            </Modal>

            <Table
                loading={loading}
                bordered={true}
                showHeader={true}
                rowKey={"index"}
                style={{overflow:"auto"}}
                rootClassName={"custom-small-row"}
                dataSource={geOdeme}
                size={"small"}
                columns={columns}
                rowClassName={(record, index) => {
                    if(index === geOdeme.length - 1){
                        return "font-weight-bold font-color-red"
                    }
                    return index % 2 === 0 ? "alternate-row" : "";
                }}
                pagination={false}>

            </Table>
        </>

    )
}

export default GeOdemeComponent