import {useState} from "react";
import dayjs from "dayjs";
import {Button, Card, Col, DatePicker, Form, Modal, Row} from "antd";
import ParametreComponent from "../../../../geodemetahsilat/partials/ParametreComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import MadenGeTahsilatComponent from "./partials/MadenGeTahsilatComponent";
import MadenGeOdemeComponent from "./partials/MadenGeOdemeComponent";
import MadenGeDurumComponent from "./partials/MadenGeDurumComponent";


const MadenGeOdemeTahsilatPage = () => {
    const [currentMonth, setCurrentMonth] = useState(dayjs(new Date).format("YYYY-MM-01"))
    const [form] = Form.useForm();
    const [geDipTahsilat, setGeDipTahsilat] = useState(null)
    const [geDipOdeme, setGeDipOdeme] = useState(null)
    const [parametreModal, setParametreModal] = useState({isOpen:false, selectedParametreKodu:null})


    const parametreModalOpen = (parametreKodu) => {
        setParametreModal({isOpen:true, selectedParametreKodu:parametreKodu})
    }

    return(
        <div className={"p-1"} style={{overflow:"auto"}}>
            <Modal
                open={parametreModal.isOpen}
                title={"Parametre"}
                destroyOnClose={true}
                width={1800}
                onCancel={() => setParametreModal({isOpen:false, selectedParametreKodu: null})}
                footer={null}

            >
                <ParametreComponent selectedParametreKodu={parametreModal.selectedParametreKodu}></ParametreComponent>
            </Modal>

            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card size={"small"} title={"FİLTRE VE PARAMETRELER"}>
                        <Form
                            layout={"horizontal"}
                            form={form}
                            colon={false}
                            labelCol={{
                                span: 15,
                            }}
                            labelAlign={"left"}>
                            <Row gutter={[10,10]}>
                                <Col >
                                    <Form.Item label={"Raporlama İstenilen Yılın Ayı"} style={{fontWeight:"bold", minWidth:400}} rules={[{ required: true },]}>
                                        <DatePicker  rootClassName={"w-100"} defaultValue={dayjs(new Date)} onChange={(e) => setCurrentMonth(dayjs(e).format("YYYY-MM-01"))} picker="month" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"}
                          title={"GERÇEKLEŞEN TAHSİLATLAR"}
                          extra={<Button onClick={() => parametreModalOpen("MADEN_MUH_RPR_GERCEKLESEN_TAHSILAT_AYLIK")}><FontAwesomeIcon icon={faDatabase} /></Button>}>
                        <MadenGeTahsilatComponent setGeDipTahsilat={setGeDipTahsilat} filterMonthDate={currentMonth}></MadenGeTahsilatComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"}
                          title={"GERÇEKLEŞEN ÖDEMELER"}
                          extra={<Button onClick={() => parametreModalOpen("MADEN_MUH_RPR_GERCEKLESEN_ODEMELER_AYLIK")}><FontAwesomeIcon icon={faDatabase} /></Button>}>
                        <MadenGeOdemeComponent setGeDipOdeme={setGeDipOdeme} filterMonthDate={currentMonth}></MadenGeOdemeComponent>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card size={"small"} title={"MEVCUT DURUM"}>
                        <MadenGeDurumComponent filterMonthDate={currentMonth} geDipOdeme={geDipOdeme} geDipTahsilat={geDipTahsilat}></MadenGeDurumComponent>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default MadenGeOdemeTahsilatPage