import {Alert, Button, Card, Col, DatePicker, Form, message, Modal, Row} from "antd";
import NakitGirisDurumuComponent from "./partials/NakitGirisDurumuComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import TahminiTahsilatPageComponent from "./partials/parametre/NakitGiris/TahminiTahsilatPageComponent";
import dayjs from "dayjs";
import NakitCikisDurumuComponent from "./partials/NakitCikisDurumuComponent";
import NakitCikisParametreComponent from "./partials/parametre/NakitCikis/NakitCikisParametreComponent";
import {CloseSquareFilled} from "@ant-design/icons";
import BankaDurumuComponent from "./partials/BankaDurumuComponent";

const TahminiGelirGiderDurumu = () => {
    const [nakitGirisTahminiTahsilatModal,setNakitGirisTahminiTahsilatModal] = useState(false)
    const [nakitCikisParametreModal, setNakitCikisParametreModal] = useState(false)
    const {RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const [startFilterDate, setStartFilterDate] = useState();
    const [endFilterDate, setEndFilterDate] = useState();
    const [cacheStartFilterDate, setCacheStartFilterDate] = useState();
    const [cacheEndFilterDate, setCacheEndFilterDate] = useState();

    const onFinish = (values) => {

        if(cacheStartFilterDate === undefined || cacheEndFilterDate === undefined){
            message.error("Lütfen tarih aralığı seçiniz, bu tarih aralığının çok fazla büyük olmamasına dikkat ediniz. Aksi takdirde bu işlem uzun sürebilir.")
            return
        }

        setStartFilterDate(cacheStartFilterDate)
        setEndFilterDate(cacheEndFilterDate)
    }

    return(
        <>
            <Modal
                title={"Nakit Giriş Tahmini Tahsilat"}
                open={nakitGirisTahminiTahsilatModal}
                width={2500}
                onCancel={() => setNakitGirisTahminiTahsilatModal(false)}
                destroyOnClose={true}
                footer={[]}>
                <TahminiTahsilatPageComponent></TahminiTahsilatPageComponent>
            </Modal>

            <Modal
                title={"Nakit Çıkış Parametre"}
                open={nakitCikisParametreModal}
                width={2500}
                onCancel={() => setNakitCikisParametreModal(false)}
                destroyOnClose={true}
                footer={[]}>
                <NakitCikisParametreComponent selectedParametreKodu={"MUH_TAHMINI_NAKIT_CIKIS"}></NakitCikisParametreComponent>
            </Modal>

            <div className={"p-1"}>

                <Alert
                    className={"mb-1"}
                    message="Uyarı!"
                    description="Veriler test verisidir. Gerçeği yansıtmıyor olabilir.."
                    type="warning"
                />

                <Card title={"PARAMETRE VE FİLTRELEME"} size={"small"}>
                    <Form
                        layout={"horizontal"}
                        form={form}
                        onFinish={onFinish}
                        initialValues={{start:false}}
                        colon={false}
                        labelCol={{
                            span: 10,
                        }}
                        labelAlign={"left"}>
                        <Row gutter={[10,10]}>
                            <Col >
                                <Form.Item label={"Raporlama Tarihi Aralığı"}  style={{fontWeight:"bold"}} rules={[{ required: true },]}>
                                    <RangePicker
                                        name={"daterange"}
                                        format={"DD.MM.YYYY"}
                                        defaultValue={[startFilterDate, endFilterDate]}
                                        onChange={(dates) => {
                                            if (dates) {
                                                const startDate = dayjs(dates[0]).format("YYYY-MM-DD");
                                                setCacheStartFilterDate(startDate);

                                                const endDate = dayjs(dates[1]).format("YYYY-MM-DD");
                                                setCacheEndFilterDate(endDate);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col >
                                <Form.Item>
                                    <Button htmlType={"submit"}>Raporu Hazırla</Button>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Card>


                <Card size={"small"} className={"mt-1"} title={"BANKA DURUMU"}>
                     <BankaDurumuComponent startFilterDate={startFilterDate} endFilterDate={endFilterDate}></BankaDurumuComponent>
                </Card>


                <Card title={"NAKİT GİRİŞLER"} className={"mt-1"} size={"small"}
                      extra={<Button onClick={() => setNakitGirisTahminiTahsilatModal(true)} icon={<FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>}></Button>}>
                    <NakitGirisDurumuComponent startFilterDate={startFilterDate} endFilterDate={endFilterDate}></NakitGirisDurumuComponent>
                </Card>


                <Card title={"NAKİT ÇIKIŞLAR"} className={"mt-1"} size={"small"}
                    extra={<Button onClick={() => setNakitCikisParametreModal(true)} icon={<FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>}></Button>}
                >
                    <NakitCikisDurumuComponent startFilterDate={startFilterDate} endFilterDate={endFilterDate}></NakitCikisDurumuComponent>
                </Card>
            </div>
        </>

    )
}

export default TahminiGelirGiderDurumu