import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import {message, Table} from "antd";
import dayjs from "dayjs";
import flexCurrency from "../../../utils/FlexCurrency";

const NakitCikisDurumuComponent = ({startFilterDate, endFilterDate }) => {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false)


    const getData = (parametreDegeri) => {
        if(startFilterDate === undefined || endFilterDate === undefined){
            setLoading(false)
            return
        }


        setLoading(true)
        let queryParams = JSON.parse(parametreDegeri)

        const requestDto = {
            "startDate":  dayjs(startFilterDate).format("YYYY-MM-DD"),
            "endDate": dayjs(endFilterDate).format("YYYY-MM-DD"),
            "formulStrList": queryParams
        }

        Api.post("/api/MuhasebeTahminiGelirGiders/GetTahminiGelirGiderNakitCikisQuery", requestDto).then(res => {
            const newData = [];
            res.data.forEach(item => {
                const ustGrup = { aciklama: item.aciklama,  };

                item.bakiyes.forEach(item => {
                    ustGrup[item.tarih] = flexCurrency(item.tutar); // bakiye.tutar değerini ekliyoruz
                });
                newData.push(ustGrup);
            });

            setData(newData)

            const columns = []
            columns.push({
                title: "",
                dataIndex: "aciklama",
                className:"font-weight-bold",
            })
            res.data[0].bakiyes.forEach(item => {
                columns.push({
                    title: dayjs(item.tarih).format("DD.MM.YYYY"),
                    dataIndex: item.tarih,
                    align:"right",
                    width:150
                })
            })
            setColumns(columns)
            setLoading(false)

        }).catch(err => {
            message.error("Bir hata oluştu");
        })
    }

    const getParametre = () => {
        setLoading(true)
        Api.get("/api/Parametres/GetByCode/MUH_TAHMINI_NAKIT_CIKIS").then(res => {
            getData(res.data.parametreDegeri)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
            setLoading(false)
        })
    }

    useEffect(() => {
        getParametre()
    }, []);

    useEffect(() => {
        getParametre()
    }, [startFilterDate, endFilterDate]);



    return(
        <div>
            <Table
                dataSource={data}
                loading={loading}
                rowKey={"aciklama"}
                size={"small"}
                style={{overflow: "auto"}}
                pagination={false}
                bordered={true}
                summary={() => (
                    <Table.Summary.Row>

                        <Table.Summary.Cell index={0}>
                            <div className={"font-weight-bold text-align-right"}>TOPLAM</div>
                        </Table.Summary.Cell>

                        {columns.slice(1).map((item, index) => {
                            return (
                                <Table.Summary.Cell index={index + 1}>
                                    <div className={"font-weight-bold text-align-right"}>
                                        {flexCurrency(data.reduce((acc, val) => acc + parseFloat(val[item.dataIndex].replaceAll(".","").replace(",",".")), 0))}
                                    </div>
                                </Table.Summary.Cell>
                            )
                        })}

                    </Table.Summary.Row>
                )}
                columns={columns}>

            </Table>
        </div>
    )
}

export default NakitCikisDurumuComponent