import {useEffect, useState} from "react";
import Api from "../../../../../../services/Api";
import {Card, Col, Row} from "antd";
import flexCurrency from "../../../../../../utils/FlexCurrency";
import Api_Maden from "../../../../../../services/Api_Maden";

const MadenBankaDurumComponent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const getData = () => {
        const hesapKodlari = ["102.01", "102.02", "102.04", "102.03"];
        Api_Maden.post("api/MuhasebeFisis/GetListMuhasebeFisWidthHesapKodu", {hesapKods:hesapKodlari}).then(res=> {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, []);


    return(
        <Row gutter={[10,10]}>
            <Col span={6}>
                <Card loading={loading} size={"small"} title={"VADESİZ MEVDUAT HESAPLARI"} extra={"102.01"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[0]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Alacak</div>
                        <div className={""}>{flexCurrency(data[0]?.alacak)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Borç</div>
                        <div className={""}>{flexCurrency(data[0]?.borc)}</div>
                    </div>
                </Card>
            </Col>

            <Col span={6}>
                <Card loading={loading} size={"small"} title={"VADESİZ MEVDUAT HESAPLARI (DÖVİZ)"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[4]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Eüts Vadesiz M. Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[4]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Eüts Vadeli M. Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[4]?.bakiye)}</div>
                    </div>
                </Card>
            </Col>

            <Col span={6}>
                <Card loading={loading} size={"small"} title={"VADELİ MEVDUAT HESAPLARI (TL)"} extra={"102.02"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[1]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Alacak</div>
                        <div className={""}>{flexCurrency(data[1]?.alacak)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Borç</div>
                        <div className={""}>{flexCurrency(data[2]?.borc)}</div>
                    </div>
                </Card>
            </Col>

            <Col span={6}>
                <Card loading={loading} size={"small"} title={"VADELİ MEVDUAT HESAPLARI (DÖVİZ)"}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Toplam Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[2]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Eüts Vadesiz M. Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[3]?.bakiye)}</div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>Eüts Vadeli M. Bakiye</div>
                        <div className={"font-weight-bold"}>{flexCurrency(data[4]?.bakiye)}</div>
                    </div>
                </Card>
            </Col>


        </Row>

    )
}

export default MadenBankaDurumComponent