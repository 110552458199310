import React from 'react';
import { InputNumber } from 'antd';
import CurrencyInput from 'react-currency-input-field';

const FormattedInputNumber = ({ value, onChange, align, ...restProps }) => {
    return (
        <>
            <InputNumber style={{ display: 'none' }} />
            <div className="ant-input-number ant-input-number-outlined w-100">
                <div className="ant-input-number-input-wrap">
                    <CurrencyInput
                        className="ant-input-number-input"
                        style={{textAlign: align || 'right'}}
                        decimalScale={2}
                        defaultValue={value}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => {
                            onChange && onChange(values.float);
                        }}
                        {...restProps}
                    />
                </div>
            </div>
        </>
    );
};

export default FormattedInputNumber;