import {Button, Card, Checkbox, Col, DatePicker, Form, message, Modal, Row, Table} from "antd";
import Api from "../../services/Api";
import {useEffect, useState} from "react";
import flexCurrency from "../../utils/FlexCurrency";
import dayjs from "dayjs";
import IsletGelirGiderParametreComponent from "./partials/IsletGelirGiderParametreComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import IsletmeGelirGiderModalComponent from "./partials/IsletmeGelirGiderModalComponent";

const IsletmeGelirGiderPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;
    const [startFilterDate, setStartFilterDate] = useState(dayjs().startOf('month'));
    const [endFilterDate, setEndFilterDate] = useState(dayjs().endOf('month'));
    const [isletmeGelirGiderParametreModal, setIsletmeGelirGiderParametreModal] = useState({isOpen:false});
    const [parametreDegeri, setParametreDegeri] = useState(null);
    const yedekRequestRowDto  = [
        {
            "aciklama": "ÜNYE LİMAN İŞLETMESİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "116",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "116",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "116",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "116",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.49+251.49+252.49+253.49+254.49+255.49+256.49+260.49+264.49+267.49",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005",
                    "masrafMerkeziKoduFormul": "116",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "610.01.01.002+610.02.01.002",
                    "masrafMerkeziKoduFormul": "116",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "BETON SANTRALİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "125",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "125",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "125",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "125",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.51+251.51+252.51+253.51+254.51+255.51+256.51+260.51+264.51+267.51",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005+730.01.11.005+770.01.11.005",
                    "masrafMerkeziKoduFormul": "125",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "OTOPARKLAR",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "030+031+042+032+043+033+044+034+049+036+051+038+053+039+054+055+056+057+063+086",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "030+031+042+032+043+033+044+034+049+036+051+038+053+039+054+055+056+057+063+086",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "030+031+042+032+043+033+044+034+049+036+051+038+053+039+054+055+056+057+063+086",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "030+031+042+032+043+033+044+034+049+036+051+038+053+039+054+055+056+057+063+086",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.38+251.38+252.38+253.38+254.38+255.38+256.38+260.38+264.38+267.38+250.39+251.39+252.39+253.39+254.39+255.39+256.39+260.39+264.39+267.39+250.40+251.40+252.40+253.40+254.40+255.40+256.40+260.40+264.40+267.40+250.41+251.41+252.41+253.41+254.41+255.41+256.41+260.41+264.41+267.41+250.42+251.42+252.42+253.42+254.42+255.42+256.42+260.42+264.42+267.42+250.43+251.43+252.43+253.43+254.43+255.43+256.43+260.43+264.43+267.43+250.44+251.44+252.44+253.44+254.44+255.44+256.44+260.44+264.44+267.44+250.45+251.45+252.45+253.45+254.45+255.45+256.45+260.45+264.45+267.45+250.46+251.46+252.46+253.46+254.46+255.46+256.46+260.46+264.46+267.46+250.47+251.47+252.47+253.47+254.47+255.47+256.47+260.47+264.47+267.47",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005+730.01.11.005+770.01.11.005",
                    "masrafMerkeziKoduFormul": "030+031+042+032+043+033+044+034+049+036+051+038+053+039+054+055+056+057+063+086",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "610.01.01.005+610.02.01.005+610.06.01.005+610.04.01.005",
                    "masrafMerkeziKoduFormul": "030+031+042+032+043+033+044+034+049+036+051+038+053+039+054+055+056+057+063+086",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "EÜTS",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "072",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "072",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "072",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "072",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.53+251.53+252.53+253.53+254.53+255.53+256.53+260.53+264.53+267.53",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "153.01.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "153.01.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005+730.01.11.005+770.01.11.005",
                    "masrafMerkeziKoduFormul": "072",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "HAFRİYATLAR",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "026+028+029",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "026+028+029",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "026+028+029",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "026+028+029",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.32+251.32+252.32+253.32+254.32+255.32+256.32+260.32+264.32+267.32+250.33+251.33+252.33+253.33+254.33+255.33+256.33+260.33+264.33+267.33+250.34+251.34+252.34+253.34+254.34+255.34+256.34+260.34+264.34+267.34",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005+730.01.11.005+770.01.11.005",
                    "masrafMerkeziKoduFormul": "026+028+029",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "610.06.01.001",
                    "masrafMerkeziKoduFormul": "026+028+029",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "FATSA LİMAN İŞLETMESİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "120",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11+740.06.01.008",
                    "masrafMerkeziKoduFormul": "120",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01",
                    "masrafMerkeziKoduFormul": "120",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "120",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.54+251.54+252.54+253.54+254.54+255.54+256.54+260.54+264.54+267.54",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005",
                    "masrafMerkeziKoduFormul": "120",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "610.01.01.002+610.02.01.002",
                    "masrafMerkeziKoduFormul": "120",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "TERS EV TESİSİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "077",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "077",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "077",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "077",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.48+251.48+252.48+253.48+254.48+255.48+256.48+260.48+264.48+267.48",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "740.01.11.005",
                    "masrafMerkeziKoduFormul": "077",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "ASFALT PLENTİ TESİSİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "101",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "101",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+730.06.01.008",
                    "masrafMerkeziKoduFormul": "101",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "101",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.35+251.35+252.35+253.35+254.35+255.35+256.35+260.35+264.35+267.35",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "151.01.01+150.01.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "151.01.01+150.01.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.11.005+740.01.11.005",
                    "masrafMerkeziKoduFormul": "101",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "TELEFERİK",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "073",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "073",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "073",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "073",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.31+251.31+252.31+253.31+254.31+255.31+256.31+260.31+264.31+267.31",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "157.01.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "157.01.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.11.005+740.01.11.005",
                    "masrafMerkeziKoduFormul": "073",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "610.01.01.006+610.06.01.006",
                    "masrafMerkeziKoduFormul": "073",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "BESİ ÇİFTLİĞİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "117",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "117",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+730.06.01.008",
                    "masrafMerkeziKoduFormul": "117",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "117",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.50+251.50+252.50+253.50+254.50+255.50+256.50+260.50+264.50+267.50",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "150.01.02+151.02.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "150.01.02+151.02.01",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.11.005+740.01.11.005",
                    "masrafMerkeziKoduFormul": "117",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "ÇAMBAŞI DOĞA TESİSİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "081+085+090+091+093+094+096+111+119",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "081+085+090+091+093+094+096+111+119",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "081+085+090+091+093+094+096+111+119",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "081+085+090+091+093+094+096+111+119",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.36+251.36+252.36+253.36+254.36+255.36+256.36+260.36+264.36+267.36+250.37+251.37+252.37+253.37+254.37+255.37+256.37+260.37+264.37+267.37",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "157.01.02+157.01.04",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "157.01.02+157.01.04",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.11.005+740.01.11.005",
                    "masrafMerkeziKoduFormul": "081+085+090+091+093+094+096+111+119",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "KİRALANAN İŞLETMELER",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "100+118+123+126+127",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "100+118+123+126+127",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+740.06.01.008",
                    "masrafMerkeziKoduFormul": "100+118+123+126+127",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "100+118+123+126+127",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.52+251.52+252.52+253.52+254.52+255.52+256.52+260.52+264.52+267.52",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.11.005+740.01.11.005",
                    "masrafMerkeziKoduFormul": "100+118+123+126+127",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "610.01.01.003+610.02.01.003+610.06.01.0030",
                    "masrafMerkeziKoduFormul": "100+118+123+126+127",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        },
        {
            "aciklama": "G. YÖN. + OBB PERSONELİ",
            "props": [
                {
                    "masrafMerkeziHesapKoduFormul": "",
                    "masrafMerkeziKoduFormul": "003+005+007+008+011+013+014+020+023+025+099",
                    "hesaplamaTipi": "MMK",
                    "propName": "toplamGelir",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "780+659+689+710+720+760+730.01.03+730.02.03+730.03.03+730.04.03+730.05.03+740.01.03+740.02.03+740.03.03+740.04.03+740.05.03+770.01.03+770.02.03+770.03.03+770.04.03+770.05.03+730.01.04+730.02.04+730.03.04+730.04.04+730.05.04+740.01.04+740.02.04+740.03.04+740.04.04+740.05.04+770.01.04+770.02.04+770.03.04+770.04.04+770.05.04+730.01.05+730.02.05+730.03.05+730.04.05+730.05.05+740.01.05+740.02.05+740.03.05+740.04.05+740.05.05+770.01.05+770.02.05+770.03.05+770.04.05+770.05.05+730.01.06+730.02.06+730.03.06+730.04.06+730.05.06+740.01.06+740.02.06+740.03.06+740.04.06+740.05.06+770.01.06+770.02.06+770.03.06+770.04.06+770.05.06+730.01.07+730.02.07+730.03.07+730.04.07+730.05.07+740.01.07+740.02.07+740.03.07+740.04.07+740.05.07+770.01.07+770.02.07+770.03.07+770.04.07+770.05.07+730.01.08+730.02.08+730.03.08+730.04.08+730.05.08+740.01.08+740.02.08+740.03.08+740.04.08+740.05.08+770.01.08+770.02.08+770.03.08+770.04.08+770.05.08+730.01.09+730.02.09+730.03.09+730.04.09+730.05.09+740.01.09+740.02.09+740.03.09+740.04.09+740.05.09+770.01.09+770.02.09+770.03.09+770.04.09+770.05.09+730.01.10+730.02.10+730.03.10+730.04.10+730.05.10+740.01.10+740.02.10+740.03.10+740.04.10+740.05.10+770.01.10+770.02.10+770.03.10+770.04.10+770.05.10+730.01.11+730.02.11+730.03.11+730.04.11+730.05.11+740.01.11+740.02.11+740.03.11+740.04.11+740.05.11+770.01.11+770.02.11+770.03.11+770.04.11+770.05.11+730.06.01+730.06.03+730.06.04+730.06.05+730.06.06+730.06.07+730.06.08+730.06.09+730.06.10+730.06.11+740.06.01+740.06.03+740.06.04+740.06.05+740.06.06+740.06.07+740.06.08+740.06.09+740.06.10+740.06.11+770.06.01+770.06.03+770.06.04+770.06.05+770.06.06+770.06.07+770.06.08+770.06.09+770.06.10+770.06.11",
                    "masrafMerkeziKoduFormul": "003+005+007+008+011+013+014+020+023+025+099",
                    "hesaplamaTipi": "HK",
                    "propName": "digerGiderler",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.01+730.02.01+730.03.01+730.04.01+730.05.01+740.01.01+740.02.01+740.03.01+740.04.01+740.05.01+770.01.01+770.02.01+770.03.01+770.04.01+770.05.01+770.06.01.008+740.06.01.008",
                    "masrafMerkeziKoduFormul": "003+005+007+008+011+013+014+020+023+025+099",
                    "hesaplamaTipi": "HK",
                    "propName": "personelGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.02+730.02.02+730.03.02+730.04.02+730.05.02+740.01.02+740.02.02+740.03.02+740.04.02+740.05.02+770.01.02+770.02.02+770.03.02+770.04.02+770.05.02+730.06.02+740.06.02+770.06.02",
                    "masrafMerkeziKoduFormul": "003+005+007+008+011+013+014+020+023+025+099",
                    "hesaplamaTipi": "HK",
                    "propName": "isYeriKiraGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "250.30+251.30+252.30+253.30+254.30+255.30+256.30+260.30+264.30+267.30",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "sabitKiymetAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "stokAlimlari",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "MUHHK",
                    "propName": "uretimdeKullanilanMalzemeGiderleri",
                    "propType": "alacak",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "730.01.11.005+740.01.11.005+770.01.11.005",
                    "masrafMerkeziKoduFormul": "003+005+007+008+011+013+014+020+023+025+099",
                    "hesaplamaTipi": "HK",
                    "propName": "amortismanGiderleri",
                    "propType": "borc",
                    "propDesc": "string"
                },
                {
                    "masrafMerkeziHesapKoduFormul": "0",
                    "masrafMerkeziKoduFormul": "",
                    "hesaplamaTipi": "HK",
                    "propName": "satisIadeleri",
                    "propType": "borc",
                    "propDesc": "string"
                }
            ]
        }
    ]
    const [detayModal, setDetayModal] = useState({isOpen: false, data: null})
    const getData = (requestStartFilter, requestEndFilter) => {
        setLoading(true)
        const requestDto = {
            "baslangicTarih": requestStartFilter.format("YYYY-MM-DD"),
            "bitisTarih": requestEndFilter.format("YYYY-MM-DD"),
            "rows": JSON.parse(parametreDegeri)
        }

        Api.post("/api/MuhasebeMasrafMerkeziHarekets/GetListMasrafMerkeziGelirGiderMaliyet", requestDto).then(res => {
            setData(res.data.rows)
            setLoading(false)
            console.log(res.data.rows)
        }).catch(err => {
            console.log(err);
        })
    }

    const onFinish = (values) => {
        getData(startFilterDate, endFilterDate);
    }

    const isletmeGelirGiderParametreModalOpen = () => {
        setIsletmeGelirGiderParametreModal({isOpen: true})
    }

    const getParametre = () => {
        Api.get("/api/Parametres/GetByCode/MUH_ISLETME_GELIR_GIDER_DURUMU").then(res => {
            setParametreDegeri(res.data.parametreDegeri)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
            console.log(err)
        })
    }

    const detayModalOpen = (data) => {
        setDetayModal({isOpen:true,data:data})
    }

    useEffect(() => {
        getParametre();
    }, []);

    return(<div className={"p-1"}>
        <Modal
            title={"Gelir ve Gider Parametreleri"}
            open={isletmeGelirGiderParametreModal.isOpen}
            destroyOnClose={true}
            maskClosable={false}
            centered={true}
            width={1800}
            onCancel={() => setIsletmeGelirGiderParametreModal({isOpen:false})}
            footer={null}

        >
            <IsletGelirGiderParametreComponent></IsletGelirGiderParametreComponent>
        </Modal>

        <Modal
            title={data?.aciklama}
            open={detayModal.isOpen}
            destroyOnClose={true}
            width={1800}
            onCancel={() => setDetayModal({isOpen:false, data:null})}
            footer={[]}
        >
            <IsletmeGelirGiderModalComponent data={detayModal.data}></IsletmeGelirGiderModalComponent>
        </Modal>

        <Row gutter={[10,10]}>

            <Col span={24}>
                <Card title={"FİLTRE VE PARAMETRELER"}  size={"small"} >
                    <Form
                        layout={"horizontal"}
                        form={form}
                        onFinish={onFinish}
                        colon={false}
                        labelCol={{
                            span: 10,
                        }}
                        labelAlign={"left"}>
                        <Row gutter={[10,10]}>
                            <Col >
                                <Form.Item label={"Raporlama Tarihi Aralığı"}  style={{fontWeight:"bold"}} rules={[{ required: true },]}>
                                    <RangePicker
                                        name={"daterange"}
                                        format={"MM.YYYY"}
                                        picker="month"
                                        defaultValue={[startFilterDate, endFilterDate]}
                                        onChange={(dates) => {
                                            if (dates) {
                                                const startDate = dayjs(dates[0]).startOf("month")
                                                setStartFilterDate(startDate);

                                                const endDate = dayjs(dates[1]).endOf("month")
                                                setEndFilterDate(endDate);


                                                // getData(startDate, endDate)
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col >
                                <Form.Item>
                                    <Button htmlType={"submit"}>Raporu Hazırla</Button>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Card>

            </Col>

            <Col span={24}>
                <Card title={"GELİR VE GİDER LİSTESİ"} size={"small"} extra={<Button onClick={() => isletmeGelirGiderParametreModalOpen()} icon={<FontAwesomeIcon icon={faDatabase}></FontAwesomeIcon>}></Button> }>
                    <Table
                        bordered={true}
                        style={{overflow:"auto"}}
                        size={"small"}
                        rowKey={"index"}
                        rootClassName={"custom-small-row"}
                        loading={loading}
                        dataSource={data}
                        summary={() => (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                    {(() => {
                                        return <div className={"font-weight-bold text-align-right"}>TOPLAM</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    {(() => {
                                        const toplamGelirler = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "toplamGelir")?.propValue || 0, 0);
                                        const satisIadeleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "satisIadeleri")?.propValue || 0, 0);
                                        const sonuc = (toplamGelirler - satisIadeleri);
                                        // return flexCurrency(sonuc);
                                        return <div className={"font-weight-bold text-align-right font-color-green"}>{flexCurrency(sonuc)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                    {(() => {
                                        const personelGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "personelGiderleri")?.propValue || 0, 0);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(personelGiderleri)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                    {(() => {
                                        const isYeriKiraGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue || 0, 0);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(isYeriKiraGiderleri)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>
                                    {(() => {
                                        const digerGiderler = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "digerGiderler")?.propValue || 0, 0);
                                        const amortismanGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "amortismanGiderleri")?.propValue || 0, 0);
                                        const sonuc = digerGiderler - amortismanGiderleri;
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(sonuc)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>
                                    {(() => {
                                        const sabitKiymetAlimlari = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "sabitKiymetAlimlari")?.propValue || 0, 0);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(sabitKiymetAlimlari)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6}>
                                    {(() => {
                                        const stokAlimlari = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "stokAlimlari")?.propValue || 0, 0);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(stokAlimlari)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7}>
                                    {(() => {
                                        const uretimdeKullanilanMalzemeGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "uretimdeKullanilanMalzemeGiderleri")?.propValue || 0, 0);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(uretimdeKullanilanMalzemeGiderleri)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8}>
                                    {(() => {
                                        const amortismanGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "amortismanGiderleri")?.propValue || 0, 0);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(amortismanGiderleri)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={9}>
                                    {(() => {
                                        const digerGiderler = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "digerGiderler")?.propValue || 0, 0);
                                        const personelGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "personelGiderleri")?.propValue || 0, 0);
                                        const isYeriKiraGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue || 0, 0);
                                        const sabitKiymetAlimlari = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "sabitKiymetAlimlari")?.propValue || 0, 0);
                                        const stokAlimlari = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "stokAlimlari")?.propValue || 0, 0);
                                        const amortismanGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "amortismanGiderleri")?.propValue || 0, 0);

                                        const sonuc = (digerGiderler+personelGiderleri+isYeriKiraGiderleri+sabitKiymetAlimlari+stokAlimlari-amortismanGiderleri);
                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(sonuc)}</div>

                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={10}>
                                    {(() => {
                                        const digerGiderler = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "digerGiderler")?.propValue || 0, 0);
                                        const personelGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "personelGiderleri")?.propValue || 0, 0);
                                        const isYeriKiraGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue || 0, 0);

                                        const sonuc = (digerGiderler+personelGiderleri+isYeriKiraGiderleri);

                                        return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(sonuc)}</div>
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={11}>
                                    {(() => {
                                        const toplamGelir = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "toplamGelir")?.propValue || 0, 0);
                                        const digerGiderler = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "digerGiderler")?.propValue || 0, 0);
                                        const personelGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "personelGiderleri")?.propValue || 0, 0);
                                        const isYeriKiraGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue || 0, 0);
                                        const sabitKiymetAlimlari = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "sabitKiymetAlimlari")?.propValue || 0, 0);
                                        const stokAlimlari = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "stokAlimlari")?.propValue || 0, 0);
                                        const amortismanGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "amortismanGiderleri")?.propValue || 0, 0);
                                        const satisIadeleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "satisIadeleri")?.propValue || 0, 0);

                                        let sonuc = amortismanGiderleri+toplamGelir-(digerGiderler+personelGiderleri+isYeriKiraGiderleri+sabitKiymetAlimlari+stokAlimlari+satisIadeleri)

                                        if(sonuc < 0){
                                            return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(sonuc)}</div>
                                        }
                                        else {
                                            return <div className={"font-weight-bold text-align-right font-color-green"}>{flexCurrency(sonuc)}</div>
                                        }
                                    })()}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={12}>
                                    {(() => {
                                        const toplamGelir = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "toplamGelir")?.propValue || 0, 0);
                                        const personelGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "personelGiderleri")?.propValue || 0, 0);
                                        const isYeriKiraGiderleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue || 0, 0);
                                        const satisIadeleri = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "satisIadeleri")?.propValue || 0, 0);
                                        const digerGiderler = data.reduce((acc, item) => acc + item.props.find(x => x.propName === "digerGiderler")?.propValue || 0, 0);

                                        const sonuc = toplamGelir-(digerGiderler+personelGiderleri+isYeriKiraGiderleri+satisIadeleri)

                                        if(sonuc < 0)
                                            return <div className={"font-weight-bold text-align-right font-color-red"}>{flexCurrency(sonuc)}</div>
                                        else
                                            return <div className={"font-weight-bold text-align-right font-color-green"}>{flexCurrency(sonuc)}</div>

                                    })()}
                                </Table.Summary.Cell>

                            </Table.Summary.Row>
                        )}
                        pagination={false}>

                        <Table.Column width={200} className={"font-weight-bold"} title="" dataIndex="aciklama" key="aciklama" align="left" />

                        <Table.Column title="TOPLAM GELİRLER" className={"font-color-green"} dataIndex="toplamGelirler" key="toplamGelirler" align="right"
                                      render={(_, record) => {
                                          let toplamGelir = record.props.find(x => x.propName === "toplamGelir")?.propValue
                                          let satisIadeleri = record.props.find(x => x.propName === "satisIadeleri")?.propValue
                                          let sonuc = (toplamGelir-satisIadeleri)
                                          return  <p onClick={(e) => detayModalOpen(record.props.find(x => x.propName === "toplamGelir"))}>{flexCurrency(sonuc)}</p>
                                      }}
                        />
                        <Table.Column title="GİDER DAĞILIMLARI" key="giderDagilimlari" align="center">

                            <Table.Column title="PERSONEL GİDERLERİ" className={"font-color-red"} dataIndex="personelGiderleri" key="personelGiderleri" align="right"
                                          render={(_, record) => {
                                              return <p onClick={(e) => detayModalOpen(record.props.find(x => x.propName === "personelGiderleri"))}>{flexCurrency(record.props.find(x => x.propName === "personelGiderleri")?.propValue)}</p>
                                          }}
                            />

                            <Table.Column title="İŞ YERİ KİRA GİDERLERİ" className={"font-color-red"} dataIndex="isYeriKiraGiderleri" key="isYeriKiraGiderleri" align="right"
                                          render={(_, record) => {
                                              return <p onClick={(e) => detayModalOpen(record.props.find(x => x.propName === "isYeriKiraGiderleri"))}>{flexCurrency(record.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue)}</p>
                                          }}
                            />

                            <Table.Column title="DİĞER GİDERLER" className={"font-color-red"} dataIndex="digerGiderler" key="digerGiderler" align="right"
                                          render={(_, record) => {
                                              let amortismanGiderleri = record.props.find(x => x.propName === "amortismanGiderleri")?.propValue
                                              let digerGiderler = record.props.find(x => x.propName === "digerGiderler")?.propValue
                                              let sonuc = digerGiderler-amortismanGiderleri
                                              return <p onClick={() => detayModalOpen(record.props.find(x => x.propName === "digerGiderler"))}>{flexCurrency(sonuc)}</p>
                                          }}
                            />

                            <Table.Column title="SABİT KIYMET ALIMLARI" className={"font-color-red"} dataIndex="sabitKiymetAlimlari" key="sabitKiymetAlimlari" align="right"
                                          render={(_, record) => {
                                              return <p onClick={() => detayModalOpen(record.props.find(x => x.propName === "sabitKiymetAlimlari"))}>{flexCurrency(record.props.find(x => x.propName === "sabitKiymetAlimlari")?.propValue)}</p>
                                          }}
                            />

                            <Table.Column title="STOK ALIMLARI" className={"font-color-red"} dataIndex="stokAlimlari" key="stokAlimlari" align="right"
                                          render={(_, record) => {
                                              return <p onClick={() => detayModalOpen(record.props.find(x => x.propName === "stokAlimlari"))}>{flexCurrency(record.props.find(x => x.propName === "stokAlimlari")?.propValue)}</p>
                                          }}
                            />
                            <Table.Column title="STOK TÜKETİMİ" className={"font-color-red"} dataIndex="uretimdeKullanilanMalzemeGiderleri" key="uretimdeKullanilanMalzemeGiderleri" align="right"
                                          render={(_, record) => {
                                              return <p onClick={() => detayModalOpen(record.props.find(x => x.propName === "uretimdeKullanilanMalzemeGiderleri"))}>{flexCurrency(record.props.find(x => x.propName === "uretimdeKullanilanMalzemeGiderleri")?.propValue)}</p>
                                          }}
                            />
                            <Table.Column title="AMORTİSMAN GİDERLERİ" className={"font-color-red"} dataIndex="amortismanGiderleri" key="amortismanGiderleri" align="right"
                                          render={(_, record) => {
                                              return <p onClick={() => detayModalOpen(record.props.find(x => x.propName === "amortismanGiderleri"))}>{flexCurrency(record.props.find(x => x.propName === "amortismanGiderleri")?.propValue)}</p>
                                          }}
                            />
                        </Table.Column>
                        <Table.Column title="G/R TOPLAM GİDERLER" className={"font-color-red"} dataIndex="gayriResmiToplamGiderler" key="gayriResmiToplamGiderler" align="right"
                                      render={(_, record) => {
                                          let digerGiderler = record.props.find(x => x.propName === "digerGiderler")?.propValue
                                          let personelGiderleri = record.props.find(x => x.propName === "personelGiderleri")?.propValue
                                          let isYeriKiraGiderleri = record.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue
                                          let sabitKiymetAlimlari = record.props.find(x => x.propName === "sabitKiymetAlimlari")?.propValue
                                          let stokAlimlari = record.props.find(x => x.propName === "stokAlimlari")?.propValue
                                          let amortismanGiderleri = record.props.find(x => x.propName === "amortismanGiderleri")?.propValue
                                          return flexCurrency(digerGiderler+personelGiderleri+isYeriKiraGiderleri+sabitKiymetAlimlari+stokAlimlari-amortismanGiderleri)
                                      }}
                        />

                        <Table.Column title="RESMİ TOPLAM GİDERLER" className={"font-color-red"} dataIndex="resmiToplamGiderler" key="resmiToplamGiderler" align="right"
                                      render={(_, record) => {

                                          let digerGiderler = record.props.find(x => x.propName === "digerGiderler")?.propValue
                                          let personelGiderleri = record.props.find(x => x.propName === "personelGiderleri")?.propValue
                                          let isYeriKiraGiderleri = record.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue

                                          return flexCurrency(digerGiderler+personelGiderleri+isYeriKiraGiderleri)
                                      }} />

                        <Table.Column title="G/R KAR ZARAR" dataIndex="gayriResmiKarZarar" key="gayriResmiKarZarar" align="right"
                                      render={(_, record) => {

                                          let toplamGelir = record.props.find(x => x.propName === "toplamGelir")?.propValue
                                          let digerGiderler = record.props.find(x => x.propName === "digerGiderler")?.propValue
                                          let personelGiderleri = record.props.find(x => x.propName === "personelGiderleri")?.propValue
                                          let isYeriKiraGiderleri = record.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue
                                          let sabitKiymetAlimlari = record.props.find(x => x.propName === "sabitKiymetAlimlari")?.propValue
                                          let stokAlimlari = record.props.find(x => x.propName === "stokAlimlari")?.propValue
                                          let amortismanGiderleri = record.props.find(x => x.propName === "amortismanGiderleri")?.propValue
                                          let satisIadeleri = record.props.find(x => x.propName === "satisIadeleri")?.propValue

                                          let sonuc = amortismanGiderleri+toplamGelir-(digerGiderler+personelGiderleri+isYeriKiraGiderleri+sabitKiymetAlimlari+stokAlimlari+satisIadeleri)

                                          if(sonuc<0){
                                              return <div className={"font-color-red"}>{flexCurrency(sonuc)}</div>
                                          }

                                          return <div className={"font-color-green"}>{flexCurrency(sonuc)}</div>
                                      }}
                        />

                        <Table.Column title="RESMİ KAR ZARAR" dataIndex="resmiKarZarar" key="resmiKarZarar" align="right"
                                      render={(_, record) => {
                                          let toplamGelir = record.props.find(x => x.propName === "toplamGelir")?.propValue

                                          let digerGiderler = record.props.find(x => x.propName === "digerGiderler")?.propValue
                                          let personelGiderleri = record.props.find(x => x.propName === "personelGiderleri")?.propValue
                                          let isYeriKiraGiderleri = record.props.find(x => x.propName === "isYeriKiraGiderleri")?.propValue
                                          let satisIadeleri = record.props.find(x => x.propName === "satisIadeleri")?.propValue
                                          let sonuc = toplamGelir-(digerGiderler+personelGiderleri+isYeriKiraGiderleri+satisIadeleri)

                                          if(sonuc<0){
                                              return <div className={"font-color-red"}>{flexCurrency(sonuc)}</div>
                                          }

                                          return <div className={"font-color-green"}>{flexCurrency(sonuc)}</div>
                                      }}
                        />
                    </Table>





                </Card>
            </Col>
        </Row>
    </div>)
}

export default IsletmeGelirGiderPage