import {useEffect, useState} from "react";
import {Card, message, Table} from "antd";
import Api from "../../services/Api";
import dayjs from "dayjs";
import Api_Maden from "../../services/Api_Maden";

const SyncListPage = () => {
    return(<div>
        <SyncOrbelComponent />

        <SyncOrbelMadencilikComponent/>
    </div>)
}

const SyncOrbelComponent = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getData = () => {
        setLoading(true)
        Api.get("/api/SyncLogs?PageIndex=0&PageSize=100&BypassCache=true").then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Loglar getirilirken hata oluştu")
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <div className={"p-1"}>
            <Card size={"small"} loading={loading} title={"ORBEL A.Ş SYNC LOG"}>
                <Table
                    dataSource={data}
                    size={"small"}
                >
                    <Table.Column title={"Sync Tipi"} dataIndex={"syncType"} key={"syncType"} />
                    <Table.Column title={"Sync Adı"} dataIndex={"syncName"} key={"syncName"} />
                    <Table.Column title={"Sync "} render={(e, record) => {
                        return dayjs(record.syncDate).format("DD.MM.YYYY HH:mm:ss")
                    }} dataIndex={"syncType"} key={"syncType"} />
                    <Table.Column title={"Durum"} dataIndex={"syncStatus"} key={"syncStatus"} />
                    <Table.Column title={"Sync Mesaj"} dataIndex={"syncMessage"} key={"syncMessage"} />
                </Table>
            </Card>
        </div>
    )
}

const SyncOrbelMadencilikComponent = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getData = () => {
        setLoading(true)
        Api_Maden.get("/api/SyncLogs?PageIndex=0&PageSize=100&BypassCache=true").then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Loglar getirilirken hata oluştu")
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return(
        <div className={"p-1"}>
            <Card size={"small"} loading={loading} title={"ORBEL MADENCİLİK SYNC LOG"}>
                <Table
                    dataSource={data}
                    size={"small"}
                >
                    <Table.Column title={"Sync Tipi"} dataIndex={"syncType"} key={"syncType"} />
                    <Table.Column title={"Sync Adı"} dataIndex={"syncName"} key={"syncName"} />
                    <Table.Column title={"Sync "} render={(e, record) => {
                        return dayjs(record.syncDate).format("DD.MM.YYYY HH:mm:ss")
                    }} dataIndex={"syncType"} key={"syncType"} />
                    <Table.Column title={"Durum"} dataIndex={"syncStatus"} key={"syncStatus"} />
                    <Table.Column title={"Sync Mesaj"} dataIndex={"syncMessage"} key={"syncMessage"} />
                </Table>
            </Card>
        </div>
    )

}

export default SyncListPage