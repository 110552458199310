import {Button, Col, InputNumber, message, Row, Space, Table} from "antd";
import FormattedInputNumber from "../../../../../utils/FormattedInputNumber";
import Api from "../../../../../services/Api";
import {useEffect, useState} from "react";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import flexCurrency from "../../../../../utils/FlexCurrency";
import {faDatabase, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TahminiGelirGiderParametreComponentModal from "./TahminiGelirGiderParametreComponent";
import {faRefresh} from "@fortawesome/free-solid-svg-icons/faRefresh";

const TahminiTahsilatPageComponent = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [fullData, setFullData] = useState([])
    const [parametreModal, setParametreModal] = useState(false)

    useEffect(() => {
        getParametre()
    }, []);

    const getParametre = () => {
        setLoading(true)
        Api.get("/api/Parametres/GetByCode/TAHMINI_GELIR_GIDER_AYLIK_GIRIS").then(res => {
            getData(res.data.parametreDegeri)
        }).catch(err => {
            message.error("Parametre bilgileri getirilemedi")
            console.log(err)
            setLoading(false)
        })
    }

    const getData = (parametreDegeri) => {
        let queryParams = JSON.parse(parametreDegeri)

        const requestDto = {
            "yil": new Date().getFullYear(),
            "formulleHesaplaRequestDto": queryParams
        }

        Api.post("api/MuhasebeTahminiGelirGiders/GetListTahminiGelirGiderWidthMonthQuery", requestDto).then(res => {
            const newData = [];
            res.data.forEach(item => {
                const ustGrup = { ustGrupAdi: item.ustGrupAdi, altGrup: [] };

                item.altGrup.forEach(altGrup => {
                    const newAltGrup = { aciklama: altGrup.aciklama, ustGrupAdi: item.ustGrupAdi, formul: altGrup.formul };
                    altGrup.bakiyes.forEach(bakiye => {
                        newAltGrup[bakiye.tarih] = bakiye.tutar; // bakiye.tutar değerini ekliyoruz
                    });
                    ustGrup.altGrup.push(newAltGrup);
                });
                newData.push(ustGrup);
            });

            setData(newData)
            setLoading(false)
            setFullData(res.data)

        }).catch(err => {
            message.error("Bir hata oluştu");
        })
    }

    const altGrupColumnsGenerator = (data) => {
        const columns = []



        columns.push(
            {title: "Hesap", dataIndex: "aciklama",
                key: "aciklama",
                fixed: "left",
                className:"font-weight-bold w-100",
                render: (_,record) => {
                    return <div style={{minWidth:200}}>{record.aciklama}</div>
                }
            }
        )



        data[0].altGrup[0].bakiyes.forEach(item => {
            columns.push(
                {title: dayjs(item.tarih).format("MMMM YYYY"),
                    align:"right",
                    dataIndex: item.tarih,
                    key: item.tarih,
                    render: (_,record) => {
                        return <FormattedInputNumber
                            style={{minWidth:110, textAlign:"right"}}
                            name={item.tarih}
                            align={"right"}
                            value={record[item.tarih]}
                            onChange={(value) => {
                                updateData(record, value, item.tarih)
                            }}/>
                    }})
        })

        return columns
    }

    const updateData = (record, value, selectedDateProperty) => {
        //datanın içinde ilgili veriyi bulup güncellememiz gerekiyor
        const newData = [...data];
        const index = newData.findIndex(item => item.ustGrupAdi === record.ustGrupAdi);
        const altGrupIndex = newData[index].altGrup.findIndex(item => item.aciklama === record.aciklama);
        newData[index].altGrup[altGrupIndex][selectedDateProperty] = value;
        setData(newData)
    }

    const saveData = () => {
        setLoading(true)
        const newDto = data.map(item => {
            return {
                ustGrupAdi: item.ustGrupAdi,
                altGrup: item.altGrup.map(altItem => {
                    console.log(altItem)
                    return {
                        formul: altItem.formul,
                        aciklama: altItem.aciklama,
                        bakiyes: fullData[0].altGrup[0].bakiyes.map(bakiye => {
                            return {
                                tarih: bakiye.tarih,
                                tutar: altItem[bakiye.tarih]
                            }
                        })
                    }
                })
            }
        })

        Api.post("/api/MuhasebeTahminiGelirGiders/MuhasebeTahminiGelirGiderUpdateAll", {"tahminiGelirGider": newDto}).then(res => {
            message.success("Kayıt başarılı")
            setLoading(false)
        }).catch(err => {
            message.error("Bir hata oluştu")
            setLoading(false)
        })

    }

    const openParametreModal = () => {
        setParametreModal(true)
    }

    const closeParametreModal = () => {
        setParametreModal(false)
        getParametre()
    }

    return(
        <>
            <div className={"mb-1 w-100 d-flex justify-content-between"} >
                <Space>
                    <Button loading={loading} icon={<FontAwesomeIcon icon={faFloppyDisk} />} onClick={() => saveData()}>Değişiklikleri Kaydet</Button>
                </Space>

                <Space>
                    <Button icon={<FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon>} onClick={() => getParametre()}></Button>
                    <Button icon={<FontAwesomeIcon icon={faDatabase} />} onClick={() => openParametreModal()}></Button>
                </Space>
            </div>

            <TahminiGelirGiderParametreComponentModal isActive={parametreModal} isClose={() => closeParametreModal()}></TahminiGelirGiderParametreComponentModal>

            {data.length > 0 &&
                <Table
                    size={"small"}
                    showHeader={true}
                    loading={loading}
                    dataSource={data}
                    style={{overflow:"auto", height: "calc(100vh - 300px)"}}
                    pagination={false}
                    bordered={true}
                    rowKey={"ustGrupAdi"}
                    defaultExpandAllRows={false}
                    expandable={{
                        expandedRowRender: record => {
                            return (
                                <>
                                    <Table
                                        loading={loading}
                                        showHeader={true}
                                        bordered={true}
                                        columns={altGrupColumnsGenerator(fullData)}
                                        dataSource={record.altGrup}
                                        rowKey={"aciklama"}
                                        size={"small"}
                                        pagination={false}
                                        summary={() => (
                                            <Table.Summary.Row>

                                                <Table.Summary.Cell index={0}>
                                                    <div className={"font-weight-bold text-align-right"}>TOPLAM</div>
                                                </Table.Summary.Cell>
                                                {fullData[0].altGrup[0].bakiyes.map(item => {
                                                    return (
                                                        <Table.Summary.Cell index={item.tarih} key={item.tarih}>
                                                            <div className={"font-weight-bold text-align-right"}>

                                                                {
                                                                    flexCurrency(
                                                                        record.altGrup.reduce((acc, altItem) => {
                                                                            const value = altItem[item.tarih];
                                                                            return acc + (typeof value === 'number' ? value : 0);
                                                                        }, 0)
                                                                    )
                                                                }
                                                            </div>
                                                        </Table.Summary.Cell>
                                                    )
                                                })}
                                            </Table.Summary.Row>
                                        )}
                                    />
                                </>
                            )
                        }
                    }}
                >
                    <Column title={""} dataIndex={"ustGrupAdi"} key={"ustGrupAdi"} />
                </Table>
            }

        </>
    )
}

export default TahminiTahsilatPageComponent