import { useState } from "react";
import Api from "../../../services/Api";
import {message} from "antd";

const ObbHizmetAlacakToArrayComponent = () => {
    const [coverImage, setCoverImage] = useState();

    const uploadCoverImageApi = (e) => {
        if (coverImage !== undefined) {
            Api.delete("/api/FileUpload/" + coverImage).then(res => message.success("Önceki kapak fotoğrafı silindi")).catch(err => message.error("Önceki kapak fotoğrafı silinemedi"))
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0])

        Api.post("/api/FileUpload", formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            setCoverImage(res.data.fileList[0])
            form.setFieldValue("coverImage", res.data.fileList[0])
        }).catch(err => message.error("Kapak Fotoğrafı Yüklenemedi"))
    }


    return (

        <div>
            <input accept="image/png, image/gif, image/jpeg"
                   onChange={uploadCoverImageApi}
                   className={"mt-0-5"}
                   type={"file"}>
            </input>
        </div>


    );
};

export default ObbHizmetAlacakToArrayComponent;
