import {Button, Card, Col, message, Modal, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import Column from "antd/es/table/Column";
import {useEffect, useState} from "react";
import ParametreDetailPage from "./ParametreDetailPage";
import Api from "../../services/Api";
import {Link} from "react-router-dom";
import {DeleteOutlined, FormOutlined} from "@ant-design/icons";

const ParametreListPage = () => {
    const [modal, setModal] = useState({isOpen:false, title:"", width:800, id:""})
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const openModal = (title, id) => {
        setModal({isOpen:true, title:title, width:800, id: id})
    }

    const closeModal = () => {
        setModal({isOpen:false, title:"", width:800, id:""})
        getData()
    }

    const getData = () => {
        Api.get("api/Parametres?PageIndex=0&PageSize=100").then(res => {
            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error("Parametreler yüklenemedi")
        })
    }

    const getDelete = (id) => {
        Api.delete(`api/Parametres/${id}`).then(res => {
            message.success("Silme işlemi başarılı")
            getData()
        }).catch(err => {
            message.error("Silme işlemi sırasında bir hata oluştu")
        })
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <div className={"p-1"}>
            <Modal open={modal.isOpen} title={modal.title} width={modal.width} destroyOnClose={true} onCancel={closeModal} footer={[]}>
                <ParametreDetailPage selectedId={modal.id} onClose={closeModal}></ParametreDetailPage>
            </Modal>

            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card title={"Paremetreler"} size={"small"} extra={<Button size={"small"} onClick={() => openModal("Yeni Parametre")}>Parametre Ekle</Button>}>
                        <Table
                            loading={loading}
                            bordered={true}
                            showHeader={true}
                            rowKey={"id"}
                            className={"row-padding"}
                            dataSource={data}
                            size={"small"}
                            pagination={true}>
                            <Column title={""} width={"10px"} dataIndex={""} render={(_, record) => {
                                return(
                                    <Space>
                                        <Tooltip title="Düzenle">
                                            <Button onClick={() => openModal("Parametre Düzenle", record.id)} icon={<FormOutlined />}  size={"small"}></Button>
                                        </Tooltip>

                                        <Popconfirm
                                            placement="top"
                                            title={"Silme İşlemi"}
                                            description={"Bu kaydı silmek istediğinizden emin misiniz?"}
                                            onConfirm={() => getDelete(record.id)}
                                            okText="Evet"
                                            cancelText="Hayır"
                                        >
                                            <Button size={"small"} icon={<DeleteOutlined />} ></Button>
                                        </Popconfirm>
                                    </Space>
                                )
                            }}></Column>
                            <Column title={"Parametre Kodu"} width={150}  dataIndex={"parametreKodu"}></Column>
                            <Column title={"Paremetre Tipi"} width={150} dataIndex={"parametreTipi"}></Column>
                            <Column title={"Parametre Adı"}  dataIndex={"parametreAdi"}></Column>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ParametreListPage;