import {useEffect, useState} from "react";
import Api from "../../../../services/Api";
import {Button, Card, Table} from "antd";
import Column from "antd/es/table/Column";
import FlexCurrency from "../../../../utils/FlexCurrency";
import dayjs from "dayjs";
import HareketModalComponent from "../Hareket/HareketModalComponent";

const KasaHareketleriComponent = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true)
    const [modalHareket, setModalHareket] = useState({isOpen:false, title:"", hesapKodu:"", width:800})

    const getApi = () => {
        const requestFilterData = {
            "sort": [
                {
                    "field": "_Date",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "_Date.Date",
                "operator": "lte",
                "value": `${dayjs(new Date).format("YYYY-MM-DD")}`,
                "logic": "and",
                "filters":[
                    {
                        "field":"HesapKodu",
                        "operator":"startswith",
                        "value":"100",
                        "logic":"and"
                    }
                ]
            }
        }


        Api.post("api/MuhasebeFisis/GetListDynamic?PageIndex=0&PageSize=50&BypassCache=true", requestFilterData).then(res=> {
            setData(res.data.items)
            setLoading(false)
        }).catch(err =>{
            console.log(err)
        })
    }

    useEffect(() => {
        getApi()
    }, [])

    return(
        <Card loading={loading} size={"small"} title={"KASA HAREKETLERİ"} className={"h-100"}
              extra={<Button onClick={() => setModalHareket({
                  isOpen:true,
                  title:"Kasa Hareketleri",
                  hesapKodu:"100",
                  width:1600
              })} size={"small"}>Tümü</Button>}>

            <HareketModalComponent
                isOpen={modalHareket.isOpen}
                close={() => setModalHareket({isOpen:false, title:"", hesapKodu:"", width:800})}
                title={modalHareket.title}
                width={modalHareket.width}
                hesapKodu={modalHareket.hesapKodu}

            >
            </HareketModalComponent>


            <Table
                bordered={true}
                showHeader={false}
                rowKey={"id"}
                className={"row-padding"}
                dataSource={data}
                style={{overflow:"auto", maxHeight:250}}
                size={"small"}
                pagination={false}>
                <Column title={"Hesap Adı"} width={150}  dataIndex={"hesapAciklama"}></Column>
                <Column title={"Açıklama"} width={150} dataIndex={"kalemAciklamasi"}></Column>
                <Column title={"Ekleyen"} align={"center"}  width={20} dataIndex={"ekleyenKullanici"}></Column>
                <Column title={"Çıkan"} className={"font-color-red small-font"} align={"right"} dataIndex={"alacak"}
                        render={(_,record) => {
                            return  FlexCurrency(record.alacak) === "0,00" ? "" : "-"+FlexCurrency(record.alacak)
                        }}>
                </Column>

                <Column title={"Giren"} align={"right"} className={"font-color-green"} dataIndex={"borc"}
                        render={(_,record) => {
                            return FlexCurrency(record.borc) === "0,00" ? "" : "+"+FlexCurrency(record.borc)
                        }}>
                </Column>
            </Table>
        </Card>
    )
}

export default KasaHareketleriComponent